import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { styles } from "../../../styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailItem,
  getListAnswerOfUser,
  IApplicationState,
  IItems,
  saveCustomAnswer,
  saveEditCustomAnswer,
} from "../../../../../store-redux";
import { ECurrentStep } from "..";
import { useEffect, useState } from "react";
import PracticeSpeakingModal from "./PracticeSpeakingModal";
import { useGetQuota } from "@/services-react-query/queries";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import RemainingEnergy from "@/components/RemainingEnergy";

interface ILayoutAnswerProps {
  children: JSX.Element;
  isLoading?: boolean;
  currentStep: ECurrentStep;
  boldIndexes: number[] | null;
  setCurrentStep: React.Dispatch<React.SetStateAction<ECurrentStep>>;
  question?: IItems;
  guidedQuestions?: any[];
}

function LayoutAnswer({
  children,
  isLoading,
  currentStep,
  setCurrentStep,
  boldIndexes,
  question,
  guidedQuestions,
}: ILayoutAnswerProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openPractice, setOpenPractice] = useState(false);
  // const { data: dataQuota } = useGetQuota({});
  const { categoryId, topicId } = useParams();

  const isFromProfile = categoryId === ":categoryId";
  const isQuestionPart2 = location?.pathname.includes("/question-part2");

  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const isHasContentEdit = Boolean(editCustomAnswerContent?.data && editCustomAnswerContent?.data?.trim());
  const questionReducer = useSelector((state: IApplicationState) => state.detailItem);
  const detailQuestion = isQuestionPart2
    ? {
        data: question,
      }
    : questionReducer;
  const infoSaveCustomAnswer = useSelector((state: IApplicationState) => state.saveCustomAnswer);

  const handleGetListAnswerOfUser = () => {
    dispatch(
      getListAnswerOfUser({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
      })
    );
  };

  const handleSaveCustomAnswer = () => {
    const dataSave = {
      questionId: detailQuestion?.data?._id ?? '',
      question: detailQuestion?.data?.name ?? '',
      answer: editCustomAnswerContent?.data?.toString(),
      idea: JSON.stringify(isQuestionPart2 ? guidedQuestions : listMyIdeaPath?.data),
      boldIndex: boldIndexes,
      topicId: topicId,
    };
    dispatch(saveCustomAnswer(dataSave));
    handleGetListAnswerOfUser();
  };

  const handleSaveEditCustomAnswer = () => {
    dispatch(
      saveEditCustomAnswer({
        questionId: location.state?.questionId || infoSaveCustomAnswer?.data?.questionId,
        question: location.state?.question || infoSaveCustomAnswer?.data?.question,
        answer: editCustomAnswerContent?.data?.toString(),
        idea: location.state?.idea || JSON.stringify(listMyIdeaPath?.data),
        boldIndex: boldIndexes,
        id: location.state?._id || infoSaveCustomAnswer?.data?._id,
        topicId: isFromProfile ? detailQuestion?.data?.parentId || location.state?.topicId : topicId,
      })
    );
    handleGetListAnswerOfUser();
  };

  //GET DETAIL
  useEffect(() => {
    if (!location.state?.topicId && location?.state?.questionId) {
      dispatch(getDetailItem(`${location?.state?.questionId}`));
    }
  }, [isFromProfile, location?.state]);

  const handleGoBack = () => {
    switch (currentStep) {
      case ECurrentStep.ANSWER:
        navigate(-1);
        break;
      case ECurrentStep.ANSWER_WORD_BOLD:
        setCurrentStep(ECurrentStep.ANSWER);
        break;
      case ECurrentStep.ANSWER_HIGHLIGHT:
        setCurrentStep(ECurrentStep.ANSWER_WORD_BOLD);
        break;
      default:
        break;
    }
  };

  const onHandlePractice = () => {
    setOpenPractice(true);
  };
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "var(--white, #fff)",
        height: "100vh",
        overflow: "hidden",
        padding: "0 16px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "8px",
            "& h1": {
              color: "#000",
              width: "auto",
              fontSize: "36px",
              fontWeight: 700,
              lineHeight: "54px",
              letterSpacing: "0em",
            },
            "& button": {
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            },
            "& .isHiddenSave": {
              width: "auto",
              display: currentStep === ECurrentStep.ANSWER && isHasContentEdit ? "block" : "none",
            },
            "& .isHiddenSaveEdit": {
              width: "auto",
              display: currentStep === ECurrentStep.ANSWER ? "block" : "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                marginRight: "10px",
              }}
            >
              My answer
              {/* <span style={{ fontSize: "20px" }}>
                (Quota: {dataQuota?.week_count ?? ""} / {dataQuota?.week_max ?? ""})
              </span> */}
            </Typography>
            <RemainingEnergy />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {isHasContentEdit && currentStep === ECurrentStep.ANSWER ? (
              <Button variant="contained" onClick={() => setCurrentStep(ECurrentStep.ANSWER_WORD_BOLD)} color="info">
                <DirectionsWalkIcon />
                Practice
              </Button>
            ) : null}
            <Button variant="contained" onClick={onHandlePractice} color="warning">
              <RecordVoiceOverIcon />
              Test
            </Button>
            {isFromProfile ||
            (infoSaveCustomAnswer?.data?.questionId &&
              infoSaveCustomAnswer?.data?.questionId === detailQuestion?.data?._id) ? (
              <Button
                variant="contained"
                onClick={handleSaveEditCustomAnswer}
                className="isHiddenSaveEdit"
                color="success"
              >
                <SaveIcon />
                Save Edit
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSaveCustomAnswer} className="isHiddenSave" color="success">
                <SaveIcon />
                Save
              </Button>
            )}

            <Button variant="outlined" onClick={handleGoBack}>
              <ArrowBackIcon />
              Back
            </Button>
          </Box>
        </Box>
        <Box
          dangerouslySetInnerHTML={{
            __html: question ? question?.name : isFromProfile ? location.state.question : detailQuestion?.data?.name,
          }}
          sx={{
            fontSize: "24px",
          }}
        />
      </Box>

      {isLoading ? <LinearProgress color="secondary" style={{ marginRight: 10 }} /> : null}
      <Box sx={styles.boxContainer}>{children}</Box>
      <PracticeSpeakingModal
        openPractice={openPractice}
        onClose={() => setOpenPractice(false)}
        dataQuestion={
          isFromProfile
            ? {
                name: location.state?.question,
                _id: location.state?.questionId,
              }
            : detailQuestion?.data
        }
      />
    </Box>
  );
}

export default LayoutAnswer;
