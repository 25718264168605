export const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .body_box": {
      maxHeight: "72vh",
      overflow: "auto",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      gap: "20px",

      "& section": {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "15px",
        "& .icon-delete": {
          width: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiTextField-root": {
          marginBlock: 0,
        },
      },
    },
    "& .container": {
      position: "relative",
      maxHeight: "calc(90vh + 40px)",
      backgroundColor: "white",
      padding: "10px 20px 20px 20px",
      maxWidth: {
        xs: "100vw",
        sm: "80vw",
      },
      minWidth: {
        xs: "100vw",
        sm: "60vw",
      },
      borderRadius: "8px",
      "& .flex-2": {
        flex: 2,
      },
      "& .flex-1": {
        flex: 0.75,
      },
      "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& h3": {
          fontWeight: "bold",
          fontSize: 24,
        },
      },
      "& .sub-header": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px",
        gap: '15px',
        "& h4:nth-of-type(2)": {
          marginLeft: "10px",
        },
      },
      "& .sun-editor .se-wrapper": {
        height: "calc(100% - 16px)",
      },
      "& .icon-close": {
        marginRight: "-10px",
        marginBottom: "10px",
      },
      "& .list-btn": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-end",
        gap: "20px",
      },
    },
  },
};
