import { suggestVocaByBand } from "@/services-react-query/api";
import { IApplicationState, SuggestVocabularyBand, getRemainingEnergy, showMessage } from "@/store-redux";
import { Box, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
interface SuggestVocaProps {
  band: SuggestVocabularyBand | null;
}

const SuggestVoca: React.FC<SuggestVocaProps> = (props) => {
  const { band } = props;
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (detailQuestion.data?.name && band) getSuggestVocaByBand(band);
  }, [band]);
  const getSuggestVocaByBand = async (band: SuggestVocabularyBand) => {
    setIsLoading(true);
    try {
      setData(null);
      const data = await suggestVocaByBand({
        band: band,
        question: detailQuestion.data.name.replace(/<\/?[^>]+(>|$)/g, "").trim(),
      });
      setData(data);
      dispatch(getRemainingEnergy());
    } catch (e) {
      dispatch(showMessage("Suggest vocabulary error", "error"));
    }
    setIsLoading(false);
  };
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        Here is the list of IELTS vocabulary band <b>{band}</b> to answer this question
      </Typography>
      {isLoading && <LinearProgress color="secondary" />}
      {Array.isArray(data) ? (
        <>
          {data?.map((item) => {
            return (
              <Box
                sx={{
                  padding: "5px 0",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  {item.type}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    ...(item.type === "answers"
                      ? {
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }
                      : {}),
                  }}
                >
                  {Array.isArray(item.suggestions) ? (
                    <>
                      {item.suggestions.map((suggest: any) => {
                        const suggestText = typeof suggest === "string" ? suggest : suggest?.text ?? "";
                        const suggestExample = typeof suggest === "string" ? null : suggest?.example ?? null;
                        return (
                          <Tooltip title={"Please click to copy to Clipboard"}>
                            <Box
                              sx={{
                                backgroundColor: "#f1f1f1",
                                padding: "4px 8px",
                                borderRadius: "20px",
                                margin: "4px 8px",
                                cursor: "pointer",
                                fontSize: "15px",
                                "&:hover": {
                                  backgroundColor: "rgba(79, 70, 229, 0.7)",
                                  color: "#fff",
                                },
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(suggestExample ?? suggestText);
                                dispatch(showMessage("Text has been copied to clipboard", "info"));
                              }}
                            >
                              {suggestText}
                              {!!suggestExample && (
                                <Box
                                  sx={{
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                    fontSize: "14px",
                                  }}
                                >
                                  {suggestExample}
                                </Box>
                              )}
                            </Box>
                          </Tooltip>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SuggestVoca;
