import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { BASE_DICT_SOUND_UK, BASE_DICT_SOUND_US } from "@/constants";
import { MenuAction } from "@/components/MenuAction";
import { EAction } from "@/common";
import { useState } from "react";
import VocabDetailModal from "../VocabDetailModal";

const VocabsCard = (props) => {
    const {item, handleClickAction} = props;
    const word = item?.word;
    const [isOpenDetail, setIsOpenDetail] = useState(false)

    const onPlaySound = (type: string) => {
        const baseSoundByType = {
          us: BASE_DICT_SOUND_US,
          uk: BASE_DICT_SOUND_UK,
        };
        const soundUrl = `${baseSoundByType[type]}${word.uid}.mp3`;
        const audio = new Audio(soundUrl);
        audio.play();
      };
    if (!word) return (<></>)
    return (
        <>
         <Card sx={{
            cursor: 'pointer'
        }} onClick={() => setIsOpenDetail(true)}>
             <CardContent sx={{
                padding: '10px!important'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Typography sx={{
                        flex: 1,
                        fontWeight: 'bold'
                    }}>
                        {word.word ?? ''}
                    </Typography>
                    <MenuAction 
                        handleClick={(typeAction: EAction) => {
                            // console.log('typeAction',typeAction)
                            handleClickAction(typeAction)
                        }}
                        hide={[EAction.edit]}
                    />
                </Box>
                <Box
                    sx={() => ({
                    "& .phonetic_type": {
                        fontWeight: "bold",
                    },
                    "& .phonetic_text": {},
                    })}
                >
                    <Box
                    sx={() => ({
                        display: "flex",
                        alignItems: "center",
                        fontSize: '14px',
                        "& span:first-of-type": {
                        fontWeight: "bold",
                        },
                    })}
                    >
                    <span>UK</span>
                    <IconButton size="small" onClick={(e) => {
                        onPlaySound("uk")
                        e.stopPropagation()
                    }}>
                        <VolumeUpIcon />
                    </IconButton>
                    <span>{word.phonetic?.uk ? `/${word.phonetic?.uk}/` : ""}</span>
                    </Box>
                    <Box
                    sx={() => ({
                        display: "flex",
                        alignItems: "center",
                        fontSize: '14px',
                        "& span:first-of-type": {
                            fontWeight: "bold",
                        },
                    })}
                    >
                    <span>US</span>
                    <IconButton size="small" onClick={(e) => {
                        onPlaySound("us")
                        e.stopPropagation()
                    }}>
                        <VolumeUpIcon />
                    </IconButton>
                    <span>{word.phonetic?.us ? `/${word.phonetic?.us}/` : ""}</span>
                    </Box>
                </Box>
            </CardContent>
        </Card>
        {isOpenDetail && <VocabDetailModal word={word} onClose={() => setIsOpenDetail(false)}/>}
        </>
    )
}

export default VocabsCard;