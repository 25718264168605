import { refinePrompt } from "@/helpers";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

interface IModalPromptStructureProps {
  aspectContent: string;
  open: boolean;
  handleClose: () => void;
  handleSave: (data: string) => void;
}

function ModalPromptStructure({ aspectContent, open, handleClose, handleSave }: IModalPromptStructureProps) {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    if (open) {
      setInputValue(`list out all structures describing ${refinePrompt(aspectContent)} in the given examples`);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Please fill the prompt structure</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="list out all structures describing ... in the given examples"
          value={inputValue}
          onChange={handleInputChange}
          multiline
          rows={3}
          sx={{ minWidth: "400px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSave(inputValue)} color="secondary" disabled={!inputValue} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalPromptStructure;
