import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../../store-redux";
import { useEffect, useMemo, useState } from "react";
import { forEach } from "lodash";
import { matchedLetterAnswer, removedLetterAnswer } from "../../../../../helpers";

interface ICustomAnswerHighlightProps {
  boldIndexes: number[];
}

interface MatchesIndexLetter {
  [key: string]: number;
}

const textRegex = /\([A-Z]\d+\)|\(\*\)/;

function CustomAnswerHighlight(props: ICustomAnswerHighlightProps) {
  const { boldIndexes } = props;
  const [indexBlur, setIndexBlur] = useState(0);
  const [speechFormatted, setSpeechFormatted] = useState<string>("");
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);

  const matches = matchedLetterAnswer(editCustomAnswerContent.data);
  const wordArray = removedLetterAnswer(editCustomAnswerContent.data);
  const arrSingleText: string[] = editCustomAnswerContent.data?.split(" ") ?? "";

  // tạo ra 1 object, lưu các giá trị key: A1 + vị trí trong mảng gốc
  // value sẽ là vị trị của A,B trong mảng có bold
  let progressiveIndexOffset = 0;
  const matchesIndexLetter: MatchesIndexLetter = useMemo(() => {
    const valueTemp: MatchesIndexLetter = {};
    arrSingleText.forEach((word, index) => {
      const match = word.match(textRegex);
      if (match) {
        const component = match[0];
        valueTemp[component + index] = index - progressiveIndexOffset;
        progressiveIndexOffset++;
      }
    });
    return valueTemp;
  }, [arrSingleText, progressiveIndexOffset]);

  // Mảng lưu các câu tách ra dựa theo A, B, C
  const arrParagraphs = useMemo(() => {
    const valueTemp = [];
    const valueSlice = Object.values(matchesIndexLetter);
    const arrSpeechFormatted = speechFormatted.trim().split(" ");
    for (let i = 0; i < valueSlice.length; i++) {
      const startIndex = valueSlice[i];
      const endIndex = valueSlice[i + 1];
      const paragraph = arrSpeechFormatted.slice(startIndex, endIndex);
      let concatParagraph = "";
      forEach(paragraph, (val) => {
        concatParagraph = concatParagraph + val + " ";
      });
      valueTemp.push(concatParagraph.trim());
    }
    return valueTemp;
  }, [matchesIndexLetter, speechFormatted]);

  const handleNextStep = () => {
    setIndexBlur(indexBlur + 1 === arrParagraphs.length ? 0 : indexBlur + 1);
  };

  const handleBlur = (index: number) => {
    setIndexBlur(index);
  };

  useEffect(() => {
    let answerBold = "";
    if (wordArray.length) {
      forEach(wordArray, (val, idx) => {
        if (boldIndexes.includes(idx)) {
          answerBold = answerBold + `<b>${val}</b> `;
        } else {
          answerBold = answerBold + val + " ";
        }
      });
    }
    setSpeechFormatted(answerBold);
  }, [boldIndexes, wordArray]);

  return (
    <Box sx={{ maxWidth: "calc(100vw - 32px)" }}>
      <Box
        component={"ul"}
        sx={{
          listStyle: "none",
          display: "flex",
          flex: "row",
          justifyContent: "space-evenly",
          marginTop: "20px",
          border: "1px solid black",
          padding: "5px 0",
          borderRadius: "8px",
          "& li": {
            cursor: "pointer",
            padding: "0 12px",
            "&:hover": {
              backgroundColor: "#ECEAEA",
            },
          },
        }}
      >
        {matches?.map((item, idx) => (
          <Box
            component={"li"}
            key={idx}
            style={{
              fontWeight: idx === indexBlur ? "700" : "400",
            }}
            onClick={() => handleBlur(idx)}
          >
            {item.replace(/[()]/g, "")}
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          overflow: "auto",
          maxHeight: "75vh",
          textAlign: "justify",
          "& .MuiTypography-root ": { color: "white" },
          "& b ": { color: "black" },
        }}
      >
        {arrParagraphs.map((paragraph, index) => {
          return index === arrParagraphs.length ? null : (
            <Typography
              key={index}
              sx={
                indexBlur === index
                  ? {
                      opacity: "1",
                      color: "black",
                      fontSize: "18px",
                    }
                  : { opacity: "0.1", fontSize: "18px" }
              }
              dangerouslySetInnerHTML={{ __html: paragraph + " " }}
              component="span"
            />
          );
        })}
      </Box>

      <Button
        sx={{
          position: "fixed",
          bottom: "10px",
          width: "calc(100vw - 32px)",
          textTransform: "none",
          backgroundColor: "#264653",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: "700",
        }}
        variant="contained"
        onClick={() => handleNextStep()}
      >
        Next
      </Button>
    </Box>
  );
}

export default CustomAnswerHighlight;
