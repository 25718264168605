import React from "react";
import { Box, Typography } from "@mui/material";

interface INoDataComponentProps {
  style?: React.CSSProperties;
}

const NoDataComponent = ({ style = {} }: INoDataComponentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: "text.secondary",
        ...style,
      }}
    >
      <Typography variant="subtitle1">No data available</Typography>
    </Box>
  );
};

export default NoDataComponent;
