import { Avatar, Box, Button, CircularProgress, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { IApplicationState, showMessage } from "@/store-redux";
import { useDispatch, useSelector } from "react-redux";
import { extractContent, getUrlServer, refinePrompt } from "@/helpers";
import SuggestAnswerBtn from "./SuggestAnswerBtn";
import AnswerPage from "../../AnswerPage";

// Define types for props
interface ChatQuidedQuestionProps {
  question: { name?: string } | null;
  guidedQuestions: any[];
  aspectSelected: string[];
  isChatBotGenIdea?: boolean;
  onSubmitChatBot?: () => void;
  isLoading?: boolean;
  onBack: () => void;
}

// Define types for state
interface AnswersByAspect {
  [key: string]: string;
}

const ChatQuidedQuestion: React.FC<ChatQuidedQuestionProps> = ({
  question,
  guidedQuestions,
  aspectSelected,
  isChatBotGenIdea,
  onSubmitChatBot,
  isLoading,
  onBack,
}) => {
  const guidedQuestionsSelected = [...guidedQuestions];
  const [answersByAspect, setAnswersByAspect] = useState<AnswersByAspect>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [inputAns, setInputAns] = useState<string>("");
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const [name, setName] = useState<string | null>(null);
  const [isAnswerPage, setIsAnswerPage] = useState(false);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [answersByAspect]);

  const onSubmitAns = (value?: string) => {
    if (!isChatBotGenIdea && !name) {
      setName(value ?? inputAns);
      setInputAns("");
      return;
    }
    setAnswersByAspect((prev) => ({
      ...prev,
      [guidedQuestionsSelected[currentIndex]?.aspect?._id]: value ?? inputAns,
    }));
    setInputAns("");
    setCurrentIndex((prev) => prev + 1);
  };

  const onContinue = () => {
    if (isChatBotGenIdea) {
      if (onSubmitChatBot)
        onSubmitChatBot(
          guidedQuestions.map((item) => ({
            aspect: item.aspect,
            aspectName: refinePrompt(extractContent(item.aspect?.name ?? "")),
            answer: answersByAspect[item.aspect?._id],
          }))
        );
      return;
    }
    // dispatch(showMessage("Comming soon!", "info"));
    setIsAnswerPage(true);
  };

  const inputDisabled =
    (isChatBotGenIdea ? false : inputAns.trim() === "") ||
    guidedQuestionsSelected.length === Object.keys(answersByAspect).length;

  if (isAnswerPage) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
        }}
      >
        <AnswerPage
          isPartTwo={true}
          question={question}
          guidedQuestions={guidedQuestionsSelected}
          answersByAspect={answersByAspect}
          name={name}
        />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: isChatBotGenIdea ? "calc(100vh - 300px)" : "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!isChatBotGenIdea && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            paddingRight: "32px",
            paddingLeft: "16px",
            marginTop: "16px",
            "& button": {
              textTransform: "none",
              height: "40px",
            },
          }}
        >
          <Button onClick={() => onBack()} variant="contained" color="secondary">
            Back
          </Button>
        </Box>
      )}
      {!isChatBotGenIdea && (
        <Box
          sx={{
            fontSize: "24px",
            display: "flex",
            justifyContent: "center",
            padding: "10px ",
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: question?.name ?? "" }}></Box>
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          padding: "10px",
          overflowY: "auto",
        }}
      >
        {!isChatBotGenIdea && (
          <Box
            sx={{
              display: "flex",
              marginBottom: "20px",
            }}
          >
            <SmartToyIcon
              sx={{
                marginRight: "10px",
                fontSize: "36px",
                color: "#ff7269",
              }}
            />
            <Box
              sx={{
                backgroundColor: "#f1f1f1",
                padding: "10px",
                borderRadius: "0 10px 10px 10px",
                maxWidth: "70%",
                "& span": {
                  fontWeight: "bold",
                },
              }}
            >
              What is the name of the <Typography component={"span"}>person</Typography> /{" "}
              <Typography component={"span"}>object</Typography> / <Typography component={"span"}>place</Typography> /{" "}
              <Typography component={"span"}>event</Typography> you want to describe?
            </Box>
          </Box>
        )}
        {name && !isChatBotGenIdea && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#f1f1f1",
                padding: "10px",
                borderRadius: "10px 0 10px 10px",
                maxWidth: "70%",
              }}
            >
              {name}
            </Box>
            <Avatar
              sx={{
                marginLeft: "10px",
                fontSize: "36px",
                color: "#ff7269",
              }}
              src={
                currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"
              }
            />
          </Box>
        )}
        {(name || isChatBotGenIdea) && (
          <>
            {guidedQuestionsSelected.slice(0, currentIndex + 1).map((item) => (
              <div key={item._id}>
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <SmartToyIcon
                    sx={{
                      marginRight: "10px",
                      fontSize: "36px",
                      color: "#ff7269",
                    }}
                  />
                  <Box
                    sx={{
                      backgroundColor: "#f1f1f1",
                      padding: "10px",
                      borderRadius: "0 10px 10px 10px",
                      maxWidth: "70%",
                    }}
                  >
                    <Box dangerouslySetInnerHTML={{ __html: item.name ?? "" }}></Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <Box dangerouslySetInnerHTML={{ __html: item?.category?.name ?? "" }}></Box>
                        <Typography
                          sx={{
                            margin: "0 5px",
                          }}
                        >
                          -
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        <Box dangerouslySetInnerHTML={{ __html: item?.aspect?.name ?? "" }}></Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {answersByAspect[item?.aspect?._id] && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#f1f1f1",
                        padding: "10px",
                        borderRadius: "10px 0 10px 10px",
                        maxWidth: "70%",
                      }}
                    >
                      {answersByAspect[item?.aspect?._id]}
                    </Box>
                    <Avatar
                      sx={{
                        marginLeft: "10px",
                        fontSize: "36px",
                        color: "#ff7269",
                      }}
                      src={
                        currentUser.data?.avatarPath
                          ? `${getUrlServer(currentUser.data?.avatarPath)}`
                          : "/images/people.png"
                      }
                    />
                  </Box>
                )}
              </div>
            ))}
          </>
        )}
        <div ref={bottomRef} />
      </Box>

      <Box
        sx={{
          display: "flex",
          padding: "12px",
        }}
      >
        {guidedQuestionsSelected.length === Object.keys(answersByAspect).length ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" color="secondary" onClick={onContinue} disabled={isLoading}>
              {isLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    marginRight: "10px",
                  }}
                />
              )}
              Continue
            </Button>
          </Box>
        ) : (
          <>
            <Input
              sx={{
                flex: 1,
                border: "1px solid #e0e0e0",
                borderRadius: "10px",
                padding: "0 10px",
                "&::after": {
                  display: "none",
                },
                "&::before": {
                  display: "none",
                },
              }}
              value={inputAns}
              onChange={(event) => setInputAns(event.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !inputDisabled) {
                  onSubmitAns();
                }
              }}
              placeholder="Enter my answer ..."
            />
            {(name || isChatBotGenIdea) && (
              <SuggestAnswerBtn
                inputDisabled={!guidedQuestionsSelected[currentIndex]}
                question={question}
                guidedQuestion={guidedQuestionsSelected[currentIndex]}
                onChoiceSuggest={(value) => {
                  onSubmitAns(value);
                }}
                name={name}
              />
            )}
            <IconButton disabled={inputDisabled} onClick={() => onSubmitAns()}>
              <SendIcon
                sx={
                  inputDisabled
                    ? {}
                    : {
                        color: "#264653",
                      }
                }
              />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChatQuidedQuestion;
