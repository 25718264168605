import WordDetail from "@/pages/LearnPage/components/WordSearch/WordDetail";
import { useGenDict, useListDictReport, useUpdateDict } from "@/services-react-query/queries";
import { showMessage } from "@/store-redux";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GenWordModal from "./GenWordModal";

const DictReportCMSPage = (props) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [genItem, setGenItem] = useState(null);
  const { mutate: listDictReport, isLoading: loading } = useListDictReport({
    onSuccess(data) {
      if (Array.isArray(data)) setItems(data);
    },
  });

  const { mutate: genDict, isLoading: loadingGen } = useGenDict({
    onSuccess(data) {
      setGenItem((prev) => ({
        ...prev,
        gen: data,
      }));
    },
  });

  const { mutate: updateDict, isLoading: loadingUpdate } = useUpdateDict({
    onSuccess(data) {
      setGenItem(null);
      listDictReport();
    },
  });

  useEffect(() => {
    listDictReport();
  }, []);
  return (
    <>
      <Box
        sx={{
          height: "100%",
        }}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              height: "100%",
              "& thead": {
                "& th": {
                  fontWeight: "bold",
                },
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                height: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Word</TableCell>
                  <TableCell>Email</TableCell>

                  <TableCell>Reported at</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => {
                  return (
                    <TableRow>
                      <TableCell>{item.word}</TableCell>
                      <TableCell>
                        <WordDetail word={item} />
                      </TableCell>
                      <TableCell>
                        {item.lastReportedAt ? moment(item.lastReportedAt).format("DD-MM-YYYY") : ""}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            textTransform: "initial",
                            width: "100%",
                          }}
                          onClick={() => {
                            setGenItem(item);
                            genDict(item.word);
                          }}
                          disabled={loadingGen}
                        >
                          Gen
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {genItem?.gen && (
        <GenWordModal
          word={genItem}
          onClose={() => setGenItem(null)}
          onAccept={() => {
            updateDict({
              id: genItem._id,
              lastReportedAt: null,
              phonetic: genItem?.gen?.phonetic,
              content: genItem?.gen?.content,
            });
          }}
          disabled={loadingUpdate}
        />
      )}
    </>
  );
};

export default DictReportCMSPage;
