import { Box, IconButton } from "@mui/material"
import { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FlashCardItem from "./FlashCardItem";
const PlayWithFlashCard = (props) => {
    const {
        items 
    } = props;
    const [indexActive, setIndexActive] = useState(0);

    const onChangeIndexActive = (amount) => {
        setIndexActive(prev => prev + amount < 0 ? 0 : prev + amount > items.length - 1 ? items.length - 1 : prev + amount)
    }

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowRight') {
                onChangeIndexActive(1);
            } else if (event.key === 'ArrowLeft') {
                onChangeIndexActive(-1);
            }
        });
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            
            <IconButton onClick={() => onChangeIndexActive(-1)} disabled={indexActive <= 0}>
                <ArrowBackIosIcon />
            </IconButton>
                <FlashCardItem item={items[indexActive]} />
            <IconButton onClick={() => onChangeIndexActive(1)} disabled={indexActive >= items.length-1}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    )
}
export default PlayWithFlashCard;