import {
  ENDPOINTS,
  ETypeItem,
  getApiUrl,
  getListAspects,
  getListCategories,
  IApplicationState,
  Requests,
} from "@/store-redux";
import { Avatar, Box, Button, CircularProgress, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { extractContent, genCategoryAspect, getUrlServer, randomStringFromDatetime, refinePrompt } from "@/helpers";
import { useParams } from "react-router-dom";
import BotIcon from "./../../../../assets/bot.png";
import SuggestAnswerBtn from "../GuidedQuestionPage/components/SuggestAnswerBtn";
import SendIcon from "@mui/icons-material/Send";
import ListIdeaAI from "./ListIdeaAI";
// Define types for state
interface AnswersByAspect {
  [key: string]: string;
}

const ChatWithBot = () => {
  const dispatch = useDispatch();
  const { questionId } = useParams();
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const listCategory = useSelector((state: IApplicationState) => state.listCategories);
  const [categorySelectedId, setCategorySelectedId] = useState<string | null>(null);
  const listAspectPage = useSelector((state: IApplicationState) => state.listAspects);
  const [aspectSelectedId, setAspectSelectedId] = useState<any[]>([]);
  const [isSubmitAspect, setIsSubmitAspect] = useState(false);
  const [data, setData] = useState(null);
  const [answersByAspect, setAnswersByAspect] = useState<AnswersByAspect>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [inputAns, setInputAns] = useState<string>("");
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listIdeaAI, setListIdeaAI] = useState([]);

  useEffect(() => {
    categorySelectedId && getGuidedQuestions();
  }, [aspectSelectedId]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [answersByAspect]);

  const getGuidedQuestions = async () => {
    const dataRes = await Requests.get(
      getApiUrl(
        `${ENDPOINTS.GET_QUIDED_QUESTIONS.replace(":questionId", questionId ?? "")}?categoryId=${categorySelectedId}`
      )
    );
    if (Array.isArray(dataRes) && dataRes.length > 0) {
      setData(dataRes[0]);
    }
  };
  useEffect(() => {
    if (!listCategory.data?.length) {
      dispatch(
        getListCategories({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          type: ETypeItem.category,
          isOnlyPublic: true,
        })
      );
    }
  }, []);
  useEffect(() => {
    if (categorySelectedId) {
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          parentId: categorySelectedId,
          questionId: questionId,
          type: ETypeItem.aspect,
        })
      );
    }
  }, [categorySelectedId]);

  const allGuidedQuestions = useMemo(() => {
    const items = data?.aspects?.reduce((accA: any, itemA: any) => {
      const aspect = { ...itemA };
      delete aspect.guidedQuestion;
      return [
        ...accA,
        {
          ...itemA.guidedQuestion,
          aspect,
          category: data,
        },
      ];
    }, []);
    if (Array.isArray(items)) return items;
    return [];
  }, [data]);
  const categorySelected = listCategory.data?.find((item) => item._id === categorySelectedId) ?? null;
  const aspectSelected = listAspectPage.data?.filter((item) => aspectSelectedId.includes(item._id)) ?? [];
  const guidedQuestions = useMemo(() => {
    return allGuidedQuestions.filter((item) => aspectSelectedId.includes(item?.aspect?._id));
  }, [aspectSelectedId, allGuidedQuestions]);

  const showMessageSystem = (content: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          marginBottom: "20px",
          "& img": {
            width: "32px",
            height: "32px",
            marginRight: "5px",
          },
        }}
      >
        {/* <SmartToyIcon
          sx={{
            marginRight: "10px",
            fontSize: "36px",
            color: "#ff7269",
          }}
        /> */}
        <img src={BotIcon} />
        <Box
          sx={{
            backgroundColor: "#F3F3FF",
            color: "#313131",
            fontSize: "14px",
            padding: "5px",
            borderRadius: "0 10px 10px 10px",
            maxWidth: "70%",
            "& span": {
              fontWeight: "bold",
            },
          }}
        >
          {content}
        </Box>
      </Box>
    );
  };

  const showMessageUser = (content: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f1f1f1",
            padding: "10px",
            borderRadius: "10px 0 10px 10px",
            maxWidth: "70%",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              "& *": {
                fontSize: "14px",
              },
            }}
            dangerouslySetInnerHTML={{ __html: content || "" }}
          ></Box>
        </Box>
        <Avatar
          sx={{
            marginLeft: "10px",
            fontSize: "32px",
            color: "#ff7269",
          }}
          src={currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"}
        />
      </Box>
    );
  };

  const onGenIdeas = async () => {
    // console.log("onGenIdeas");
    const answers = guidedQuestions.map((item) => ({
      guidedQuestion: item.name ?? "",
      aspect: item.aspect,
      aspectName: refinePrompt(extractContent(item.aspect?.name ?? "")),
      answer: answersByAspect[item.aspect?._id],
    }));
    if (isLoading) return;
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.GENERATE_IDEAS_BY_ASPECTS);
    // const content = `List out some ${answers
    //   .map((item) => `${item.aspectName}: ${item.answer}`)
    //   .join("; ")} of students or people that affect ${refinePrompt(extractContent(detailQuestion.data?.name ?? ""))}`;
    const content = answers.map((item) => `${item.aspectName}: ${item.answer}`).join("\n");
    const res = await Requests.post(apiUrl, {
      question: refinePrompt(extractContent(detailQuestion.data?.name ?? "")),
      questionId,
      prompt: content,
      creativity: 0.9,
      numberOfIdeas: answers.length,
      answers,
    });
    if (Array.isArray(res.data?.result)) {
      setListIdeaAI(
        res.data?.result.map((item, index) => ({
          genByAI: true,
          _id: randomStringFromDatetime(),
          name: `${item.title} (${item.detail})`,
          description: item.example_answer,
          locationIdea: "",
          //   locationIdea: genCategoryAspect(categorySelected, answers[index]?.aspect),
        }))
      );
    }
    setIsLoading(false);
  };

  const onSubmitAns = (value?: string) => {
    setAnswersByAspect((prev) => ({
      ...prev,
      [guidedQuestions[currentIndex]?.aspect?._id]: value ?? inputAns,
    }));
    setInputAns("");
    setCurrentIndex((prev) => prev + 1);
  };

  const inputDisabled = inputAns.trim() === "" || guidedQuestions.length === Object.keys(answersByAspect).length;

  if (listIdeaAI.length > 0) {
    return <ListIdeaAI ideas={listIdeaAI} />;
  }
  return (
    <Box>
      <Box
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        {showMessageSystem("Choose category to start")}
        {!categorySelectedId && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {listCategory.data?.map((category) => {
              return (
                <Box
                  sx={{
                    margin: "5px",
                    border: "1px solid #C6C1C1",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#F3F3FF",
                    },
                  }}
                  onClick={() => {
                    setCategorySelectedId(category._id);
                  }}
                >
                  <Box
                    sx={{
                      "& *": {
                        fontSize: "14px",
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: category?.name || "" }}
                  ></Box>
                </Box>
              );
            })}
          </Box>
        )}
        {categorySelected && (
          <>
            {showMessageUser(categorySelected?.name)}
            {showMessageSystem("Choose aspect")}
            {!isSubmitAspect && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {listAspectPage.data?.map((aspect) => {
                    return (
                      <Box
                        sx={{
                          margin: "5px",
                          border: "1px solid #C6C1C1",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#F3F3FF",
                          },
                          ...(aspectSelectedId.includes(aspect._id)
                            ? {
                                border: "1px solid #0A14FF",
                                color: "#0A14FF",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setAspectSelectedId((prev) =>
                            prev.includes(aspect._id)
                              ? prev.filter((item) => item !== aspect._id)
                              : [...prev, aspect._id]
                          );
                        }}
                      >
                        <Box
                          sx={{
                            "& *": {
                              fontSize: "14px",
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: aspect?.name || "" }}
                        ></Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      background: "#0A14FF",
                      color: "#fff",
                      textTransform: "unset",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      setIsSubmitAspect(true);
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
            {isSubmitAspect && aspectSelected?.length > 0 && (
              <>
                {showMessageUser(aspectSelected.map((aspect) => aspect?.name || "").join("\n"))}
                {guidedQuestions.slice(0, currentIndex + 1).map((item) => (
                  <div key={item._id}>
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "20px",
                        "& img": {
                          width: "32px",
                          height: "32px",
                          marginRight: "5px",
                        },
                      }}
                    >
                      {/* <SmartToyIcon
                        sx={{
                          marginRight: "10px",
                          fontSize: "36px",
                          color: "#ff7269",
                        }}
                      /> */}
                      <img src={BotIcon} />
                      <Box
                        sx={{
                          backgroundColor: "#F3F3FF",
                          color: "#313131",
                          padding: "5px",
                          borderRadius: "0 10px 10px 10px",
                          maxWidth: "70%",
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: "14px",
                            "& *": {
                              fontSize: "14px",
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: item.name ?? "" }}
                        ></Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <Box dangerouslySetInnerHTML={{ __html: item?.category?.name ?? "" }}></Box>
                            <Typography
                              sx={{
                                margin: "0 5px",
                              }}
                            >
                              -
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              fontSize: "12px",
                            }}
                          >
                            <Box dangerouslySetInnerHTML={{ __html: item?.aspect?.name ?? "" }}></Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {answersByAspect[item?.aspect?._id] && showMessageUser(answersByAspect[item?.aspect?._id])}
                  </div>
                ))}
              </>
            )}
          </>
        )}
        <div ref={bottomRef} />
      </Box>
      {categorySelected && isSubmitAspect && aspectSelected?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            padding: "12px",
          }}
        >
          {guidedQuestions.length && guidedQuestions.length === Object.keys(answersByAspect).length ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "#0A14FF",
                  color: "#fff",
                  textTransform: "unset",
                }}
                onClick={onGenIdeas}
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                )}
                Gen ideas
              </Button>
            </Box>
          ) : (
            <>
              <Input
                sx={{
                  flex: 1,
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  padding: "0 10px",
                  "&::after": {
                    display: "none",
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                value={inputAns}
                onChange={(event) => setInputAns(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !inputDisabled) {
                    onSubmitAns();
                  }
                }}
                placeholder="Enter my answer ..."
              />
              {/* <SuggestAnswerBtn
                inputDisabled={!guidedQuestions[currentIndex]}
                question={detailQuestion.data}
                guidedQuestion={guidedQuestions[currentIndex]}
                onChoiceSuggest={(value) => {
                  onSubmitAns(value);
                }}
              /> */}
              <IconButton disabled={inputDisabled} onClick={() => onSubmitAns()}>
                <SendIcon
                  sx={
                    inputDisabled
                      ? {}
                      : {
                          color: "#0A14FF",
                        }
                  }
                />
              </IconButton>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
export default ChatWithBot;
