import { useMutation, useQuery } from "react-query";
import { IQueryOptions } from ".";
import { getMyPractices, getQuotaPractice, postPracticeEvaluate, postPracticeTranscribe, sendAudioPractice } from "../api";

export const useUploadFilePractice = (options: IQueryOptions) => {
  return useMutation(sendAudioPractice, options);
};

export const usePracticeTranscribe = (options: IQueryOptions) => {
  return useMutation(postPracticeTranscribe, options);
};

export const usePracticeEvaluate = (options: IQueryOptions) => {
  return useMutation(postPracticeEvaluate, options);
};

export const useGetQuotaPractice = (options: IQueryOptions) => {
  return useQuery(["fetchQuotaPractice"], () => getQuotaPractice(), {
    enabled: true,
    keepPreviousData: true,
    ...options,
  });
};

export const useGetMyPractices = (options: IQueryOptions) => {
  return useQuery(["fetchMyPractices"], () => getMyPractices(), {
    enabled: true,
    keepPreviousData: true,
    ...options,
  });
};