import { IPracticeEvaluateOutput } from "@/common";
import {
  Box,
  Typography,
  Card,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from "@mui/material";
import Markdown from "react-markdown";

interface IDetailPracticeProps {
  voiceTranscript: IPracticeEvaluateOutput["voiceTranscript"];
  gptEvaluates: IPracticeEvaluateOutput["gptEvaluates"];
}

function DetailPractice({ voiceTranscript, gptEvaluates }: IDetailPracticeProps) {
  const theme = useTheme();
  const replaceText = (text: string) => {
    return text.replace(/<\/band>/g, "").replace(/<band>/g, "Band ");
  };
  return (
    <Box minHeight={"30vh"}>
      <Box>
        <Typography component={"span"} sx={{ fontWeight: "bold", fontSize: 18 }}>
          Your transcript
        </Typography>
        <Card
          sx={{
            padding: "16px",
            marginTop: "16px",
          }}
        >
          {voiceTranscript}
        </Card>
      </Box>
      {gptEvaluates ? (
        <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography component={"span"} sx={{ marginBottom: "16px", fontWeight: "bold", fontSize: 18 }}>
            Your result
          </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                "& thead": {
                  backgroundColor: theme.palette.secondary.light,
                  "& th": {
                    fontSize: "16px",
                    color: "white",
                  },
                },
                "& tr": {
                  "& td:last-of-type": {
                    fontWeight: "bold",
                  },
                },
                "& td": {
                  fontSize: "16px",
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Speaking Criteria</TableCell>
                  <TableCell>Explanation</TableCell>
                  {gptEvaluates?.[0]?.suggestion ? <TableCell>Suggestion</TableCell> : null}
                  <TableCell>Band</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gptEvaluates?.map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{row.criterium}</TableCell>
                    <TableCell>
                      {!row.suggestion ? <Markdown>{replaceText(row.explanation)}</Markdown> : row.explanation}
                    </TableCell>
                    {row.suggestion ? <TableCell>{row.suggestion}</TableCell> : null}
                    <TableCell align="center">{row.band}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </Box>
  );
}

export default DetailPractice;
