import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
import dataAspect from "./personalized.json";
import { ENDPOINTS, Requests, getApiUrl } from "@/common";
import { jsonToFile } from "@/helpers";
import AspectDetail, { CellName } from "./components/AspectDetail";
import _ from "lodash";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { EBackOfficeCode } from "@/common/types/backoffice";
const PersonalizedCMSPage: React.FC = () => {
  const dispatch = useDispatch();
  const [dataAspectFile, setDataAspectFile] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [aspectDetail, setAspectDetail] = useState(null);

  useEffect(() => {
    if (isLoading) setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    getAspect();
  }, []);

  const getAspect = async () => {
    try {
      const dataRes = await Requests.get(
        getApiUrl(ENDPOINTS.BACKOFFICE_GET_BY_CODE.replace(":code", EBackOfficeCode.PERSONALIZED_CMS))
      );
      if (Array.isArray(dataRes?.data)) {
        const data = dataRes?.data;
        setDataAspectFile(data);
        setData(data);
      } else {
        setDataAspectFile(dataAspect);
        setData(dataAspect);
      }
    } catch (e) {
      setDataAspectFile(dataAspect);
      setData(dataAspect);
    }
  };

  const onCancel = () => {
    setData(dataAspectFile);
    setIsLoading(true);
  };

  const onSave = async (data) => {
    setIsSaving(true);
    // save backoffice by code: PERSONALIZED_CMS
    const file = jsonToFile(data);
    const urlSaveBackoffice = getApiUrl(
      ENDPOINTS.BACKOFFICE_SAVE_BY_CODE.replace(":code", EBackOfficeCode.PERSONALIZED_CMS)
    );

    try {
      await Requests.post(
        urlSaveBackoffice,
        {
          file: file,
        },
        true,
        false
      );
      setIsSaving(false);
      setDataAspectFile(data);
      dispatch(showMessage("Update personalized data success", "success"));
    } catch (e) {
      setIsSaving(false);
      dispatch(showMessage("Update personalized data error", "error"));
    }
    // const file = jsonToFile(data);
    // const urlUploadFile = getApiUrl(ENDPOINTS.SAVE_PERSONALIZED_CMS);
    // try {
    //   await Requests.post(
    //     urlUploadFile,
    //     {
    //       file: file,
    //     },
    //     true,
    //     false
    //   );
    //   setIsSaving(false);
    //   setDataAspectFile(data);
    //   dispatch(showMessage("Update personalized data success", "success"));
    // } catch (e) {
    //   setIsSaving(false);
    //   dispatch(showMessage("Update personalized data error", "error"));
    // }
  };
  const isChange = useMemo(() => {
    return !_.isEqual(dataAspectFile, data);
  }, [data, dataAspectFile]);
  if (isLoading) return <></>;

  if (aspectDetail) {
    return (
      <Box sx={{ height: "100%", position: "relative" }}>
        {isSaving && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              backgroundColor: "rgba(255,255,255, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
        )}
        <AspectDetail
          item={aspectDetail}
          onBack={() => {
            setAspectDetail(null);
          }}
          onSave={(value) => {
            const dataSaved = data.map((item, index) => (item.code === aspectDetail.code ? value : item));
            setData(dataSaved);
            onSave(dataSaved);
            setAspectDetail(null);
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      {isSaving && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "rgba(255,255,255, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )}

      {isChange && (
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            onClick={() => onCancel()}
            variant="contained"
            sx={{
              backgroundColor: "#f1f1f1",
              color: "#000",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => onSave(data)} variant="contained" color="secondary">
            Save
          </Button>
        </Box>
      )}
      <TableContainer component={Paper} sx={{ height: isChange ? "calc(100% - 60px)" : "100%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Code
              </TableCell> */}
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                English
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Vietnamese
              </TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, idx) => {
              const countItems =
                row.items.length +
                (Array.isArray(row.children)
                  ? row.children.reduce((accumulator, currentValue) => (accumulator += currentValue.items.length), 0)
                  : 0);
              return (
                <TableRow key={idx}>
                  {/* <TableCell>{row.code}</TableCell> */}
                  <TableCell>
                    <CellName
                      name={row.name.en}
                      onSave={(value) => {
                        setData((prev) =>
                          prev.map((item) =>
                            item.code === row.code
                              ? {
                                  ...item,
                                  name: {
                                    ...item.name,
                                    en: value,
                                  },
                                }
                              : item
                          )
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <CellName
                      name={row.name.vi}
                      onSave={(value) => {
                        setData((prev) =>
                          prev.map((item) =>
                            item.code === row.code
                              ? {
                                  ...item,
                                  name: {
                                    ...item.name,
                                    vi: value,
                                  },
                                }
                              : item
                          )
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {Array.isArray(row.children) ? row.children.length : 0} children, {countItems} items
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => setAspectDetail(row)}>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PersonalizedCMSPage;
