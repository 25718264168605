import { useMutation } from "react-query";
import { genDict, listDictReport, sendDictReport, updateDict } from "../api/dict.service";
import { IQueryOptions } from ".";

export const useSendDictReport = (options: IQueryOptions) => {
  return useMutation(sendDictReport, options);
};

export const useListDictReport = (options: IQueryOptions) => {
  return useMutation(listDictReport, options);
};

export const useGenDict = (options: IQueryOptions) => {
  return useMutation(genDict, options);
};

export const useUpdateDict = (options: IQueryOptions) => {
  return useMutation(updateDict, options);
};
