import { Box } from "@mui/material";
import ukFlag from "@/assets/icons/uk_flag.png";
import viFlag from "@/assets/icons/vi_flag.png";
import { useEffect, useState } from "react";

const SwitchLang = (props) => {
  const { onChange } = props;
  const [value, setValue] = useState("vi");
  useEffect(() => {
    onChange(value);
  }, [value]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        minWidth: "80px",
        height: "fit-content",
        borderRadius: "15px",
        border: "1px solid #264653",
        position: "absolute",
        right: "8px",
        top: "8px",
        backgroundColor: "white",
        "& img": {
          width: "24px",
        },
      }}
    >
      <Box
        onClick={() => {
          setValue("en");
        }}
        sx={{
          padding: "2px 10px",
          borderRadius: "15px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#264653",
          },
          ...(value === "en" ? { backgroundColor: "#264653" } : {}),
        }}
      >
        <img src={ukFlag} />
      </Box>
      <Box
        onClick={() => {
          setValue("vi");
        }}
        sx={{
          padding: "2px 10px",
          borderRadius: "15px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#264653",
          },
          ...(value === "vi" ? { backgroundColor: "#264653" } : {}),
        }}
      >
        <img src={viFlag} />
      </Box>
    </Box>
  );
};
export default SwitchLang;
