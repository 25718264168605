import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from 'posthog-js/react'

// check environment not localhost , then init sentry
if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://f2aa9bf7922ad71502a33863b0b5be37@o4507314979733504.ingest.de.sentry.io/4507314982551632",
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.fongfluency\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);
