import { ENDPOINTS, getApiUrl } from "@/common/endpoints";
import { Requests } from "@/common/requests";
import { put, takeLatest } from "redux-saga/effects";
import { showMessage } from "../actions";
import { AllTypeActions } from "../types";

function* sendAudioAnswer({ input, type }: any): Generator<any> {
  try {
    yield put({ type: `${type}_ATTEMPT` });

    // API call for uploading
    const apiUrlUpload = getApiUrl(ENDPOINTS.POST_CHALLENGES_UPLOAD);
    const dataUpload: any = yield Requests.post(apiUrlUpload, input);
    // console.log("dataUpload", dataUpload);
    //   {
    //     "questionId": "65d32418356f7c043a2deb72",
    //     "baremId": "65f7150faf9adc91801093e9",
    //     "voicePath": "uploads/Record (online-voice-recorder.com).mp3",
    //     "owner": "65d3164753b7c74a25a9a4dc",
    //     "status": "uploaded",
    //     "createdAt": "2024-04-11T19:28:02.115Z"
    // }
    if (dataUpload?.success) {
      yield put(showMessage("Upload your answer success", "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: dataUpload.message });
      return;
    }

    // API call for transcription
    const { id: idAudio } = dataUpload;
    const apiUrlTranscribe = getApiUrl(ENDPOINTS.POST_CHALLENGES_TRANSCRIBE, {
      id: idAudio,
    });
    const dataTranscribe: any = yield Requests.post(apiUrlTranscribe);
    // console.log("dataTranscribe", dataTranscribe);

    if (dataTranscribe?.success) {
      yield put(showMessage("Transcribe your answer success", "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: dataTranscribe.message });
      return;
    }

    // API call for evaluation
    const { id: idTranscribe } = dataTranscribe;

    const apiUrlEvaluate = getApiUrl(ENDPOINTS.POST_CHALLENGES_EVALUATE, {
      id: idTranscribe,
    });
    const dataEvaluate: any = yield Requests.post(apiUrlEvaluate);
    // console.log("dataEvaluate", dataEvaluate);

    if (dataEvaluate?.success) {
      yield put(showMessage("Evaluate your answer success", "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: dataEvaluate.message });
      return;
    }

    yield put({
      type: `${type}_SUCCESS`,
      data: {
        upload: dataUpload.data,
        transcription: dataTranscribe.data,
        evaluation: dataEvaluate.data,
      },
    });
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

export function* challengeActions() {
  yield takeLatest(AllTypeActions.POST_CHALLENGES_UPLOAD, sendAudioAnswer);
  yield takeLatest(AllTypeActions.POST_CHALLENGES_TRANSCRIBE, sendAudioAnswer);
  yield takeLatest(AllTypeActions.POST_CHALLENGES_EVALUATE, sendAudioAnswer);
}
