import SearchInput from "@/components/Search";
import configs from "@/configs.json";
import { changeFileName, getFileExtension, getUrlServer, searchVietnamese } from "@/helpers";
import data_personalized from "@/pages/CMS/PersonalizedCMSPage/personalized.json";
import { useUpdateInfoUser } from "@/services-react-query/queries";
import { ENDPOINTS, IApplicationState, Requests, getApiUrl, getCurrentUser, showMessage } from "@/store-redux";
import { Box, Button, CircularProgress, Tab, Tabs, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarUpload from "./components/AvatarUpload";
import SwitchLang from "./components/SwitchLang";
import { EBackOfficeCode } from "@/common/types/backoffice";
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PersonalizedPage() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const [dataAspect, setDataAspect] = useState([]);
  const [lang, setLang] = useState("vi");
  const [aspectTab, setAspectTab] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [childrenSelected, setChildrenSelected] = useState([]);
  useEffect(() => {
    getAspect();
  }, []);
  const getAspect = async () => {
    try {
      const dataRes = await Requests.get(
        getApiUrl(ENDPOINTS.BACKOFFICE_GET_BY_CODE.replace(":code", EBackOfficeCode.PERSONALIZED_CMS))
      );
      if (Array.isArray(dataRes?.data)) {
        const data = dataRes?.data;
        setDataAspect(data);
      } else {
        setDataAspect(data_personalized);
      }
    } catch (e) {
      setDataAspect(data_personalized);
    }
  };
  useEffect(() => {
    if (dataAspect.length) {
      setAspectTab(dataAspect[0].code);
    }
  }, [dataAspect]);
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setAspectTab(newValue);
    setSearchText("");
    setChildrenSelected([]);
  };
  const [avatar, setAvatar] = useState<string | null>(null);
  const [backgroudProfile, setBackgroudProfile] = useState<string | null>(null);
  const [preference, setPreference] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const onHandleSearch = (value: string) => {
    setSearchText(value ?? "");
  };
  const theme = useTheme();
  const { mutate: updateInfoUser, isLoading: updateInfoUserLoading } = useUpdateInfoUser({
    onSuccess: () => {
      dispatch(getCurrentUser());
      dispatch(showMessage("Update item success", "success"));
    },
  });

  useEffect(() => {
    getFromCurrenUser();
  }, [currentUser]);
  const getFromCurrenUser = () => {
    const preference = currentUser.data?.preference ? currentUser.data.preference : {};
    setPreference(preference);
    setAvatar(currentUser.data?.avatarPath ?? null);
    setBackgroudProfile(currentUser.data?.backgroundPath ?? null);
  };
  const onClickOption = (option) => {
    setPreference((prev) => ({
      ...prev,
      [aspectTab]: (prev[aspectTab] ?? []).includes(option.code)
        ? (prev[aspectTab] ?? []).filter((item) => item !== option.code)
        : [...(prev[aspectTab] ?? []), option.code],
    }));
    setIsEdit(true);
  };

  const onSave = () => {
    const avatarFile = avatar;

    updateInfoUser({
      email: currentUser.data?.email,
      preference,
      ...(typeof avatarFile === "string"
        ? {}
        : {
            avatar: avatarFile
              ? changeFileName(
                  avatarFile,
                  `${currentUser.data?.username ?? ""}_${Date.now()}.${getFileExtension(avatarFile)}`
                )
              : "",
          }),
      ...(typeof backgroudProfile === "string"
        ? {}
        : {
            background: backgroudProfile
              ? changeFileName(
                  backgroudProfile,
                  `${currentUser.data?.username ?? ""}_${Date.now()}.${getFileExtension(backgroudProfile)}`
                )
              : "",
          }),
    });
    setIsEdit(false);
  };

  const onCancel = () => {
    setIsEdit(false);
    getFromCurrenUser();
  };

  const { options, children } = useMemo(() => {
    let options = dataAspect.find((aspect) => aspect.code === aspectTab)?.items ?? [];
    let children = dataAspect.find((aspect) => aspect.code === aspectTab)?.children ?? [];
    if (childrenSelected.length === 0) {
      options = [...options, ...children.reduce((val, child) => (val = [...val, ...child.items]), [])];
    } else {
      options = children.reduce(
        (val, child) => (childrenSelected.includes(child.id) ? (val = [...val, ...child.items]) : (val = [...val])),
        []
      );
    }
    if (searchText.trim() === "") {
    } else {
      options = options.filter((option) => searchVietnamese(`${option.name.en} ${option.name.vi}`, searchText) !== -1);
    }
    return {
      options,
      children,
    };
  }, [aspectTab, dataAspect, searchText, childrenSelected]);
  const listOptionsSelected = useMemo(() => {
    return options?.filter((option) => preference[aspectTab]?.includes?.(option.code as any));
  }, [aspectTab, options, preference]);

  const listOptionsUnselected = useMemo(() => {
    return options?.filter((option) => !preference[aspectTab]?.includes?.(option.code as any));
  }, [aspectTab, options, preference]);
  return (
    <Box
      sx={{
        padding: "0px !important",
      }}
    >
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          padding: "0",
        })}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "300px",
              "& > div:first-of-type": {
                position: "relative",
                height: "300px",
                "& .MuiAvatar-root": {
                  "& .MuiAvatar-img": {
                    borderRadius: "0px",
                  },
                },
              },
            }}
          >
            <AvatarUpload
              url={typeof backgroudProfile === "string" ? getUrlServer(backgroudProfile) : null}
              onChange={(value) => {
                setBackgroudProfile(value);
                setIsEdit(true);
              }}
            />
            <SwitchLang onChange={(value: string) => setLang(value)} />
          </Box>
        </Box>
        <Box
          sx={{
            height: "72px",
            display: "flex",
            paddingInline: "32px",
            boxShadow: "0 10px 10px rgba(0, 0, 0, 0.08)",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > div:first-of-type": {
                height: "120px",
                marginTop: "-64px",
              },
              "& .MuiAvatar-root": {
                height: "120px",
                width: "120px",
                borderWidth: "4px",
                borderColor: "white",
                "& img": {
                  borderRadius: "1000px !important",
                },
              },
            }}
          >
            <AvatarUpload
              // disabled={!isEdit}
              url={typeof avatar === "string" ? getUrlServer(avatar) : null}
              onChange={(value) => {
                setAvatar(value);
                setIsEdit(true);
              }}
              isAvatar
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "8px",
              }}
            >
              <Typography
                component={"span"}
                sx={() => ({
                  fontSize: 18,
                  fontWeight: 700,
                })}
              >
                Hi {currentUser.data?.username}
              </Typography>
              <Typography
                component={"span"}
                sx={() => ({
                  fontSize: 16,
                })}
              >
                Let's us know more about yourself
              </Typography>
            </Box>
          </Box>
          {isEdit && (
            <Box sx={{}}>
              <Button
                onClick={() => onCancel()}
                variant="contained"
                sx={{
                  backgroundColor: "#f1f1f1",
                  color: "#000",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
                disabled={updateInfoUserLoading}
              >
                {updateInfoUserLoading ? <CircularProgress size={30} /> : "Cancel"}
              </Button>
              <Button onClick={() => onSave()} variant="contained" color="secondary" disabled={updateInfoUserLoading}>
                {updateInfoUserLoading ? <CircularProgress size={30} /> : "Save"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: "16px",
          paddingInline: "16px",
          "& .item": {
            padding: "8px 16px",
            backgroundColor: "#f1f1f1",
            cursor: "pointer",
            borderRadius: "20px",
            "&:hover": {
              backgroundColor: "#ccc",
            },
            "&.selected": {
              backgroundColor: theme.palette.secondary.main,
              color: "white",
            },
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "calc(100vw - 272px)",
            "& .MuiTabs-root": {
              "& .MuiTabs-flexContainer": {
                "& button": {
                  textTransform: "none",
                  fontSize: "16px",
                  borderRadius: "30px",
                  minHeight: "48px",
                  height: "48px",
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.secondary.light,
                    color: "white",
                  },
                },
              },
            },
          }}
        >
          <Tabs
            variant="scrollable"
            value={aspectTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: {
                height: 0,
              },
            }}
          >
            {dataAspect.map((aspect, index) => {
              return (
                <Tab
                  key={index}
                  value={aspect.code}
                  label={`A${index + 1} - ${lang === "en" ? aspect.name.en : aspect.name.vi}`}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: "18px",
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
        {children.length > 0 && (
          <Box
            sx={{
              paddingTop: "5px",
              maxWidth: "calc(100vw - 272px)",
              "& .MuiTabs-root": {
                minHeight: "unset",
                "& .MuiTabs-flexContainer": {
                  "& button": {
                    textTransform: "none",
                    fontSize: "14px",
                    height: "min-content",
                    padding: "8px!important",
                    minHeight: "unset",
                  },
                },
              },
            }}
          >
            <Tabs
              variant="scrollable"
              TabIndicatorProps={{
                sx: {
                  height: 0,
                },
              }}
            >
              {children.map((child, index) => {
                return (
                  <Tab
                    key={index}
                    value={child.id}
                    label={`${lang === "en" ? child.name?.en ?? "" : child.name?.vi ?? ""}`}
                    {...a11yProps(index)}
                    sx={{
                      fontSize: "14px!important",
                      textTransform: "capitalize",
                      margin: "0 5px",
                      borderRadius: "20px",
                      ...(childrenSelected.includes(child.id)
                        ? {
                            backgroundColor: theme.palette.secondary.light,
                            color: "#fff",
                          }
                        : {}),
                    }}
                    onClick={() => {
                      setChildrenSelected((prev) =>
                        prev.includes(child.id) ? prev.filter((item) => item !== child.id) : [...prev, child.id]
                      );
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
        )}
        <SearchInput valueSearch={searchText} changeWithNotText={true} handleSearch={onHandleSearch} />
        <Box
          sx={{
            marginTop: "16px",
            maxHeight: children.length > 0 ? "calc(100vh - 240px)" : "calc(100vh - 190px)",
            overflowY: "auto",
            "&>div": {
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              "&.list-selected": {
                marginBottom: "16px",
                paddingBottom: "16px",
                borderBottom: "1px solid #ccc",
              },
            },
          }}
        >
          {listOptionsSelected?.length ? (
            <Box className="list-selected">
              {listOptionsSelected?.map((option, idx) => {
                return (
                  <Typography
                    key={idx}
                    sx={{}}
                    component={"span"}
                    className={clsx("item", "selected")}
                    onClick={() => {
                      onClickOption(option);
                    }}
                  >
                    {lang === "en" ? option.name.en : option.name.vi}
                  </Typography>
                );
              })}
            </Box>
          ) : null}
          <Box sx={{ paddingBottom: "16px" }}>
            {listOptionsUnselected.map((option, idx) => {
              return (
                <Typography
                  key={idx}
                  sx={{}}
                  component={"span"}
                  className={clsx("item")}
                  onClick={() => {
                    onClickOption(option);
                  }}
                >
                  {lang === "en" ? option.name.en : option.name.vi}
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PersonalizedPage;
