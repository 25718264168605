import { ENDPOINTS, getApiUrl, IItems, Requests } from "@/common";
import { extractContent, refinePrompt } from "@/helpers";
import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
interface FormGenerateGuidedQProps {
  questionContent: string;
  onGenGuidedQ: (items: string[]) => void;
}
interface IFormData {
  content: string;
  // numberOfGuidedQuestions: number;
  aspects: IItems[];
}
const FormGenerateGuidedQ: React.FC<FormGenerateGuidedQProps> = ({ questionContent, onGenGuidedQ, aspects }) => {
  const { register, handleSubmit, reset, watch } = useForm<IFormData>({
    defaultValues: {
      content: "",
      // numberOfGuidedQuestions: 1,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: IFormData) => {
    if (isLoading) return;
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.GEN_GUIDED_QUESTIONS);
    try {
      const items = await Requests.post(apiUrl, {
        content: data.content,
        question: refinePrompt(questionContent),
        aspects: aspects.map((item: IItems) => ({
          id: item._id,
          name: refinePrompt(extractContent(item.name)),
        })),
      });
      if (Array.isArray(items) && items.length > 0) onGenGuidedQ(items);
    } catch (e) {}
    setIsLoading(false);
  };

  useEffect(() => {
    if (questionContent) {
      const content = `Generate a list of guided questions that relates to the person who has to describe that famous person based on the following aspect`;
      reset({
        content,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionContent]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} className="form-generate-idea">
        <Box
          sx={{
            display: "flex",
          }}
        >
          <TextField
            sx={{
              flex: 1,
            }}
            type="text"
            multiline
            rows={3}
            {...register("content", { required: true })}
          />
          {/* <TextField
            sx={{
              margin: "0 5px",
              width: "120px",
            }}
            label="Number of guided questions"
            type="number"
            {...register("numberOfGuidedQuestions", { required: true })}
          /> */}
          <Box className="icon-submit">
            <IconButton type="submit" disabled={isLoading}>
              {isLoading ? <CircularProgress size={20} /> : <SendIcon color="secondary" />}
            </IconButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default FormGenerateGuidedQ;
