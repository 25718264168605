import { useUpdateInfoUser } from "@/services-react-query/queries";
import EditIcon from "@mui/icons-material/Edit";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Divider, Tab, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../constants";
import AbcIcon from "@mui/icons-material/Abc";

import {
  AllTypeActions,
  ETypeItem,
  IApplicationState,
  IEditInfoUser,
  IUser,
  getCurrentUser,
  getListAnswerOfUser,
  getListTopics,
  getListUser,
  resetData,
  showMessage,
} from "../../store-redux";
import AnswerTab from "./components/AnswerTab";
import ModalAddEditUserProfile from "./components/ModalAddEditUserProfile";
import PracticeTab from "./components/PracticeTab";
import GrammarTab from "./components/GrammarTab";

function ProfilePage() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const listTopic = useSelector((state: IApplicationState) => state.listTopics);
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const removeAnswerItemState = useSelector((state: IApplicationState) => state.removeAnswerItem);

  const [openModalOneUser, setOpenModalOneUser] = useState<{
    isOpen: boolean;
    userData?: IUser;
  }>({
    isOpen: false,
    userData: undefined,
  });

  const updateUserState = useSelector((state: IApplicationState) => state.updateUser);
  const { mutate: updateInfoUser, isLoading: updateInfoUserLoading } = useUpdateInfoUser({
    onSuccess: (res) => {
      if (res.success) {
        dispatch(showMessage(res.message ?? "Update item success", "success"));
        dispatch(getCurrentUser());
        setOpenModalOneUser({ isOpen: false });
      } else {
        dispatch(showMessage(res.message, "error"));
      }
    },
  });

  const [tab, setTab] = useState("1");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const onSave = (data: IEditInfoUser) => {
    const dataSend = {
      ...data,
      username: data.username,
      password: data.password,
      newPassword: data.newPassword,
    };
    updateInfoUser(dataSend);
  };

  useEffect(() => {
    if (updateUserState.data && !updateUserState.error && !updateUserState.isFetching) {
      setOpenModalOneUser({ isOpen: false });
      dispatch(getListUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserState]);

  useEffect(() => {
    !listTopic?.data?.length &&
      dispatch(
        getListTopics({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          type: ETypeItem.topic,
        })
      );
  }, []);

  useEffect(() => {
    dispatch(
      getListAnswerOfUser({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
      })
    );
  }, []);

  useEffect(() => {
    if (removeAnswerItemState.data && !removeAnswerItemState.isFetching) {
      dispatch(
        getListAnswerOfUser({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
        })
      );
      dispatch(resetData(AllTypeActions.REMOVE_ANSWER_ITEM));
    }
  }, [removeAnswerItemState]);

  return (
    <Box
      sx={() => ({
        padding: "16px",
        "& h2": {
          height: "54px",
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "54px",
        },
      })}
    >
      <Box
        sx={{
          position: "relative",
          "& .text": {
            letterSpacing: "0em",
            textAlign: "left",
          },
          "& .content-header": {},
          "& h3": {
            fontSize: "26px",
            fontWeight: "700",
            lineHeight: "24px",
            fontFamily: "Poppins",
          },
        }}
      >
        <Box
          className="content-header"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            marginBottom: "16px",
            "& .btn-edit-profile": {
              textTransform: "none",
              fontSize: "16px",
              padding: "0 8px",
              height: "48px",
              color: "#fff",
              "& svg": {
                marginRight: "8px",
              },
              "&.color-green": {
                "&:hover": {
                  backgroundColor: "#79c745",
                },
              },
              "&.color-pink": {
                "&:hover": {
                  backgroundColor: "#5c3fb3",
                },
              },
            },
          }}
        >
          <Box>
            <Typography component={"h3"} className="text">
              Hey {currentUser.data?.username ?? ""}
            </Typography>
            {currentUser.data?.expiredTime ? (
              <Typography component={"p"} sx={{ marginBottom: "10px" }}>
                Expiry date until <b>{moment(currentUser.data?.expiredTime).format("MM/DD/YYYY")}</b>
              </Typography>
            ) : null}
          </Box>
          <Button
            onClick={() => {
              setOpenModalOneUser({ isOpen: true, userData: currentUser?.data });
            }}
            className="btn-edit-profile color-green"
            variant="text"
            sx={{
              backgroundColor: "#79c745",
            }}
          >
            <EditIcon />
            Edit profile
          </Button>
          <NavLink to={ROUTES.WORDBANK}>
            <Button
              className="btn-edit-profile color-pink"
              sx={{
                backgroundColor: "#5c3fb3",
              }}
            >
              <SpellcheckIcon />
              Your Wordbank
            </Button>
          </NavLink>
        </Box>
        <Divider />
        <Box
          sx={{
            maxWidth: "calc(100vw - 32px)",
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              "& .MuiTabs-root": {
                "& .MuiTabs-flexContainer": {
                  "& button": {
                    textTransform: "none",
                    fontSize: "16px",
                    borderRadius: "4px",
                    minHeight: "48px",
                    height: "46px",
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.secondary.light,
                      color: "white",
                    },
                  },
                },
              },
              "& .MuiTabPanel-root": {
                padding: 0,
                paddingBlock: "16px",
                maxHeight: "calc(100vh - 220px)",
                overflowY: "auto",
              },
            }}
          >
            <TabContext value={tab}>
              <TabList
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    height: 0,
                  },
                }}
              >
                <Tab label="List Answers" value="1" icon={<QuestionAnswerIcon />} iconPosition="start" />
                <Tab label="List Practices" value="2" icon={<TrackChangesIcon />} iconPosition="start" />
                <Tab label="List Grammars" value="3" icon={<AbcIcon />} iconPosition="start" />
              </TabList>
              <TabPanel value="1">
                <AnswerTab />
              </TabPanel>
              <TabPanel value="2">
                <PracticeTab />
              </TabPanel>
              <TabPanel value="3">
                <GrammarTab />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
      <ModalAddEditUserProfile
        open={openModalOneUser.isOpen}
        onClose={() => setOpenModalOneUser({ isOpen: false })}
        onSave={onSave}
        loading={updateInfoUserLoading}
        titleModal={`Update info user`}
        data={openModalOneUser.userData}
      />
    </Box>
  );
}

export default ProfilePage;
