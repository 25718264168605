import { Box, Chip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { MenuAction } from "../../../../components/MenuAction";
import { EAction, ETypeItem, IItems, updateOrder } from "../../../../store-redux";
import { styles } from "../styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { IHandleAction } from "../interface";
import { getUrlServer } from "@/helpers";

interface ISortQuestionsProps {
  listData: IItems[];
  handleAction: (data: IHandleAction) => void;
  isPageChallenge?: boolean;
  onHandleClickQuestion?: (id: string) => void;
  selectedQuestionId: string | null;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface ItemsSort extends IItems {
  id: string;
}

function SortQuestions({
  listData,
  handleAction,
  isPageChallenge,
  onHandleClickQuestion,
  selectedQuestionId,
  setSelectedQuestionId,
}: ISortQuestionsProps) {
  const navigate = useNavigate();
  const [listQuestions, setListQuestions] = useState<ItemsSort[]>(
    listData?.map((item) => ({ ...item, id: item._id })) ?? []
  );
  const dispatch = useDispatch();
  const listQuestionsRef = useRef(listData);

  const handleSortItem = (newState: ItemsSort[]) => {
    setListQuestions(newState);
    listQuestionsRef.current = newState;
  };

  const onEndSort = () => {
    let newOrder = 0;
    const itemChange = listQuestionsRef.current?.find((item, idx) => {
      const findItem = item.order > listQuestionsRef.current?.[idx + 1]?.order;
      if (findItem) {
        newOrder =
          ((listQuestionsRef.current?.[idx - 1]?.order ?? 0) +
            (listQuestionsRef.current?.[idx + 1]?.order ?? listQuestionsRef.current?.[idx - 1]?.order + 1)) /
          2;
      }
      return findItem;
    });
    const newValue = listQuestionsRef.current?.map((item) => {
      if (item._id === itemChange?._id) {
        return {
          ...item,
          id: item._id,
          order: newOrder,
        };
      }
      return { ...item, id: item._id };
    });
    setListQuestions(newValue);
    if (itemChange) {
      dispatch(
        updateOrder({
          ...itemChange,
          id: itemChange._id,
          name: itemChange.name,
          description: itemChange.description ?? "",
          type: itemChange.type,
          parentId: itemChange.parentId,
          aspectId: itemChange.aspectId,
          order: newOrder,
        })
      );
    }
  };

  const onClickQuestion = (question: IItems) => {
    navigate(`${ROUTES.QUESTION_IDEA.replace(":id", question._id)}`);
  };

  useEffect(() => {
    setListQuestions(listData?.map((item) => ({ ...item, id: item._id })) ?? []);
  }, [listData]);

  if (isPageChallenge) {
    return (
      <>
        {listQuestions?.map((question) => (
          <Box
            key={question._id}
            sx={{
              ...styles.styleItem,
              backgroundColor: isPageChallenge && selectedQuestionId === question._id ? "lightblue" : "white",
              "& .title": {
                display: "flex",
                "& .count": {
                  marginLeft: "5px",
                },
              },
            }}
            onClick={() => {
              if (!isPageChallenge) {
                onClickQuestion(question);
              } else {
                setSelectedQuestionId(question._id);
                if (onHandleClickQuestion !== undefined) {
                  onHandleClickQuestion(question._id);
                }
              }
            }}
          >
            <Box
              className="title"
              dangerouslySetInnerHTML={{
                __html:
                  question.name +
                    `${question.childrenCount > 0 ? `<b class="count">(${question.childrenCount})</b>` : ""}` || "",
              }}
            />
            <Box
              className="description"
              dangerouslySetInnerHTML={{
                __html: question.description || "",
              }}
            />

            {!isPageChallenge && (
              <MenuAction
                handleClick={(typeAction: EAction) => {
                  handleAction({
                    typeAction,
                    type: ETypeItem.question,
                    data: question,
                  });
                }}
              />
            )}
          </Box>
        ))}
      </>
    );
  }
  // if (isPageChallenge) {
  //   return <ListItem />;
  // }
  return (
    <ReactSortable
      list={listQuestions}
      setList={(newState) => handleSortItem(newState)}
      animation={200}
      delayOnTouchStart={true}
      delay={2}
      onEnd={onEndSort}
    >
      {listQuestions?.map((question) => (
        <Box
          key={question._id}
          alignItems={"center"}
          gap={0.5}
          sx={{
            ...styles.styleItem,
            backgroundColor: isPageChallenge && selectedQuestionId === question._id ? "lightblue" : "white",
            "& .title": {
              display: "flex",
              "& .count": {
                marginLeft: "5px",
              },
            },
          }}
          onClick={() => {
            if (!isPageChallenge) {
              onClickQuestion(question);
            } else {
              setSelectedQuestionId(question._id);
              if (onHandleClickQuestion !== undefined) {
                onHandleClickQuestion(question._id);
              }
            }
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
            display={"flex"}
            flexDirection={"column"}
            maxWidth={"85%"}
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: question.name,
              }}
            />
            <Box
              dangerouslySetInnerHTML={{
                __html: question.description || "",
              }}
            />
          </Box>

          {!isPageChallenge && (
            <MenuAction
              handleClick={(typeAction: EAction) => {
                handleAction({
                  typeAction,
                  type: ETypeItem.question,
                  data: question,
                });
              }}
            />
          )}

          <Box flex={1} />
          {question.public && <Chip label={"Public"} size="small" color="success" />}
          {question.childrenCount > 0 && (
            <Chip sx={{ width: "50px" }} label={question.childrenCount} size="small" color="primary" />
          )}
          <Box
            sx={{
              maxWidth: "40px",
              marginRight: "40px",
              "& img": {
                maxWidth: "100%",
              },
            }}
          >
            {question.imageUrl && <img src={getUrlServer(question.imageUrl)} />}
          </Box>
          <Box width={30} />
        </Box>
      ))}
    </ReactSortable>
  );
}

export default SortQuestions;
