import { all, fork } from 'redux-saga/effects';
import { adminActions } from './admin';
import { itemsActions } from './items';
import { challengeActions } from './challenge';

export function* AllSagaActions() {
	yield all([
		fork(adminActions),
		fork(itemsActions),
		fork(challengeActions),
	]);
}
