import { Avatar, Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { getUrlServer } from "@/helpers";

interface IUploadFileProps {
  setValue: any;
  name: string;
  acceptFiles?: string;
  valueDefault?: string;
}

const UploadFile = ({ name, acceptFiles = "image/*", setValue, valueDefault }: IUploadFileProps) => {
  const hiddenInputRef = useRef<any>();

  const [preview, setPreview] = useState<string | undefined>(undefined);

  const handleUploadedFile = (event: any) => {
    const file = event.target.files[0];
    setValue(name, file);

    const urlImage = URL.createObjectURL(file);
    setPreview(urlImage);
  };

  const onUpload = () => {
    hiddenInputRef.current?.click();
  };

  const uploadButtonLabel = preview ? "Change" : "Upload";

  useEffect(() => {
    if (valueDefault) {
      setPreview(getUrlServer(valueDefault));
    }
  }, [valueDefault]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {preview ? (
        <CancelRoundedIcon
          sx={{
            position: "absolute",
            right: -10,
            top: -2,
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={() => {
            setValue(name, null);
            setPreview(undefined);
          }}
        />
      ) : null}
      <input
        type="file"
        name={name}
        onChange={(e) => handleUploadedFile(e)}
        ref={(e) => {
          hiddenInputRef.current = e;
        }}
        accept={acceptFiles}
        style={{
          display: "none",
        }}
      />

      <Avatar src={preview} sx={{ width: 80, height: 80 }} />

      <Button
        variant="text"
        onClick={onUpload}
        sx={{
          fontSize: "12px",
          textTransform: "none",
          marginTop: "4px",
        }}
      >
        {uploadButtonLabel}
      </Button>
    </Box>
  );
};

export { UploadFile };
