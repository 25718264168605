import { ETypeItem, IItems } from "@/common";
import SearchInput from "@/components/Search";
import { useGetListItems, useUpdateItem } from "@/services-react-query/queries";
import { Box, Button, LinearProgress, Dialog, IconButton, colors, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ILinkingQuestionModalProps {
  open: boolean;
  handleClose: () => void;
  questionTypeId: string;
  refetchQuestion: () => void;
}

function LinkingQuestionModal({ open, handleClose, questionTypeId, refetchQuestion }: ILinkingQuestionModalProps) {
  const [valueSearch, setValueSearch] = useState("");
  const {
    data: listQuestion,
    isLoading,
    refetch,
  } = useGetListItems({
    query: {
      type: ETypeItem.question,
      limit: 20,
      skip: 0,
      sortBy: "createdAt",
      parentId: "*",
      searchQuery: valueSearch,
      questionTypeId: "empty",
    },
    options: {},
  });

  const { mutate: updateItem, isLoading: isLoadingUpdate } = useUpdateItem({
    onSuccess: () => {
      refetchQuestion();
      refetch();
    },
  });

  const handleLinking = (item: IItems) => {
    updateItem({
      ...item,
      description: item.description || "",
      id: item._id,
      questionTypeId,
    });
  };

  useEffect(() => {
    if (open) {
      setValueSearch("");
      setTimeout(() => {
        refetch();
      }, 100);
    }
  }, [open, refetch]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "50vw",
          minHeight: "50vh",
          padding: "16px",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          "& .icon-close": {
            // position: "absolute",
            // right: "5px",
            // top: "-5px",
          },
          "& .search": {
            "& input": {
              width: "100%",
            },
          },
        }}
      >
        {(isLoading || isLoadingUpdate) && <LinearProgress />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            List question, please select the question you want to link
          </Typography>
          <IconButton className="icon-close" onClick={handleClose}>
            <CloseIcon color="success" />
          </IconButton>
        </Box>

        <SearchInput
          handleSearch={(value) => {
            setValueSearch(value);
          }}
          valueSearch={valueSearch}
        />
        <Box
          sx={{
            display: "flex",
            position: "relative",
            padding: "5px",
            minHeight: "50px",
            marginTop: "16px",
            flexDirection: "column",
            gap: "8px",
            maxHeight: "60vh",
            "& .title": {
              paddingRight: "30px",
            },
            "& .description": {
              paddingRight: "30px",
              color: colors.grey[600],
            },
          }}
        >
          {listQuestion?.length ? (
            listQuestion?.map((item: IItems) => {
              return (
                <Box
                  key={item._id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "50px",
                    "&:hover": {
                      backgroundColor: "#ECEAEA",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: item.name || "",
                      }}
                      className="title"
                    />
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: item?.description || "",
                      }}
                      className="description"
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => handleLinking(item)}
                      sx={{ textTransform: "unset" }}
                      color="success"
                    >
                      Linking
                    </Button>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                No data
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default LinkingQuestionModal;
