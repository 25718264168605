import WordPhonetic from "@/pages/LearnPage/components/WordSearch/WordPhonetic";
import { Box, Card, Typography } from "@mui/material";

const FlashCardItemFront = (props) => {
    const {item}  = props;
    return (
        <Card
            sx={{
                borderRadius: '10px',
                cursor: 'pointer'
            }}
        >
            <Box sx={{
                height: '400px',
                width: '320px',
                display: 'flex',
                flexDirection : 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography sx={{
                    fontWeight: 600,
                    fontSize: '24px',
                }}>
                    {item?.word?.word ?? ""}
                </Typography>
                <WordPhonetic word={item?.word}/>
            </Box>
        </Card>
    )
}

export default FlashCardItemFront;