import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails, Box, Button, CircularProgress, IconButton } from "@mui/material";
import ChevronUpIcon from "../../../../../assets/icons/ChevronUpIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { ENDPOINTS, getApiUrl, IItems, Requests, showMessage } from "../../../../../store-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { extractContent, genCategoryAspect, refinePrompt } from "@/helpers";

interface IListSuggestProps {
  handleClickGuidedQuestion?: (guidedQuestion: IItems) => void;
  dataOfAnswer?: IItems[];
  guidedQuestions?: any[];
  answersByAspect?: any;
  question?: IItems;
  name?: string;
  onGenerateAnswer: (content: string) => void;
}

export default function ListAnswer({
  guidedQuestions,
  answersByAspect,
  question,
  name,
  onGenerateAnswer,
  handleClickGuidedQuestion
}: IListSuggestProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isPageAnswer = pathname.indexOf("/answer") !== -1;
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.SUGGEST_ANSWER_PART_TWO);
    try {
      const dataRes = await Requests.post<string>(apiUrl, {
        question: refinePrompt(extractContent(question?.name ?? "")),
        answers: guidedQuestions?.map((item) => ({
          aspect: refinePrompt(extractContent(item.aspect?.name ?? "")),
          answer: answersByAspect[item.aspect?._id],
        })),
        name: name,
      });
      onGenerateAnswer(dataRes);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Accordion
      sx={{
        borderRadius: "4px",
      }}
    >
      <AccordionSummary
        expandIcon={pathname.indexOf("/answer") !== -1 ? <ChevronUpIcon /> : <ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          borderRadius: "8px",
          maxHeight: "48px !important",
          minHeight: "48px",
          height: "48px",
          boxShadow: "-1px -9px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "15px",
            marginRight: "20px",
          },
        }}
      >
        My Guided Question Path ({guidedQuestions?.length})
        {!isPageAnswer ? (
          <Button
            onClick={(event) => handleClick(event)}
            sx={(theme) => ({
              textTransform: "none",
              backgroundColor: "#264653",
              height: "auto",
              borderRadius: "8px",
              fontFamily: "Poppins",
              fontSize: "15px",
              padding: "4px 8px",
              fontWeight: "700",
              color: "white",
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            })}
          >
            {isLoading && <CircularProgress size={20} />}
            Generate answer
          </Button>
        ) : null}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          maxHeight: "73vh",
          [theme.breakpoints.up("sm")]: {
            maxHeight: "62vh",
          },
          overflow: "auto",
        })}
      >
        {guidedQuestions?.map((idea: IItems) => {
          const nameLocationIdea = genCategoryAspect(idea.category, idea.aspect);
          return (
            <Box
              key={idea._id}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                border: "1px solid #EDEDED",
                borderRadius: "8px",
                padding: "16px 2px 16px 24px",
                "& .box-icon": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                "& .nameLocationIdea": {
                  margin: " auto 6px",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  fontWeight: "700",
                  border: "1px solid transparent",
                  borderRadius: "50%",
                  backgroundColor: "pink",
                  padding: " 0 10px",
                },
              }}
            >
              <Box
                key={idea._id}
                onClick={() => {
                  handleClickGuidedQuestion(idea);
                  // dispatch(showMessage("Comming soon!", "info"));
                }}
                sx={() => ({
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  position: "relative",
                  padding: "5px",
                  minHeight: "50px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#ECEAEA",
                    cursor: "pointer",
                  },
                  //   ...(editCustomAnswerContent?.data?.indexOf(
                  //     extractContent(idea.description ?? "").replace(/^.*"(.*?)"[^"]*$/, "$1") ?? ""
                  //   ) !== -1
                  //     ? {
                  //         backgroundColor: "rgba(79, 70, 229, 0.7)",
                  //         color: "white",
                  //         "&:hover": {
                  //           backgroundColor: "rgba(79, 70, 229, 0.7) !important",
                  //         },
                  //       }
                  //     : {}),
                })}
              >
                <Box className="title" dangerouslySetInnerHTML={{ __html: idea.name || "" }} />
                <Box
                  sx={{
                    fontWeight: "bold",
                    fontSize: "italic",
                  }}
                >
                  {answersByAspect[idea.aspect?._id]}
                </Box>
              </Box>
              <Box
                className="nameLocationIdea"
                dangerouslySetInnerHTML={{ __html: nameLocationIdea == "undefined" ? " " : nameLocationIdea || " " }}
              />
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
