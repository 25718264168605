import NoDataComponent from "@/components/NoDataComponent";
import { ENDPOINTS, ETypeItem, getApiUrl, getListTopics, IApplicationState, IItems, Requests } from "@/store-redux";
import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListQuestionByTopic from "./ListQuestionByTopic";
import GrammarTopic from "./GrammarTopic";
import { INIT_GRAMMAR_TOPICS } from "@/constants";
import { EBackOfficeCode } from "@/common/types/backoffice";

interface IGrammarCMSPageProps {}

const GrammarCMSPage: React.FC<IGrammarCMSPageProps> = (props) => {
  const dispatch = useDispatch();
  const listTopics = useSelector((state: IApplicationState) => state.listTopics);
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);
  const [grammarTopics, setGrammarTopics] = useState<any[]>([]);

  const getBackofficeGrammarTopic = async () => {
    try {
      const dataRes = await Requests.get(
        getApiUrl(ENDPOINTS.BACKOFFICE_GET_BY_CODE.replace(":code", EBackOfficeCode.GRAMMAR_TOPIC_CMS))
      );
      if (Array.isArray(dataRes?.data)) {
        const data = dataRes?.data;
        setGrammarTopics(data);
      } else {
        setGrammarTopics(INIT_GRAMMAR_TOPICS);
      }
    } catch (e) {
      setGrammarTopics(INIT_GRAMMAR_TOPICS);
    }
  };

  useEffect(() => {
    getBackofficeGrammarTopic();
  }, []);

  const handleGetListTopics = () => {
    dispatch(
      getListTopics({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.topic,
      })
    );
  };

  useEffect(() => {
    handleGetListTopics();
  }, []);

  const handleTopicClick = (topic: IItems) => {
    setSelectedTopicId(topic._id);
  };

  return (
    <Box>
      <GrammarTopic grammarTopics={grammarTopics} onChangeGrammarTopics={(items) => setGrammarTopics(items)} />
      <Box
        sx={{
          display: "flex",
        }}
      >
        {listTopics.isFetching ? <LinearProgress color="secondary" style={{ marginRight: 10 }} /> : null}
        <Box
          sx={
            selectedTopicId
              ? {
                  maxWidth: "150px",
                  borderRight: "1px solid #eceaea",
                  marginRight: "5px",
                  maxHeight: "calc(100vh - 150px)",
                  overflowY: "auto",
                }
              : { maxHeight: "calc(100vh - 150px)", overflowY: "auto" }
          }
        >
          {listTopics.data?.map((topic) => (
            <Box
              sx={{
                cursor: "pointer",
                padding: "10px",
                ...(selectedTopicId === topic._id
                  ? {
                      backgroundColor: "lightblue",
                    }
                  : {}),
                "&:hover": {
                  backgroundColor: "#eceaea",
                },
              }}
              key={topic._id}
              onClick={() => {
                handleTopicClick(topic);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    topic.name + `${topic.childrenCount > 0 ? `<b class="count">(${topic.childrenCount})</b>` : ""}` ||
                    "",
                }}
              />
              <Box
                sx={{
                  flex: 1,
                }}
                dangerouslySetInnerHTML={{
                  __html: topic.description || "",
                }}
              />
            </Box>
          ))}
          {listTopics.data?.length === 0 && !listTopics.isFetching ? <NoDataComponent /> : null}
        </Box>
        {selectedTopicId && (
          <>
            <ListQuestionByTopic topicId={selectedTopicId} grammarTopics={grammarTopics} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default GrammarCMSPage;
