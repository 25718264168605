import NoDataComponent from "@/components/NoDataComponent";
import { ROUTES } from "@/constants";
import { extractContent, removeLeadingNumbersAndPeriods } from "@/helpers";
import { useGetDetailItem } from "@/services-react-query/queries";
import { ETypeItem, getListQuestionType, IApplicationState } from "@/store-redux";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LayoutLearn from "../LayoutLearn";

const QuestionTypeByCatPage = (props) => {
  const { questionCategoryId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listQuestionType = useSelector((state: IApplicationState) => state.listQuestionType);
  const { data: detailItem } = useGetDetailItem({
    id: questionCategoryId ?? "",
    enabled: !!questionCategoryId,
  });
  const handleGetListQuestionType = () => {
    dispatch(
      getListQuestionType({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem["question.type"],
        parentId: questionCategoryId,
      })
    );
  };
  useEffect(() => {
    if (listQuestionType.data?.length && listQuestionType.data?.[0]?.parentId === questionCategoryId) return;
    if (questionCategoryId) {
      handleGetListQuestionType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionTypeClick = (id: string) => {
    navigate(ROUTES.LEARN_QUESTION_TYPE_LIST_QUESTION.replace(":questionTypeId", id));
  };
  return (
    <LayoutLearn>
      <Box
        sx={{
          width: "100%",
          "& h3": {
            fontSize: "22px",
            textAlign: "left",
            fontWeight: "bold",
          },
          "& .box-container": {
            display: "grid",
            overflowY: "auto",
            marginTop: "10px",
            paddingInline: "5px",
            width: "100%",
            gap: "10px",
          },
          " .box-child": {
            border: "1px solid #000",
            " .head": {
              padding: "8px",
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "1px solid #000",
            },
          },
          "& .box-item": {
            padding: "16px 32px",

            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#ECEAEA",
              cursor: "pointer",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& button": {
              marginLeft: "16px",
              height: "40px",
            },
          }}
        >
          <Button onClick={() => navigate(-1)} variant="contained" color="secondary">
            Back
          </Button>
          <Typography
            component={"h4"}
            sx={{
              fontSize: "20px",
              textAlign: "left",
              marginLeft: "16px",
            }}
          >
            {detailItem ? <b> {extractContent(detailItem?.name)}</b> : ""}
          </Typography>
          <Box sx={{ width: "70px", marginRight: "16px" }} />
        </Box>
        <Box className="box-container">
          {listQuestionType.data?.map((questionType) => (
            <Box key={questionType._id} onClick={() => handleQuestionTypeClick(questionType._id)} className="box-item">
              <Box className="title">{removeLeadingNumbersAndPeriods(extractContent(questionType.name))}</Box>
            </Box>
          ))}
          {listQuestionType.data?.length === 0 && !listQuestionType.isFetching ? <NoDataComponent /> : null}
        </Box>
      </Box>
    </LayoutLearn>
  );
};

export default QuestionTypeByCatPage;
