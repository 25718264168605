import { EAction } from "@/common";
import { MenuAction } from "@/components/MenuAction";
import { ROUTES } from "@/constants";
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import BookIcon from '@mui/icons-material/Book';
const WordbookCard = (props) => {
    let navigate = useNavigate();
    const {item, handleAction} = props;
    const onGoToWordbookPage = () => {
        navigate(ROUTES.WORDBOOK.replace(':wordbookId', item._id))
    }
    return (
        <Card sx={{
            cursor: 'pointer'
        }} onClick={onGoToWordbookPage}>
            <CardContent sx={{
                padding: '10px!important'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                   <Box sx={{
                        display: 'flex',
                        // alignItems: 'center',
                        flex: 1,
                        '& svg': {
                            
                            '& path': {
                                fill: '#264653'
                            }
                        }
                        
                    }}>
                        <BookIcon fontSize="large"/>
                        <Box>
                            <Typography sx={{
                                fontWeight: 600
                            }}>{item.name}</Typography>
                            <Typography component={'span'} sx={{
                                fontWeight: 300,
                                fontSize: 12,
                            }}>{item.vocabCount} words</Typography>
                        </Box>
                    </Box>
                    <MenuAction
                        handleClick={(typeAction: EAction) => {
                            // console.log('typeAction',typeAction)
                            handleAction({typeAction})
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}

export default WordbookCard;