import {
  ICreateItem,
  IGenerateIdeas,
  IGetList,
  IItemUpdate,
  IItems,
  ISaveCustomAnswer,
  IRemoveMultipleItems,
  IIdeaCombineCategoryAspect,
  IGenerateGrammarStructure,
} from "../interface";
import { AllTypeActions } from "../types";

export const getListTopics = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_TOPICS,
    input,
  };
};

export const createTopics = (input: ICreateItem): any => {
  return {
    type: AllTypeActions.CREATE_TOPICS,
    input,
  };
};

export const createQuestions = (input: ICreateItem): any => {
  return {
    type: AllTypeActions.CREATE_QUESTIONS,
    input,
  };
};

export const updateTopic = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_TOPIC,
    input,
  };
};

export const updateQuestion = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_QUESTION,
    input,
  };
};

export const getListQuestions = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_QUESTIONS,
    input,
  };
};

export const deleteTopic = (id: string): any => {
  return {
    type: AllTypeActions.DELETE_TOPIC,
    id,
  };
};

export const deleteQuestion = (id: string): any => {
  return {
    type: AllTypeActions.DELETE_QUESTION,
    id,
  };
};

export const getDetailItem = (id: string): any => {
  return {
    type: AllTypeActions.GET_DETAIL_ITEM,
    id,
  };
};

export const getListIdeas = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_IDEAS,
    input,
  };
};

export const getListAllGrammarIdeas = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_ALL_GRAMMAR_OF_IDEAS,
    input,
  };
};

export const getListAllStructureIdeas = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_ALL_STRUCTURE_OF_IDEAS,
    input,
  };
};

export const createItems = (input: ICreateItem): any => {
  return {
    type: AllTypeActions.CREATE_ITEMS,
    input,
  };
};

export const deleteItem = (id: string): any => {
  return {
    type: AllTypeActions.DELETE_ITEM,
    id,
  };
};

export const updateItem = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_ITEM,
    input,
  };
};

export const getListCategories = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_CATEGORIES,
    input,
  };
};

export const getListAllAspects = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_ALL_ASPECTS,
    input,
  };
};

export const getListAspects = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_ASPECTS,
    input,
  };
};

export const addIntoCart = (data: IItems): any => {
  return {
    type: AllTypeActions.ADD_INTO_CART,
    data,
  };
};

export const removeIntoCart = (data: IItems): any => {
  return {
    type: AllTypeActions.REMOVE_INTO_CART,
    data,
  };
};

export const resetCard = (): any => {
  return {
    type: AllTypeActions.ADD_INTO_CART_SUCCESS,
  };
};

export const editCustomAnswer = (data: string): any => {
  return {
    type: AllTypeActions.EDIT_CUSTOM_ANSWER,
    data,
  };
};

export const saveCustomAnswer = (input: ISaveCustomAnswer): any => {
  return {
    type: AllTypeActions.SAVE_CUSTOM_ANSWER,
    input,
  };
};

export const saveEditCustomAnswer = (input: ISaveCustomAnswer): any => {
  return {
    type: AllTypeActions.SAVE_EDIT_CUSTOM_ANSWER,
    input,
  };
};

export const generateIdeas = (input: IGenerateIdeas): any => {
  return {
    type: AllTypeActions.GENERATE_IDEAS,
    input,
  };
};

export const generateGrammars = (input: IGenerateGrammarStructure): any => {
  return {
    type: AllTypeActions.GENERATE_GRAMMARS,
    input,
  };
};

export const generateStructures = (input: IGenerateGrammarStructure): any => {
  return {
    type: AllTypeActions.GENERATE_STRUCTURES,
    input,
  };
};

export const getListAnswerOfUser = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_ANSWER_OF_USER,
    input,
  };
};

export const removeAnswerItem = (id: string): any => {
  return {
    type: AllTypeActions.REMOVE_ANSWER_ITEM,
    id,
  };
};

export const removeMultipleItems = (input: IRemoveMultipleItems): any => {
  return {
    type: AllTypeActions.REMOVE_MULTIPLE_ITEMS,
    input,
  };
};

export const createLevel = (input: ICreateItem): any => {
  return {
    type: AllTypeActions.CREATE_LEVEL,
    input,
  };
};

export const updateLevel = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_LEVEL,
    input,
  };
};

export const getListLevel = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_LEVEL,
    input,
  };
};

export const updateOrder = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_ORDER,
    input,
  };
};

export const updateIdeaCombineCategoryAspect = (data: IIdeaCombineCategoryAspect[]): any => {
  return {
    type: AllTypeActions.IDEA_COMBINE_CATEGORY_ASPECT,
    data,
  };
};

export const getWordSearch = (word: string): any => {
  return {
    type: AllTypeActions.GET_WORD_SEARCH,
    word,
  };
};

export const createQuestionCategory = (input: ICreateItem): any => {
  return {
    type: AllTypeActions.CREATE_QUESTION_CATEGORY,
    input,
  };
};

export const createQuestionType = (input: ICreateItem): any => {
  return {
    type: AllTypeActions.CREATE_QUESTION_TYPE,
    input,
  };
};

export const updateQuestionCategory = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_QUESTION_CATEGORY,
    input,
  };
};

export const updateQuestionType = (input: IItemUpdate): any => {
  return {
    type: AllTypeActions.UPDATE_QUESTION_TYPE,
    input,
  };
};

export const deleteQuestionCategory = (id: string): any => {
  return {
    type: AllTypeActions.DELETE_QUESTION_CATEGORY,
    id,
  };
};

export const deleteQuestionType = (id: string): any => {
  return {
    type: AllTypeActions.DELETE_QUESTION_TYPE,
    id,
  };
};

export const getListQuestionCategory = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_QUESTION_CATEGORY,
    input,
  };
};

export const getListQuestionType = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_QUESTION_TYPE,
    input,
  };
};

export const getListGuidedQuestions = (input: IGetList): any => {
  return {
    type: AllTypeActions.GET_LIST_GUIDED_QUESTIONS,
    input,
  };
};
