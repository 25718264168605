import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box } from "@mui/material";
import { EAction } from "../../store-redux";

const options: { icon: JSX.Element; title: EAction }[] = [
  {
    icon: <ModeEditIcon color="secondary" />,
    title: EAction.edit,
  },
  {
    icon: <DeleteIcon color="error" />,
    title: EAction.delete,
  },
];

interface IMenuActionProps {
  handleClick: (type: EAction) => void;
  hide?: EAction[]
}

export function MenuAction({ handleClick , hide = [] }: IMenuActionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    event?.stopPropagation();
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event?.stopPropagation();
  };
  return (
    <Box className="menu-icon">
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& li": {
            textTransform: "capitalize",
            fontSize: 14,
            "& svg": {
              marginRight: "5px",
              fontSize: 20,
            },
          },
        }}
      >
        {options.filter(item => !hide.includes(item.title)).map((option) => (
          <MenuItem
            key={option.title}
            onClick={(e: any) => {
              handleClose(e);
              handleClick(option.title);
            }}
          >
            {option.icon} {option.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
