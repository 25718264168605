import { Box, IconButton, Modal } from "@mui/material"
import WordbankPage from "..";
import CloseIcon from "@mui/icons-material/Close";

const WordbankModal = (props) => {
    const {onClose} = props;
    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                padding: "0 16px",           
            }}
            onClose={() => onClose()}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    minWidth: {
                        xs: "calc(100vw - 40px)",
                    },
                    maxWidth: {
                        xs: "calc(100vw - 40px)",
                    },
                    width: "calc(100vw - 40px)",
                    height: "calc(100vh - 40px)",
                    "& form": {
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    },
                }}
            >
                <IconButton sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px'
                }} onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
                <WordbankPage />
            </Box>
        </Modal>
    )
}

export default WordbankModal;