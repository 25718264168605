import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Input, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { jsonToFile } from "@/helpers";
import { useDispatch } from "react-redux";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { EBackOfficeCode } from "@/common/types/backoffice";
import { showMessage } from "@/store-redux";

interface GrammarTopicProps {
  grammarTopics: string[];
  onChangeGrammarTopics: (topics: string[]) => void;
}

const DragHandle = SortableHandle(() => <DragHandleIcon style={{ cursor: "grab", marginRight: 10 }} />);

const SortableItem = SortableElement(
  ({ value, index, onDelete }: { value: string; index: number; onDelete: (index: number) => void }) => (
    <ListItem
      sx={{
        borderBottom: "1px solid #ccc",
        height: "50px",
      }}
    >
      <DragHandle />
      <ListItemText primary={value} />
    </ListItem>
  )
);

const SortableList = SortableContainer(
  ({ items, onDelete }: { items: string[]; onDelete: (index: number) => void }) => {
    return (
      <List>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} onDelete={onDelete} />
        ))}
      </List>
    );
  }
);

const GrammarTopic: React.FC<GrammarTopicProps> = ({ grammarTopics, onChangeGrammarTopics }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [items, setItems] = useState<string[]>([]);
  const [openAddTopic, setOpenAddTopic] = useState(false);
  const [inputAddTopic, setInputAddTopic] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!openModal) {
      setIsEdit(false);
      setItems([]);
    } else {
      setItems([...grammarTopics]);
    }
  }, [openModal, grammarTopics]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  const handleDelete = (index: number) => {
    setItems((prev) => prev.filter((_, indexP) => indexP !== index));
  };

  const onSave = async () => {
    const file = jsonToFile(items);
    setIsEdit(false);
    setOpenModal(false);
    const urlUploadFile = getApiUrl(
      ENDPOINTS.BACKOFFICE_SAVE_BY_CODE.replace(":code", EBackOfficeCode.GRAMMAR_TOPIC_CMS)
    );

    try {
      await Requests.post(
        urlUploadFile,
        {
          file: file,
        },
        true,
        false
      );
      dispatch(showMessage("Update structure data success", "success"));
      onChangeGrammarTopics([...items]);
    } catch (e) {
      dispatch(showMessage("Update structure data error", "error"));
    }
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "unset",
          marginBottom: "10px",
        }}
        variant="contained"
        color="secondary"
        onClick={() => setOpenModal(true)}
      >
        Grammar Topic
      </Button>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "10px",
            borderRadius: "8px",
            maxHeight: "calc(100vh - 40px)",
            maxWidth: "calc(100vw - 40px)",
            width: "600px",
            height: "800px",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                flex: 1,
              }}
            >
              List grammar topics
            </Typography>

            <Box
              sx={{
                paddingBottom: "5px",
              }}
            >
              {isEdit ? (
                <>
                  <Button
                    sx={{
                      textTransform: "unset",
                      marginRight: "10px",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpenAddTopic(true);
                    }}
                  >
                    Add topic
                  </Button>
                  <Button
                    sx={{
                      textTransform: "unset",
                      marginRight: "10px",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    sx={{
                      textTransform: "unset",
                      backgroundColor: "#f1f1f1",
                      color: "#000",
                      marginRight: "10px",
                      "&:hover": {
                        backgroundColor: "#f1f1f1",
                      },
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setIsEdit(false);
                      setItems([...grammarTopics]);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  sx={{
                    textTransform: "unset",
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 40px)",
              overflowY: "auto",
            }}
          >
            {isEdit ? (
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <SortableList items={items} onSortEnd={onSortEnd} onDelete={handleDelete} />
                </Box>
                <Box
                  sx={{
                    padding: "8px 0",
                  }}
                >
                  {items.map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <>
                {grammarTopics.map((topic, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: "10px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <Typography>{topic}</Typography>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openAddTopic}
        onClose={() => {
          setOpenAddTopic(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "10px",
            borderRadius: "8px",
            maxHeight: "calc(100vh - 40px)",
            maxWidth: "calc(100vw - 40px)",
            width: "400px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            >
              Add topic
            </Typography>
            <Input
              sx={{
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                margin: "10px 0",
                "&::after": {
                  display: "none",
                },
                "&::before": {
                  display: "none",
                },
              }}
              value={inputAddTopic}
              onChange={(event) => setInputAddTopic(event.target.value)}
            />
            <Button
              disabled={inputAddTopic.trim() === ""}
              sx={{
                textTransform: "unset",
                marginRight: "10px",
              }}
              variant="contained"
              color="secondary"
              onClick={() => {
                setItems((prev) => [...prev, inputAddTopic]);
                setOpenAddTopic(false);
                setInputAddTopic("");
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GrammarTopic;
