import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAnswer from "./components/CustomAnswer";
import CustomAnswerWordBold from "./components/CustomAnswerWordBold";
import CustomAnswerHighlight from "./components/CustomHighlight";
import LayoutAnswer from "./components/LayoutAnswer";
import { useLocation, useParams } from "react-router-dom";
import { editCustomAnswer, getDetailItem, IApplicationState, IItems } from "@/store-redux";
import { useDispatch, useSelector } from "react-redux";

export enum ECurrentStep {
  ANSWER = "ANSWER",
  ANSWER_WORD_BOLD = "ANSWER_WORD_BOLD",
  ANSWER_HIGHLIGHT = "ANSWER_HIGHLIGHT",
}
interface AnswerPageProps {
  isPartTwo?: boolean;
  question?: IItems;
  guidedQuestions?: any[];
  answersByAspect?: any;
  name?: string;
}
const AnswerPage: React.FC<AnswerPageProps> = ({ isPartTwo, question, guidedQuestions, answersByAspect, name }) => {
  const location = useLocation();
  const { categoryId, questionId } = useParams();
  const dispatch = useDispatch();
  const isFromProfile = categoryId === ":categoryId";
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);

  const infoSaveCustomAnswer = useSelector((state: IApplicationState) => state.saveCustomAnswer);
  const [currentStep, setCurrentStep] = useState<ECurrentStep>(ECurrentStep.ANSWER);
  const [boldIndexes, setBoldIndexes] = useState<number[]>([]);

  useEffect(() => {
    if (isFromProfile || infoSaveCustomAnswer?.data) {
      setBoldIndexes(location?.state?.boldIndex ?? infoSaveCustomAnswer?.data?.boldIndex);
    }
  }, [isFromProfile, infoSaveCustomAnswer?.data]);

  useEffect(() => {
    return () => {
      dispatch(editCustomAnswer(""));
    };
  }, []);

  const renderContent = (): JSX.Element => {
    switch (currentStep) {
      case ECurrentStep.ANSWER:
        return (
          <CustomAnswer
            question={question ?? detailQuestion?.data ?? undefined}
            isPartTwo={isPartTwo}
            guidedQuestions={guidedQuestions}
            answersByAspect={answersByAspect}
            name={name}
          />
        );
      case ECurrentStep.ANSWER_WORD_BOLD:
        return (
          <CustomAnswerWordBold
            nextStep={() => {
              setCurrentStep(ECurrentStep.ANSWER_HIGHLIGHT);
            }}
            boldIndexes={boldIndexes}
            setBoldIndexes={setBoldIndexes}
          />
        );
      case ECurrentStep.ANSWER_HIGHLIGHT:
        return <CustomAnswerHighlight boldIndexes={boldIndexes} />;
      default:
        break;
    }
    return <CustomAnswer />;
  };

  return (
    <LayoutAnswer
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      boldIndexes={boldIndexes}
      question={question}
      guidedQuestions={guidedQuestions}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        {renderContent()}
      </Box>
    </LayoutAnswer>
  );
};

export default AnswerPage;
