import { ENDPOINTS, getApiUrl, IItems, Requests } from "@/store-redux";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChatQuidedQuestion from "./components/ChatQuidedQuestion";

interface Question {
  _id: string;
  name: string;
  aspectInfo?: IItems;
  aspects?: any;
}

interface GuidedQuestionPageProps {}

const GuidedQuestionPage: React.FC<GuidedQuestionPageProps> = () => {
  const navigate = useNavigate();
  const { questionId } = useParams<{ questionId: string }>();
  const [question, setQuestion] = useState<Question | null>(null);

  const [items, setItems] = useState<Question[]>([]);
  const [aspectSelected, setAspectSelected] = useState<string[]>([]);
  const [showChatbox, setShowChatbox] = useState<boolean>(false);

  useEffect(() => {
    getGuidedQuestions();
    getQuestionInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestionInfo = async () => {
    const dataRes = await Requests.get(getApiUrl(ENDPOINTS.ONE_ITEM.replace(":id", questionId ?? "")));
    if (dataRes.data) setQuestion(dataRes.data);
  };

  const getGuidedQuestions = async () => {
    const dataRes = await Requests.get(
      getApiUrl(ENDPOINTS.GET_QUIDED_QUESTIONS.replace(":questionId", questionId ?? ""))
    );
    if (Array.isArray(dataRes)) {
      const tempValue = dataRes?.filter((item) => {
        const existGuideQuestion = item?.aspects?.find((item: any) => item?.guidedQuestion);
        return existGuideQuestion ? true : false;
      });
      setItems(tempValue);
    }
  };

  const onNext = () => {
    setShowChatbox(true);
  };
  const allGuidedQuestions = useMemo(() => {
    return items.reduce((acc, item) => {
      const category = { ...item };
      delete category?.aspects;
      return [
        ...acc,
        ...item?.aspects?.reduce((accA, itemA) => {
          const aspect = { ...itemA };
          delete aspect.guidedQuestion;
          return [
            ...accA,
            {
              ...itemA.guidedQuestion,
              aspect,
              category,
            },
          ];
        }, []),
      ];
    }, []);
  }, [items]);
  const guidedQuestions = useMemo(() => {
    return allGuidedQuestions.filter((item) => aspectSelected.includes(item?.aspect?._id));
  }, [aspectSelected]);

  if (showChatbox) {
    return <ChatQuidedQuestion question={question} guidedQuestions={guidedQuestions} aspectSelected={aspectSelected} onBack={() => setShowChatbox(false)} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          paddingRight: "32px",
          paddingLeft: "16px",
          marginTop: "16px",
          "& button": {
            textTransform: "none",
            height: "40px",
          },
        }}
      >
        <Button onClick={() => navigate(-1)} variant="contained" color="secondary">
          Back
        </Button>
      </Box>
      <Box>
        <Box
          sx={{
            fontSize: "24px",
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: question?.name ?? "" }}></Box>
        </Box>
      </Box>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "18px",
          padding: "20px 0",
        }}
      >
        Please choose aspect
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxHeight: "calc(100vh - 240px)",
          overflowY: "auto",
          padding: "10px",
        }}
      >
        {items.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              marginBottom: "16px",
            }}
          >
            <Box
              key={item._id}
              sx={{
                width: "100%",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Box dangerouslySetInnerHTML={{ __html: item.name ?? "" }}></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "10px",
              }}
            >
              {item.aspects?.map((aspect) => {
                if (!aspect?.guidedQuestion) return <></>;
                return (
                  <Box
                    sx={{
                      margin: "5px 10px",
                      backgroundColor: "#f1f1f1",
                      padding: "10px 20px",
                      borderRadius: "30px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "linear-gradient(270deg, #ff6ec4, #7873f5)",
                        backgroundSize: "400% 400%",
                        animation: "gradient-animation 4s ease infinite",
                        color: "#fff",
                      },
                      ...(aspectSelected.includes(aspect._id)
                        ? {
                            background: "linear-gradient(270deg, #ff6ec4, #7873f5)",
                            backgroundSize: "400% 400%",
                            animation: "gradient-animation 4s ease infinite",
                            color: "#fff",
                          }
                        : {}),
                      "@keyframes gradient-animation": {
                        "0%": {
                          backgroundPosition: "0% 50%",
                        },
                        "50%": {
                          backgroundPosition: "100% 50%",
                        },
                        "100%": {
                          backgroundPosition: "0% 50%",
                        },
                      },
                    }}
                  >
                    <Box
                      dangerouslySetInnerHTML={{ __html: aspect.name ?? "" }}
                      onClick={() => {
                        setAspectSelected((prev) =>
                          prev.includes(aspect._id)
                            ? prev.filter((idItem) => idItem !== aspect._id)
                            : [...prev, aspect._id]
                        );
                      }}
                    ></Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "60px",
        }}
      >
        <Button onClick={onNext} variant="contained" color="secondary" disabled={aspectSelected.length === 0}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default GuidedQuestionPage;
