import React, { useEffect } from "react";

const ChatwootWidget: React.FC = () => {
  useEffect(() => {
    // Add Chatwoot Settings
    (window as any).chatwootSettings = {
      hideMessageBubble: false,
      position: "left", // This can be left or right
      locale: "vi", // Language to be set
      type: "standard", // [standard, expanded_bubble]
    };

    // Paste the script from inbox settings except the <script> tag
    (function (d, t) {
      const BASE_URL = "https://chatwoot.fongfluency.com";
      const g = d.createElement(t) as HTMLScriptElement;
      const  s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      if (s.parentNode) {
        s.parentNode.insertBefore(g, s);
      }
      g.onload = function () {
        (window as any).chatwootSDK.run({
          websiteToken: "HDjGE2We3joQXASwniZq48rP",
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");
  }, []);

  return null;
};

export default ChatwootWidget;
