import { LLMTypes } from "@/constants";
import { useGetListEnergyPrice } from "@/services-react-query/queries";
import LinkIcon from "@mui/icons-material/Link";
import { Box, Card, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import StarIcon from "@mui/icons-material/Star";

interface IAddConnectorProps {
  onGenerateConnector: () => void;
}

function AddConnector({ onGenerateConnector }: IAddConnectorProps) {
  const { data: listEnergyPrice } = useGetListEnergyPrice({});
  return (
    <Box>
      <Tooltip
        title={"Click to genereate connector between sentences "}
        sx={{
          fontSize: 20,
        }}
        aria-setsize={20}
      >
        <Card
          className={clsx(["card-ai"])}
          onClick={onGenerateConnector}
          sx={{
            backgroundColor: "#79c745",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <LinkIcon />
              Add connector
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>({listEnergyPrice?.types?.[LLMTypes.add_connector]} </Typography>
              <StarIcon
                sx={{
                  marginLeft: "2px",
                  fontSize: "18px",
                }}
                htmlColor="#fff31b"
              />
              )
            </Box>
          </Box>
        </Card>
      </Tooltip>
    </Box>
  );
}

export default AddConnector;
