import SortItem from "@/components/SortItem";
import { sortItemOrder } from "@/helpers";
import {
  useAddMultipleItems,
  useDeleteItem,
  useGetGroupQuestionPart2All,
  useGetQuestionPart2All,
  useUpdateItem,
} from "@/services-react-query/queries";
import { Box, Button, Chip, LinearProgress, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CreateEditItemModal, { IFormDataProps } from "../../../components/CreateEditItemModal";
import { MenuAction } from "../../../components/MenuAction";
import NoDataComponent from "../../../components/NoDataComponent";
import {
  EAction,
  ETypeItem,
  IApplicationState,
  ICreateItem,
  IItems,
  ItemsSort,
  showMessage,
} from "../../../store-redux";
import { IHandleAction } from "./interface";
import { styles } from "./styles";
import GenerateQuidedQuestions from "./components/GenerateQuidedQuestions";

interface IQuestionPart2CMSPageProps {
  isPageQuestion?: boolean;
  onClickAspect?: (id: string) => void;
}

const QuestionPart2CMSPage = ({ isPageQuestion, onClickAspect }: IQuestionPart2CMSPageProps) => {
  const dispatch = useDispatch();
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null);
  const typeDelete = useRef("");
  const [openModalCreate, setOpenModalCreate] = useState<{
    type: ETypeItem;
    open: boolean;
    data?: IItems;
  }>({
    type: ETypeItem["group.question.part2"],
    open: false,
  });

  const {
    data: listGroup,
    isLoading: isLoadingGroup,
    refetch: refetchGroup,
  } = useGetGroupQuestionPart2All({
    query: {
      type: ETypeItem["group.question.part2"],
      limit: Number.MAX_SAFE_INTEGER,
      skip: 0,
    },
  });

  const { data: listQuestionPart2, refetch: refetchQuestionPart2 } = useGetQuestionPart2All({
    query: {
      type: ETypeItem["question.part2"],
      limit: Number.MAX_SAFE_INTEGER,
      skip: 0,
      parentId: selectedGroupId ?? "",
    },
    options: {
      enabled: false,
    },
  });
  const { mutate: addMultipleItems, isLoading: isLoadingAdd } = useAddMultipleItems({
    onSuccess: (data) => {
      if (data) {
        handleCloseModal();
      }
    },
  });
  const { mutate: updateItem, isLoading: isLoadingUpdate } = useUpdateItem({
    onSuccess: (data) => {
      if (data) {
        handleCloseModal();
      }
    },
  });
  const { mutate: deleteItem } = useDeleteItem({
    onSuccess: (data) => {
      if (data) {
        if (typeDelete.current === ETypeItem["group.question.part2"]) {
          refetchGroup();
        } else if (typeDelete.current === ETypeItem["question.part2"]) {
          refetchQuestionPart2();
        }
      }
    },
  });
  const listAspects = useSelector((state: IApplicationState) => state.listAspects);

  // SORT CATEGORY
  const [listSortGroup, setListSortGroup] = useState<ItemsSort[]>([]);
  useEffect(() => {
    setListSortGroup(sortItemOrder(listGroup ?? [])?.map((item) => ({ ...item, id: item._id })) ?? []);
  }, [listGroup]);

  // SORT ASPECT
  const [listSortQuestionPart2, setListSortQuestionPart2] = useState<ItemsSort[]>([]);
  useEffect(() => {
    setListSortQuestionPart2(sortItemOrder(listQuestionPart2 ?? [])?.map((item) => ({ ...item, id: item._id })) ?? []);
  }, [listQuestionPart2]);

  const handleOpenCreate = (type: ETypeItem) => {
    setOpenModalCreate({
      type,
      open: true,
      data: undefined,
    });
  };

  const handleCloseModal = () => {
    setOpenModalCreate({
      type: ETypeItem["group.question.part2"],
      open: false,
    });
    if (openModalCreate.type === ETypeItem["group.question.part2"]) {
      refetchGroup();
    } else if (openModalCreate.type === ETypeItem["question.part2"]) {
      refetchQuestionPart2();
    }
  };

  const handleCreateEdit = (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item) => ({
      name: item.title,
      description: item.description,
      type: openModalCreate.type,
      parentId: openModalCreate.type === ETypeItem["question.part2"] ? selectedGroupId : "",
      imageUrl: item.imageUrl,
      ...(item.file ? { file: item.file } : {}),
      ...(openModalCreate.type === ETypeItem["question.part2"]
        ? {
            public: item.public ?? false,
          }
        : {}),
    }));
    if (openModalCreate.data) {
      updateItem({ ...dataSend?.[0], id: openModalCreate.data._id });
    } else {
      addMultipleItems({ items: dataSend });
    }
  };

  const handleGroupClick = (topic: IItems) => {
    setSelectedGroupId(topic._id);
    if (topic._id !== selectedGroupId) {
      setTimeout(() => {
        refetchQuestionPart2();
      }, 100);
    }
  };

  const handleAction = ({ type, typeAction, data }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          typeDelete.current = type;
          deleteItem(data._id);
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        type,
        open: true,
        data,
      });
    }
  };

  const selectedQuestion = useMemo(() => {
    return listSortQuestionPart2.find((item) => item._id === selectedQuestionId);
  }, [selectedQuestionId]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      })}
    >
      {isLoadingGroup ? <LinearProgress color="secondary" style={{ marginRight: 10 }} /> : null}
      <Box
        sx={{
          ...styles.boxContainer,
          ...(selectedQuestion ? { position: "relative" } : {}),
        }}
      >
        {selectedQuestion && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#fff",
              zIndex: 999,
            }}
          >
            <GenerateQuidedQuestions question={selectedQuestion} onBack={() => setSelectedQuestionId(null)} />
          </Box>
        )}
        <Box className="flex-group">
          <Typography variant="h3">Group</Typography>
          <Button
            variant="contained"
            onClick={() => handleOpenCreate(ETypeItem["group.question.part2"])}
            color="secondary"
            disabled={isPageQuestion}
          >
            Create groups
          </Button>
          <Box className="box-item">
            <SortItem
              listData={listGroup ?? []}
              listItem={listSortGroup}
              setListItem={setListSortGroup}
              isDisableSort={isPageQuestion}
            >
              <>
                {listSortGroup?.map((topic) => (
                  <Box
                    key={topic._id}
                    onClick={() => {
                      handleGroupClick(topic);
                      if (onClickAspect !== undefined) {
                        onClickAspect("");
                        setSelectedQuestionId(null);
                      }
                    }}
                    sx={{
                      ...styles.styleItem,
                      backgroundColor: selectedGroupId === topic._id ? "lightblue" : "transparent",
                      "& .title": {
                        display: "flex",
                        "& .count": {
                          marginLeft: "5px",
                        },
                      },
                      "& .description": {
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2 /* number of lines to show */,
                        WebkitBoxOrient: "vertical",
                      },
                    }}
                  >
                    <Box
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html:
                          topic.name +
                            `${topic.childrenCount > 0 ? `<b class="count">(${topic.childrenCount})</b>` : ""}` || "",
                      }}
                    />
                    <Box className="description" dangerouslySetInnerHTML={{ __html: topic.description || "" }} />
                    {!isPageQuestion && (
                      <MenuAction
                        handleClick={(typeAction: EAction) => {
                          handleAction({
                            typeAction,
                            type: ETypeItem["group.question.part2"],
                            data: topic,
                          });
                        }}
                      />
                    )}
                  </Box>
                ))}
              </>
            </SortItem>
            {listGroup?.length === 0 && !isLoadingGroup ? <NoDataComponent /> : null}
          </Box>
        </Box>
        <Box className="flex-1">
          <Typography variant="h3">Question Part 2</Typography>
          <Button
            variant="contained"
            onClick={() => {
              if (selectedGroupId) {
                handleOpenCreate(ETypeItem["question.part2"]);
              } else {
                dispatch(showMessage("Please choose one group", "warning"));
              }
            }}
            color="secondary"
            disabled={isPageQuestion}
          >
            Create questions
          </Button>
          <Box className="box-item">
            <SortItem
              listData={listQuestionPart2 ?? []}
              listItem={listSortQuestionPart2}
              setListItem={setListSortQuestionPart2}
              isDisableSort={isPageQuestion}
            >
              <>
                {selectedGroupId &&
                  listSortQuestionPart2?.map((question) => (
                    <Box
                      key={question._id}
                      sx={{
                        ...styles.styleItem,
                        backgroundColor: selectedQuestionId === question._id ? "lightblue" : "transparent",
                        flexDirection: "row",
                        alignItems: "center",
                        "& .chip": {
                          marginLeft: "8px",
                        },
                        "& .title": {
                          display: "flex",
                          "& .count": {
                            marginLeft: "5px",
                          },
                        },
                      }}
                      onClick={() => {
                        setSelectedQuestionId(question._id);
                        // if (onClickAspect !== undefined) {
                        //   onClickAspect(question._id);
                        //   setSelectedQuestionId(question._id);
                        // }
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                        }}
                        display={"flex"}
                        flexDirection={"column"}
                        maxWidth={"85%"}
                      >
                        <Box
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html:
                              question.name +
                                `${
                                  question.childrenCount > 0 ? `<b class="count">(${question.childrenCount})</b>` : ""
                                }` || "",
                          }}
                        />
                        <Box
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: question.description || "",
                          }}
                        />
                      </Box>
                      {question.public && <Chip label={"Public"} size="small" color="success" className="chip" />}
                      {!isPageQuestion && (
                        <MenuAction
                          handleClick={(typeAction: EAction) => {
                            handleAction({
                              typeAction,
                              type: ETypeItem["question.part2"],
                              data: question,
                            });
                          }}
                        />
                      )}
                    </Box>
                  ))}
              </>
            </SortItem>
            {listQuestionPart2?.length === 0 && !listAspects.isFetching ? <NoDataComponent /> : null}
          </Box>
        </Box>
      </Box>
      <CreateEditItemModal
        handleCreateEdit={handleCreateEdit}
        openModal={openModalCreate.open}
        handleCloseModal={() => {
          setOpenModalCreate({
            type: ETypeItem["group.question.part2"],
            open: false,
          });
        }}
        isLoading={isLoadingAdd || isLoadingUpdate}
        titleModal={
          openModalCreate?.data
            ? openModalCreate.type === ETypeItem["group.question.part2"]
              ? `Edit group`
              : `Edit question`
            : openModalCreate.type === ETypeItem["group.question.part2"]
            ? `Create new groups`
            : `Create new questions`
        }
        data={openModalCreate.data}
        isUploadFile
        isQuestion={openModalCreate.type === ETypeItem["question.part2"]}
      />
    </Box>
  );
};

export default QuestionPart2CMSPage;
