import { Switch as MuiSwitch, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { ISwitchProps } from "./types";

export default function Switch(props: ISwitchProps) {
  const { control, label, rules, controllerExtras = {}, handleChange, ...other } = props;

  return (
    <section>
      {label && <Typography variant="caption">{label}</Typography>}
      <Controller
        name={other.name}
        render={({ field: { onChange, value } }) => (
          <MuiSwitch
            {...props}
            {...other}
            onChange={(e) => {
              onChange(e.target.checked);
              handleChange && handleChange(e.target.checked);
            }}
            checked={value}
          />
        )}
        control={control}
        rules={rules}
        {...controllerExtras}
      />
    </section>
  );
}
