import { IApplicationState } from "@/store-redux";
import BoltIcon from "@mui/icons-material/Bolt";
import { Box, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const RemainingEnergy = (props) => {
  const remainingEnergy = useSelector((state: IApplicationState) => state.remainingEnergy);
  if (!remainingEnergy?.data) return <></>;
  return (
    <Tooltip
      title={
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          You have 2000 energy and it will be reset every day
        </Typography>
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#ff7269",
          borderRadius: "8px",
          padding: "4px 10px 4px 16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          {remainingEnergy?.data?.remain ?? " "}
        </Typography>
        <BoltIcon
          sx={{
            fontSize: "18px",
          }}
          htmlColor="#fff31b"
        />
      </Box>
    </Tooltip>
  );
};
export default RemainingEnergy;
