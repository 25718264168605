import { IconButton } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import { useSendDictReport } from "@/services-react-query/queries";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";

const ReportDictBtn = ({ id }) => {
  const dispatch = useDispatch();
  const { mutate: sendDictReport, isLoading: loadingReport } = useSendDictReport({
    onSuccess(data) {
      dispatch(showMessage("Report dict successfully!"));
    },
  });

  const onSend = () => {
    sendDictReport(id);
  };
  return (
    <>
      <IconButton disabled={loadingReport} size="small" onClick={() => onSend()}>
        <ReportIcon sx={{ color: "#F5004F" }} />
      </IconButton>
    </>
  );
};

export default ReportDictBtn;
