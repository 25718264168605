import { useEffect, useMemo, useState } from "react";
import structures from "./structure.json";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StructureAspect from "./components/StructureAspect";
import _ from "lodash";
import { ENDPOINTS, Requests, getApiUrl } from "@/common";
import { jsonToFile } from "@/helpers";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
import { EBackOfficeCode } from "@/common/types/backoffice";
const StructureCMSPage = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [indexAspectDetail, setIndexAspectDetail] = useState(null);
  useEffect(() => {
    getStructure();
  }, []);

  const getStructure = async () => {
    try {
      const dataRes = await Requests.get(
        getApiUrl(ENDPOINTS.BACKOFFICE_GET_BY_CODE.replace(":code", EBackOfficeCode.STRUCTURE_CMS))
      );
      if (Array.isArray(dataRes?.data)) {
        const data = dataRes?.data;
        setData(data);
      } else {
        setData(structures);
      }
    } catch (e) {
      setData(structures);
    }
  };

  const onSave = async (dataStructure) => {
    const file = jsonToFile(dataStructure);
    // const urlUploadFile = getApiUrl(ENDPOINTS.SAVE_STRUCTURE_CMS);
    const urlUploadFile = getApiUrl(ENDPOINTS.BACKOFFICE_SAVE_BY_CODE.replace(":code", EBackOfficeCode.STRUCTURE_CMS));

    try {
      await Requests.post(
        urlUploadFile,
        {
          file: file,
        },
        true,
        false
      );
      setIsLoading(false);
      dispatch(showMessage("Update structure data success", "success"));
    } catch (e) {
      setIsLoading(false);
      dispatch(showMessage("Update structure data error", "error"));
    }
    setIndexAspectDetail(null);
  };
  if (indexAspectDetail !== null) {
    return (
      <Box sx={{ height: "100%", position: "relative" }}>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              backgroundColor: "rgba(255,255,255, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
        )}
        <StructureAspect
          item={data[indexAspectDetail]}
          onBack={() => {
            setIndexAspectDetail(null);
          }}
          onSave={(value) => {
            const dataSaved = data.map((item, index) => (index === indexAspectDetail ? value : item));
            setData(dataSaved);
            onSave(dataSaved);
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <TableContainer component={Paper} sx={{ height: "calc(100% - 60px)" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Structures
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Examples
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              const nbExamples = item.structures.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.examples.length;
              }, item.examples.length);
              return (
                <TableRow
                  onClick={() => {
                    setIndexAspectDetail(index);
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                  key={index}
                >
                  <TableCell>{item.aspect}</TableCell>
                  <TableCell>{item.structures.length}</TableCell>
                  <TableCell>{nbExamples}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StructureCMSPage;
