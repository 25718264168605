import WordDetail from "@/pages/LearnPage/components/WordSearch/WordDetail";
import { Box, Modal, Typography } from "@mui/material";

const VocabDetailModal = (props) => {
    const {
        onClose,
        word
    } = props;
    return (
        <Modal
            open={true}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    minWidth: {
                      xs: "100vw",
                      sm: "500px",
                    },
                    maxWidth: {
                      xs: "100vw",
                      sm: "600px",
                    },
                    maxHeight: 'calc(100vh - 160px)',
                    width: "100%",
                    "& form": {
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    },
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {
                    word
                    ?
                    <>
                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: '24px',
                            marginBottom: '10px'
                        }}>
                            {word.word}
                        </Typography>
                        <Box sx={{
                            height: 'calc(100% - 50px)',
                            overflowY: 'auto'
                        }}>
                            <WordDetail word={word}/>
                        </Box>
                    </>
                    :
                    <></>
                }
               
            </Box>
        </Modal>
    )
}

export default VocabDetailModal;