import RemainingEnergy from "@/components/RemainingEnergy";
import { KeyTokenName, ROUTES, appVersion } from "@/constants";
import { getUrlServer } from "@/helpers";
import { AllTypeActions, IApplicationState, resetData } from "@/store-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import Person2Icon from "@mui/icons-material/Person2";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { Avatar } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import * as React from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface IPersistentDrawerLeftProps {
  children: JSX.Element;
}

const ListItemNav = [
  {
    title: "Learn",
    icon: <LocalLibraryIcon />,
    path: ROUTES.LEARN,
  },
  {
    title: "Personalization",
    icon: <SensorOccupiedIcon />,
    path: ROUTES.PERSONALIZED,
  },
  {
    title: "Challenge",
    icon: <TrackChangesIcon />,
    path: ROUTES.CHALLENGE,
  },
  {
    title: "Profile",
    icon: <Person2Icon />,
    path: ROUTES.PROFILE,
  },
  {
    title: "Sign out",
    icon: <LogoutIcon />,
    path: "",
  },
];

export default function PersistentDrawerLeft({ children }: IPersistentDrawerLeftProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);

  const valueFromParam = (): number => {
    if (pathname.indexOf("learn") !== -1) {
      return 0;
    } else if (pathname.indexOf("challenge") !== -1) {
      return 2;
    } else if (pathname.indexOf("personalized") !== -1) {
      return 1;
    } else if (
      pathname.indexOf("profile") !== -1 ||
      pathname.indexOf("wordbank") !== -1 ||
      pathname.indexOf("wordbook") !== -1
    ) {
      return 3;
    }
    return 0;
  };

  const [value, setValue] = React.useState<number>(valueFromParam());

  React.useEffect(() => {
    setValue(valueFromParam());
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem(KeyTokenName);
        navigate("/login");
        dispatch(resetData(AllTypeActions.GET_CURRENT_USER));
        dispatch(resetData(AllTypeActions.POST_LOGIN));
      }
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "rgb(255, 255, 255)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: "9!important",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon htmlColor="#000" />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ fontSize: "26px", color: "black" }}>
            {ListItemNav[value].title}
          </Typography>
        </Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flex: 1,
            padding: "0 10px",
          }}
        >
          <RemainingEnergy />
        </Box>
        <Box
          sx={{
            marginRight: "16px",
            paddingRight: "16px",
            display: "flex",
            cursor: "pointer",
            paddingLeft: "16px",
            "&:hover": {
              backgroundColor: "rgba(17, 24, 39, 0.04)",
              borderRadius: "20px",
            },
          }}
          onClick={() => navigate(ROUTES.PROFILE)}
        >
          <Box
            sx={{
              color: "black",
              marginRight: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box>{currentUser.data?.username}</Box>
            <Box sx={{ color: "rgb(107, 114, 128)", fontSize: "14px", textTransform: "capitalize" }}>
              {currentUser.data?.role}
            </Box>
          </Box>

          <Avatar
            src={currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"}
          />
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          "& .MuiPaper-root": {
            backgroundColor: "#111827",
            color: "white",
            "& .MuiListItemIcon-root": {
              minWidth: "32px",
            },
            "& .MuiListItem-root": {
              minWidth: "32px",
              color: "rgba(255, 255, 255, 0.7)",
              "& svg": {
                color: "rgba(255, 255, 255, 0.7)",
              },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              "&.active": {
                color: "rgb(255, 255, 255)",
                transition: "border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                backgroundColor: "rgba(255, 255, 255, 0.1) !important",
                "& span": {
                  fontSize: "18px",
                },
                "& svg": {
                  fontSize: "22px",
                  color: "white",
                },
              },
            },
            "& svg": {
              fontSize: "20px",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "8px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& img": {
                height: "40px",
                marginRight: "8px",
              },
            }}
          >
            <img src={"/svgs/logo.svg"} />
            <Typography
              variant="h6"
              sx={{
                fontSize: "16px",
                color: "#139eca",
              }}
            >
              FongFluencyLab
            </Typography>
          </Box>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon htmlColor="white" />
              ) : (
                <ChevronRightIcon htmlColor="white" />
              )}
            </IconButton>
          </DrawerHeader>
        </Box>

        <Divider />
        <List>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "32px",
              "&>div:first-of-type": {
                height: "96px",
                width: "96px",
                marginBottom: "16px",
              },
            }}
          >
            <Avatar
              src={
                currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"
              }
            />
            <Box sx={{ color: "white", marginLeft: "8px" }}>{currentUser.data?.username}</Box>
            <Box sx={{ color: "rgb(107, 114, 128)", marginLeft: "8px", fontSize: "14px" }}>
              {currentUser.data?.email}
            </Box>
          </Box>
          {ListItemNav.map((item, index) => (
            <Fragment key={index}>
              {index === ListItemNav.length - 1 ? (
                <Divider sx={{ backgroundColor: "rgba(241, 245, 249, 0.12)", marginInline: "8px" }} />
              ) : null}
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  if (index === ListItemNav.length - 1) {
                    handleLogout();
                  } else {
                    navigate(item.path);
                    setValue(index);
                  }
                }}
                className={clsx(value === index && "active")}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            </Fragment>
          ))}
        </List>
        <Typography
          component={"h3"}
          sx={{
            backgroundColor: "rgb(79, 70, 229)",
            borderRadius: "8px",
            padding: "4px 8px",
            width: "fit-content",
            position: "fixed",
            bottom: "28px",
            left: "75px",
            marginInline: "16px",
          }}
        >
          {appVersion}
        </Typography>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
