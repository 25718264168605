import { ENDPOINTS, IChangeStatus, ICreateMultipleUser, IEditInfoUser, IItems, Requests, getApiUrl } from "@/common";

export const createMultipleUsers = async (payload: ICreateMultipleUser): Promise<IItems[]> => {
  const apiUrl = getApiUrl(ENDPOINTS.CREATE_MULTIPLE_USERS);
  const { data }: any = await Requests.post(apiUrl, payload);
  return data;
};

export const updateInfoUser = async (payload: IEditInfoUser): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.EDIT_INFO_USER);
  if (payload.hasOwnProperty("preference")) {
    payload.preference = JSON.stringify(payload.preference);
  }
  const res: any = await Requests.post(apiUrl, payload, true, false);
  return res;
};

export const changeStatusUser = async (payload: IChangeStatus): Promise<any> => {
  const { id, ...rest } = payload;
  const apiUrl = getApiUrl(ENDPOINTS.CHANGE_STATUS_USER, { id }, true);
  const { data }: any = await Requests.post(apiUrl, rest);
  return data;
};
