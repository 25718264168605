import { useRef } from "react";
import { useDispatch } from "react-redux";
import { ReactSortable, SortableEvent } from "react-sortablejs";
import { EAction, IItems, ItemsSort, updateOrder } from "../../store-redux";

export interface IHandleAction {
  typeAction: EAction;
  data: IItems;
}

interface ISortItemProps {
  listData: IItems[];
  children: JSX.Element;
  listItem: ItemsSort[];
  setListItem: React.Dispatch<React.SetStateAction<ItemsSort[]>>;
  isDisableSort?: boolean;
}

function SortItem({ listData, children, listItem, setListItem, isDisableSort }: ISortItemProps) {
  const dispatch = useDispatch();
  const listItemRef = useRef(listData);

  const handleSortItem = (newState: ItemsSort[]) => {
    setListItem(newState);
    listItemRef.current = newState;
  };

  const onEndSort = (evt: SortableEvent) => {
    /* If position did not change neither the containing list */
    if (evt.oldIndex === evt.newIndex && evt.from === evt.to) {
      return;
    }
    /* If position changed within the same category */
    if (evt.oldIndex !== evt.newIndex) {
      const newPosition = evt.newIndex;
      let newOrder = 0;

      const itemChange = listItemRef.current?.[newPosition];
      newOrder =
        ((listItemRef.current?.[newPosition - 1]?.order ?? 0) +
          (listItemRef.current?.[newPosition + 1]?.order ?? listItemRef.current?.[newPosition - 1]?.order + 1)) /
        2;
      const newValue = listItemRef.current?.map((item) => {
        if (item._id === itemChange?._id) {
          return {
            ...item,
            id: item._id,
            order: newOrder,
          };
        }
        return { ...item, id: item._id };
      });
      setListItem(newValue);
      if (itemChange) {
        dispatch(
          updateOrder({
            ...itemChange,
            id: itemChange._id,
            name: itemChange.name,
            description: itemChange.description ?? "",
            type: itemChange.type,
            parentId: itemChange.parentId,
            aspectId: itemChange.aspectId,
            order: newOrder,
          })
        );
      }
    }
  };
  if (isDisableSort) return children;

  return (
    <ReactSortable
      list={listItem}
      setList={(newState) => handleSortItem(newState)}
      animation={200}
      delayOnTouchStart={true}
      delay={2}
      onEnd={onEndSort}
    >
      {children}
    </ReactSortable>
  );
}

export default SortItem;
