import { Box, IconButton, Input, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

interface GrammarExampleProps {
  example: { text: string; type: string }[];
  onSave: (dataEdit: { text: string; type: string }[]) => void;
  disabled?: boolean;
}

const GrammarExample: React.FC<GrammarExampleProps> = ({ example, onSave, disabled = false }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<{ text: string; type: string }[]>([]);

  if (isEdit) {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        {dataEdit.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                padding: "4px",
              }}
            >
              <Input
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  marginBottom: "4px",
                  "&::after": {
                    display: "none",
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                value={item.text}
                onChange={(event) =>
                  setDataEdit((prev) =>
                    prev.map((itemP, indexP) => (index === indexP ? { ...itemP, text: event.target.value } : itemP))
                  )
                }
              />
              <Input
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px",
                  "&::after": {
                    display: "none",
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                value={item.type}
                onChange={(event) =>
                  setDataEdit((prev) =>
                    prev.map((itemP, indexP) => (index === indexP ? { ...itemP, type: event.target.value } : itemP))
                  )
                }
              />
              <Tooltip title="Delete" placement="top">
                <IconButton
                  onClick={() => {
                    setDataEdit((prev) => prev.filter((itemP, indexP) => indexP !== index));
                  }}
                  sx={{
                    color: "#fff",
                    backgroundColor: "#FFB22C",
                    margin: "2px 0",
                    "&:hover": {
                      backgroundColor: "#FFB22C",
                    },
                  }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        })}
        <Box>
          <Tooltip title="Add" placement="top">
            <IconButton
              onClick={() => {
                setDataEdit((prev) => [
                  ...prev,
                  {
                    text: "",
                    type: "",
                  },
                ]);
              }}
              sx={{
                color: "#fff",
                backgroundColor: "#3FA2F6",
                "&:hover": {
                  backgroundColor: "#3FA2F6",
                },
              }}
              size="small"
            >
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save" placement="top">
            <IconButton
              onClick={() => {
                onSave(dataEdit);
                setIsEdit(false);
              }}
              sx={{
                color: "#fff",
                backgroundColor: "#06D001",
                margin: "2px 0",
                "&:hover": {
                  backgroundColor: "#06D001",
                },
              }}
              size="small"
            >
              <SaveIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel" placement="top">
            <IconButton
              onClick={() => setIsEdit(false)}
              sx={{
                color: "#fff",
                backgroundColor: "#758694",
                "&:hover": {
                  backgroundColor: "#758694",
                },
              }}
              size="small"
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        "&:hover": {
          "& .iconEdit": {
            opacity: 1,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          paddingRight: "10px",
        }}
      >
        {example.map((item, index) => {
          return (
            <Tooltip key={index} title={item.type} placement="top">
              <Box
                sx={{
                  margin: "0 2px",
                  cursor: "pointer",
                  borderBottom: "2px dashed transparent",
                  "&:hover": {
                    borderBottom: "2px dashed #ccc",
                  },
                }}
              >
                {item.text}
              </Box>
            </Tooltip>
          );
        })}
      </Box>
      {!disabled && (
        <IconButton
          className="iconEdit"
          sx={{
            opacity: 0,
          }}
          onClick={() => {
            setIsEdit(true);
            setDataEdit(example);
          }}
        >
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default GrammarExample;
