import { AllTypeActions } from "../types";
import { SigninInput, AlertSeverity, IUser } from "../interface";

export const postLogin = (input: SigninInput): any => {
  return {
    type: AllTypeActions.POST_LOGIN,
    input,
  };
};

export const getCurrentUser = (): any => {
  return {
    type: AllTypeActions.GET_CURRENT_USER,
  };
};

export const getRemainingEnergy = (): any => {
  return {
    type: AllTypeActions.GET_REMAINING_ENERGY,
  };
};

export const getListUser = (): any => {
  return {
    type: AllTypeActions.GET_LIST_USER,
  };
};

export const createUser = (input: Omit<IUser, "_id">): any => {
  return {
    type: AllTypeActions.CREATE_USER,
    input,
  };
};
export const updateUser = (input: IUser): any => {
  return {
    type: AllTypeActions.UPDATE_USER,
    input,
  };
};

export const deleteUser = (id: string): any => {
  return {
    type: AllTypeActions.DELETE_USER,
    id,
  };
};

export const showMessage = (message: string, severity?: AlertSeverity): any => {
  return {
    type: AllTypeActions.SYSTEM_MESSAGE_SHOW,
    message,
    severity,
  };
};

export const hideMessage = (): any => {
  return {
    type: AllTypeActions.SYSTEM_MESSAGE_HIDE,
  };
};
