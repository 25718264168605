import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import SearchInput from "@/components/Search";

interface IPropsSearchTodo {
  onSearch: (searcInfoUser: string, searchRole: string) => void;
}

function SearchInfoUser({ onSearch }: IPropsSearchTodo) {
  const [searcInfoUser, setSearcInfoUser] = useState("");
  const [searchRole, setSearchRole] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSearchRole(event.target.value as string);
  };

  const onHandleSearch = (value: string) => {
    setSearcInfoUser(value);
  };

  const clearFilter = () => {
    setSearcInfoUser("");
    setSearchRole("");
  };

  useEffect(() => {
    onSearch(searcInfoUser, searchRole);
  }, [searcInfoUser, searchRole]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minWidth: "500px",
          alignItems: "flex-end",
          width: "auto",
          maxWidth: "900px",
          gap: "20px",
          "& .search": { flex: "2", margin: "0" },
          "& .MuiFormControl-root": { flex: "1" },
          "& .MuiSelect-select": { padding: "10px 14px" },
        }}
      >
        <SearchInput handleSearch={onHandleSearch} valueSearch={searcInfoUser} changeWithNotText/>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchRole}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value="super_admin">super_admin</MenuItem>
            <MenuItem value="admin">admin</MenuItem>
            <MenuItem value="user">user</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Button
        onClick={clearFilter}
        sx={{
          width: "90px",
          backgroundColor: "#264653",
          color: "white",
          textTransform: "none",
          // marginRight: "10px",
          padding: "0 8px",
        }}
      >
        Clear filter
      </Button>
    </Box>
  );
}

export default SearchInfoUser;
