import {
  createItems,
  deleteItem,
  EAction,
  ETypeItem,
  getListAspects,
  IApplicationState,
  IItems,
  updateItem,
} from "@/store-redux";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGenerateGuidedQ from "../../QuestionPart2CMSPage/components/FormGenerateGuidedQ";
import { extractContent } from "@/helpers";
import { styles } from "../styles";
import { MenuAction } from "@/components/MenuAction";
import Swal from "sweetalert2";
import { IHandleAction } from "../interface";
import CreateEditItemModal from "@/components/CreateEditItemModal";

interface GuidedQuestionSearchAIProps {
  onBack: () => void;
  selectedCategoryId: string | null;
}
const GuidedQuestionSearchAI: React.FC<GuidedQuestionSearchAIProps> = ({ selectedCategoryId }) => {
  const dispatch = useDispatch();
  const listAspects = useSelector((state: IApplicationState) => state.listAspects);
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const createItemsResult = useSelector((state: IApplicationState) => state.createItems);
  const updateItemResult = useSelector((state: IApplicationState) => state.updateItem);
  const [openModalCreate, setOpenModalCreate] = useState<{
    open: boolean;
    data?: IItems;
    init?: any[];
  }>({
    open: false,
  });

  const question = detailQuestion?.data;
  const handleGetListAspect = () => {
    if (selectedCategoryId)
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          parentId: selectedCategoryId,
          questionId: detailQuestion?.data._id,
          isWithGuidedQuestion: true,
        })
      );
  };
  useEffect(() => {
    handleGetListAspect();
  }, [selectedCategoryId]);

  const handleCreateEdit = (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item, index) => ({
      name: item.title,
      description: item.description,
      type: ETypeItem["guided.question"],
      parentId: question._id,
      ...(openModalCreate.data
        ? {
            aspectId: openModalCreate.data?.aspectId,
          }
        : {
            aspectId: openModalCreate.init?.[index].id,
          }),
    }));
    if (openModalCreate.data) {
      dispatch(updateItem({ ...dataSend?.[0], id: openModalCreate.data._id }));
    } else {
      dispatch(createItems({ items: dataSend }));
    }
  };
  const handleCloseModal = () => {
    setOpenModalCreate({
      open: false,
    });
  };
  const handleAction = ({ type, typeAction, data }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteItem(data._id);
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        type,
        open: true,
        data,
      });
    }
  };
  // CREATE
  useEffect(() => {
    if (createItemsResult.data && !createItemsResult.error) {
      handleGetListAspect();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createItemsResult]);

  // UPDATE
  useEffect(() => {
    if (updateItemResult.data && !updateItemResult.error) {
      handleGetListAspect();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemResult]);

  //   console.log('listAspects')
  const listSortAspectFilterCat = listAspects.data?.filter((item) => item.parentId === selectedCategoryId);
  return (
    <Box className="flex-1">
      <FormGenerateGuidedQ
        questionContent={extractContent(question.name)}
        onGenGuidedQ={(items: any[]) => {
          setOpenModalCreate({
            open: true,
            init: items.map((item) => ({ ...item, title: item.guidedQuestion ?? "" })),
          });
        }}
        aspects={listSortAspectFilterCat}
      />
      <Box className="box-item" sx={{ width: "100%", overflowY: "auto", maxHeight: "calc(100vh - 280px)" }}>
        {listSortAspectFilterCat?.map((aspect) => (
          <Box
            key={aspect._id}
            sx={{
              ...styles.styleItem,
              "& .title": {
                display: "flex",
                "& .count": {
                  marginLeft: "5px",
                },
              },
            }}
          >
            <Box
              className="title"
              dangerouslySetInnerHTML={{
                __html:
                  aspect.name + `${aspect.childrenCount > 0 ? `<b class="count">(${aspect.childrenCount})</b>` : ""}` ||
                  "",
              }}
            />
            {/* <Box className="description" dangerouslySetInnerHTML={{ __html: aspect.description || "" }} /> */}
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              Guided question
            </Typography>
            <Box
              sx={{
                paddingRight: "40px",
                position: "relative",
              }}
            >
              <Box
                className="title"
                dangerouslySetInnerHTML={{
                  __html: aspect?.guidedQuestion?.name ?? "",
                }}
              />
              <MenuAction
                handleClick={(typeAction: EAction) => {
                  handleAction({
                    typeAction,
                    type: ETypeItem["guided.question"],
                    data: aspect?.guidedQuestion,
                  });
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <CreateEditItemModal
        handleCreateEdit={handleCreateEdit}
        openModal={openModalCreate.open}
        handleCloseModal={handleCloseModal}
        isLoading={createItemsResult.isFetching}
        titleModal={`Create guided questions`}
        data={openModalCreate.data}
        init={openModalCreate.data ? undefined : openModalCreate.init}
        isGuidedQuestion={openModalCreate.data ? false : true}
      />
    </Box>
  );
};

export default GuidedQuestionSearchAI;
