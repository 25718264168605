export interface ISendAudioChallengeOuput {
  questionId: string;
  baremId: string;
  voicePath: string;
  status: ChallengeState;
  owner: string;
  _id: string;
}

export enum ChallengeState {
  "init" = "init",
  "uploaded" = "uploaded",
  "transcribed" = "transcribed",
  "evaluated" = "evaluated",
}

export interface ISendAudioAnswer {
  questionId: string;
  baremId: string;
  voiceFile: File;
}

export interface ISendAudioPractice {
  questionId: string;
  voiceFile: File;
}

export interface IChallengeTranscribeOutput {
  _id: string;
}

export interface IChallengeEvaluateOutput {
  gptEvaluates: {
    requirement: string;
    score: number;
    explanation: string;
    suggestion: string;
  }[];
  attempt: number;
  voiceTranscript: string;
}
export interface IPracticeEvaluateOutput {
  gptEvaluates: {
    criterium: string;
    band: number;
    explanation: string;
    suggestion: string;
  }[];
  attempt: number;
  voiceTranscript: string;
}

export enum ECurrentStep {
  CHOOSE_LEVEL_1 = "CHOOSE_LEVEL_1",
  CHOOSE_QUESTION = "CHOOSE_QUESTION",
  START_RECORDING_2 = "START_RECORDING_2",
  RECORDING_3 = "RECORDING_3",
  SUBMIT_4 = "SUBMIT_4",
  RESULT_5 = "RESULT_5",
}
