import informationJson from "@/assets/lottie-json/information.json";
import { IApplicationState } from "@/store-redux";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { Box, Button, Dialog, Typography } from "@mui/material";
import _ from "lodash";
import Lottie from "lottie-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const CompletePersonalizedAlert = () => {
  const localtion = useLocation();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const isCompletePersonalized = useMemo(() => {
    if (currentUser.data === null) return true;
    const preference = currentUser.data?.preference;
    return !_.isEmpty(preference);
  }, [currentUser]);
  if (isCompletePersonalized || localtion.pathname === "/personalized") {
    return <></>;
  }
  return (
    <Dialog open>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: "16px",
          borderRadius: "4px",
        }}
      >
        <Lottie
          animationData={informationJson}
          loop={true}
          style={{
            height: "300px",
          }}
        />
        <Typography
          sx={{
            margin: "10px 0",
            fontSize: "18px",
          }}
        >
          Please complete the <b>personalization</b> before using the software.
        </Typography>
        <Link to={"/personalized"} style={{ marginTop: "16px" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: "initial",
              width: "100%",
            }}
            startIcon={<SensorOccupiedIcon htmlColor="#fff" fontSize="large" />}
          >
            Continue
          </Button>
        </Link>
      </Box>
    </Dialog>
  );
};

export default CompletePersonalizedAlert;
