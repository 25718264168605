import { getListQuestions, IApplicationState } from "@/store-redux";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListGrammarByQuestion from "./ListGrammarByQuestion";

const ListQuestionByTopic = (props) => {
  const { topicId, grammarTopics } = props;
  const dispatch = useDispatch();
  const listQuestions = useSelector((state: IApplicationState) => state.listQuestions);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const handleGetListQuestions = () => {
    dispatch(
      getListQuestions({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        parentId: topicId,
        sortBy: "createdAt",
      })
    );
  };
  useEffect(() => {
    setSelectedQuestionId(null);
    handleGetListQuestions();
  }, [topicId]);
  return (
    <>
      <Box
        sx={
          selectedQuestionId
            ? { maxWidth: "320px", maxHeight: "calc(100vh - 150px)", overflowY: "auto" }
            : { flex: 1, maxHeight: "calc(100vh - 150px)", overflowY: "auto" }
        }
      >
        {Array.isArray(listQuestions.data) && (
          <Box>
            {listQuestions.data.map((question) => {
              return (
                <Box
                  sx={{
                    cursor: "pointer",
                    padding: "10px",
                    ...(selectedQuestionId === question._id
                      ? {
                          backgroundColor: "lightblue",
                        }
                      : {}),
                    "&:hover": {
                      backgroundColor: "#eceaea",
                    },
                  }}
                  key={question._id}
                  onClick={() => setSelectedQuestionId(question._id)}
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: question.name,
                    }}
                  />
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: question.description || "",
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      {selectedQuestionId && <ListGrammarByQuestion questionId={selectedQuestionId} grammarTopics={grammarTopics} />}
    </>
  );
};

export default ListQuestionByTopic;
