import {
  ENDPOINTS,
  IGetList,
  IItems,
  IChallengeEvaluateOutput,
  IChallengeTranscribeOutput,
  ISendAudioAnswer,
  ISendAudioChallengeOuput,
  Requests,
  getApiUrl,
} from "@/common";

export const getChallenge = async (payload: IGetList): Promise<IItems[]> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_LIST_ITEMS, payload);
  const { data }: any = await Requests.get(apiUrl);
  return data;
};

export const sendAudioAnswer = async (payload: ISendAudioAnswer): Promise<ISendAudioChallengeOuput> => {
  const apiUrl = getApiUrl(ENDPOINTS.POST_CHALLENGES_UPLOAD);
  const data: any = await Requests.post(apiUrl, payload, true, false);
  return data;
};

export const postChallengeTranscribe = async (id: string): Promise<IChallengeTranscribeOutput> => {
  const apiUrl = getApiUrl(ENDPOINTS.POST_CHALLENGES_TRANSCRIBE, { id }, true);
  const { data }: any = await Requests.post(apiUrl);
  return data;
};

export const postChallengeEvaluate = async (id: string): Promise<IChallengeEvaluateOutput> => {
  const apiUrl = getApiUrl(ENDPOINTS.POST_CHALLENGES_EVALUATE, { id }, true);
  const { data }: any = await Requests.post(apiUrl);
  return data;
};
