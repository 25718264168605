import { Fragment, memo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants";
import { parseParamUrl } from "../../store-redux";
import { Box, SxProps, Theme, emphasize } from "@mui/material";
import { orange } from "@mui/material/colors";

export enum ETypePage {
  Topic = "Topic",
  Question = "Question",
  Category = "Category",
  Aspect = "Aspect",
  Idea = "Idea",
  QuestionType = "QuestionType",
  QuestionTypeCat = "QuestionTypeCat",
}

interface ISlugChangePageProps {
  typePage: ETypePage;
}

function SlugChangePage({ typePage }: ISlugChangePageProps) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { questionId, categoryId, topicId, aspectId, questionCategoryId } = useParams();

  const renderLink = (text: string, url: string) => <span onClick={() => navigate(url + search)}>{text}</span>;

  const renderNavigation = (navigationItems: string[]) => (
    <Box sx={boxStyle}>
      {navigationItems.map((item, index) => {
        return (
          <Fragment key={index}>
            {renderLink(
              item,
              item === ETypePage.Topic
                ? ROUTES.LEARN_TOPIC
                : item === ETypePage.QuestionType
                ? ROUTES.LEARN_QUESTION_TYPE
                : parseParamUrl(ROUTES[`LEARN_${item.toUpperCase()}` as keyof typeof ROUTES], {
                    questionId,
                    categoryId,
                    topicId,
                    aspectId,
                    questionCategoryId,
                  })
            )}
            {index < navigationItems.length - 1 && ">"}
          </Fragment>
        );
      })}
    </Box>
  );

  const navigationMap = {
    Topic: [ETypePage.Topic],
    Question: [ETypePage.Topic, ETypePage.Question],
    Category: [ETypePage.Topic, ETypePage.Question, ETypePage.Category],
    Aspect: [ETypePage.Topic, ETypePage.Question, ETypePage.Category, ETypePage.Aspect],
    Idea: [ETypePage.Topic, ETypePage.Question, ETypePage.Category, ETypePage.Aspect, ETypePage.Idea],
    QuestionType: [ETypePage.QuestionType],
    QuestionTypeCat: [ETypePage.QuestionType],
  };

  return renderNavigation(navigationMap[typePage]);
}

const boxStyle: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexWrap: "wrap",
  fontSize: 18,
  gap: "5px",
  paddingInline: "8px",
  cursor: "pointer",
  "& span": {
    backgroundColor: orange[100],
    borderRadius: "100px",
    paddingInline: "8px",
    "&:hover, &:focus": {
      backgroundColor: emphasize(orange[100], 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(orange[100], 0.12),
    },
  },
});

export const SlugNavigation = memo(SlugChangePage);
