import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { ITextFieldProps } from "./types";

export default function Textfield(props: ITextFieldProps) {
  const { control, icon, rules, controllerExtras = {}, margin = "normal", handleChange, ...otherProps } = props;
  return (
    <Controller
      render={({ field: { onChange, value, ...restController } }) => (
        <TextField
          value={value ?? ""}
          onChange={(e) => {
            const _value = otherProps?.type === "number" ? _.get(e.target, "valueAsNumber", e.target.value) : e;

            onChange(_value);

            handleChange && handleChange(value);
            // otherProps?.type === 'number'
            //   ? onChange(_.get(e.target, 'valueAsNumber', e.target.value))
            //   : onChange(e);
          }} // Parses the value as a number
          {...restController}
          {...otherProps}
          margin={margin}
          InputProps={{
            endAdornment: icon && icon.ComponentRight && (
              <React.Fragment>{<InputAdornment position="start">{icon.ComponentRight}</InputAdornment>}</React.Fragment>
            ),
            startAdornment: icon && icon.ComponentLeft && (
              <React.Fragment>{<InputAdornment position="end">{icon.ComponentLeft}</InputAdornment>}</React.Fragment>
            ),
            ...otherProps.InputProps,
          }}
        />
      )}
      name={otherProps.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
