import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import ReactCardFlip from 'react-card-flip';
import FlashCardItemFront from "./FlashCardItemFront";
import FlashCardItemBack from "./FlashCardItemBack";

const FlashCardItem = (props) => {
    const {item}  =props;
    const [isFlipped, setIsFlipped] = useState(false);
    useEffect(() => {
        setIsFlipped(false)
    }, [item])

    // useEffect(() => {
    //     document.addEventListener('keydown', (event) => {
    //         if (event.key === 'Enter') {
    //             setIsFlipped(prev => !prev)
    //         } 
    //     });
    // }, [])
    return (
        <ReactCardFlip isFlipped={isFlipped}
        >
            <Box sx={{

            }} onClick={() => setIsFlipped(prev => !prev)}>
                <FlashCardItemFront item={item} />
            </Box>

            <Box onClick={() => setIsFlipped(prev => !prev)}>
                <FlashCardItemBack item={item}/>
            </Box>
        </ReactCardFlip>
    )
}

export default FlashCardItem;