import {
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { ETypeItem } from "@/common";
import { fetchListItems } from "@/services-react-query/api";
import moment from "moment";

interface IdeaByAspectModalProps {
  onClose: () => void;
  aspect: {
    _id: string;
    name: string;
  };
}

interface Idea {
  _id: string;
  name: string;
  type: ETypeItem;
  description: string;
  createdAt: string;
}

const IdeaByAspectModal: React.FC<IdeaByAspectModalProps> = ({ onClose, aspect }) => {
  const [allIdea, setAllIdea] = useState<Idea[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllIdea();
  }, []);

  const getAllIdea = async () => {
    setIsLoading(true);
    const data = await fetchListItems({
      aspectId: aspect._id,
      parentId: "*",
    });
    if (Array.isArray(data)) setAllIdea(data);
    setIsLoading(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const typeNameByCode = {
    [ETypeItem["idea.main"]]: "Main",
    [ETypeItem["idea.structure"]]: "Structure",
    [ETypeItem["idea.grammar"]]: "Grammar",
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        padding: "0 16px",
      }}
      onClose={() => onClose()}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          minWidth: {
            xs: "calc(100vw - 40px)",
          },
          maxWidth: {
            xs: "calc(100vw - 40px)",
          },
          width: "calc(100vw - 40px)",
          height: "calc(100vh - 40px)",
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "18px",
              marginRight: "10px",
            }}
          >
            Aspect:
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: aspect.name }}></div>
        </Box>
        <Box
          sx={{
            maxHeight: "calc(100% - 40px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableContainer
            sx={{
              flex: 1,
            }}
            component={Paper}
          >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>createdAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allIdea.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((idea) => (
                  <TableRow key={idea._id}>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: idea.name }}></div>
                    </TableCell>
                    <TableCell>{typeNameByCode[idea.type] ?? ""}</TableCell>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: idea.description }}></div>
                    </TableCell>
                    <TableCell>{moment(idea.createdAt).format("MMM Do YY")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoading && <LinearProgress color="secondary" />}
          <TablePagination
            sx={{
              minHeight: "50px",
            }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={allIdea.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default IdeaByAspectModal;
