import { Box, Button, Typography } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import { useState } from "react";
import { IChallengeEvaluateOutput } from "@/common";

interface IResultProps {
  nextStep: () => void;
  dataEvaluate: IChallengeEvaluateOutput | null;
}

function Result({ nextStep, dataEvaluate }: IResultProps) {
  const [printTranscript, setPrintTranscript] = useState(false);
  return (
    <Box
      sx={() => ({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& h2": {
          textAlign: "center",
          fontSize: "30px",
        },
        "& .value-number": {
          fontSize: "36px",
          fontWeight: "700",
          textAlign: "center",
        },
      })}
    >
      <Box marginBottom="8vh">
        <Typography component={"h2"}>Good Job!</Typography>
        {/* <Typography component={"h3"} className="value-number">
          3/4
        </Typography> */}
      </Box>
      {dataEvaluate?.voiceTranscript && (
        <Box minHeight={"30vh"}>
          <Box>
            <Typography component={"span"} sx={{ marginRight: "10px", fontWeight: "bold" }}>
              Your transcript:{" "}
            </Typography>
            <TypeAnimation
              sequence={[
                dataEvaluate?.voiceTranscript ?? "",
                () => {
                  setPrintTranscript(true);
                },
              ]}
              wrapper="span"
              speed={50}
              style={{
                whiteSpace: "pre-line",
                fontSize: "18px",
              }}
              cursor={false}
            />
          </Box>
          <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
            {printTranscript && (
              <>
                <Typography component={"span"} sx={{ marginRight: "10px", fontWeight: "bold" }}>
                  Your result:{" "}
                </Typography>
                <TypeAnimation
                  sequence={[
                    dataEvaluate?.gptEvaluates
                      ?.map((item) => `(${item.score}/10): ${item.requirement} => ${item.explanation} => Suggestion: ${item.suggestion}`)
                      ?.join(" \n ") ?? "",
                  ]}
                  wrapper="span"
                  speed={50}
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "18px",
                  }}
                  cursor={false}
                />
              </>
            )}
            {/* <DoneIcon style={{ marginRight: "10px" }} /> “You - Place - Topic - You” template */}
          </Box>
        </Box>
      )}
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          marginTop: '15px',
          "& button": {
            width: "100%",
            borderRadius: "8px",
            height: 56,
            fontSize: "24px",
            fontWeight: "400",
            textTransform: "none",
            marginBottom: "10px",
            "&.active": {
              fontWeight: "bold",
            },
          },
        })}
      >
        <Button variant="contained" color="secondary" className={"active"} onClick={nextStep}>
          Play again
        </Button>
        {/* <Button variant="text" color="secondary" className={"active"} style={{ fontSize: 16 }}>
          Share my result
        </Button> */}
      </Box>
    </Box>
  );
}

export default Result;
