import { Reducer, UnknownAction } from "redux";
import { GlobalReducer } from "./state";
import {
  AllTypeActions,
  IAppRequestState,
  IEditCustomAnswerState,
  IGetListAllAspectsState,
  IIdeaCombineCategoryAspect,
  IMyIdeaPathState,
  initialRequestState,
} from "..";

export const GetListTopicsReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_TOPICS.toString());
};

export const CreateTopicsReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_TOPICS.toString());
};

export const CreateQuestionsReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_QUESTIONS.toString());
};

export const GetListQuestionsReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_QUESTIONS.toString());
};

export const DeleteTopicReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.DELETE_TOPIC.toString());
};

export const DeleteQuestionReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.DELETE_QUESTION.toString());
};

export const UpdateTopicReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_TOPIC.toString());
};

export const UpdateQuestionReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_QUESTION.toString());
};

export const GetDetailItemReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GET_DETAIL_ITEM.toString());
};

export const GetListIdeasReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_IDEAS.toString());
};

export const GetListAllGrammarIdeasReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_ALL_GRAMMAR_OF_IDEAS.toString());
};

export const GetListAllStructureIdeasReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_ALL_STRUCTURE_OF_IDEAS.toString());
};

export const GetListCategoriesReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_CATEGORIES.toString());
};

export const GetListAspectsReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_ASPECTS.toString());
};

export const GetListAllAspectsReducer: Reducer = (state: IGetListAllAspectsState = { data: [] }, action: any) => {
  switch (action.type) {
    case AllTypeActions.GET_LIST_ALL_ASPECTS.toString() + "_SUCCESS": {
      return {
        ...state,
        data: [...state.data, ...action.data],
      };
    }
  }
  return state;
};

export const CreateItemsReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_ITEMS.toString());
};

export const DeleteItemReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.DELETE_ITEM.toString());
};

export const UpdateItemReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_ITEM.toString());
};

export const EditCustomAnswerReducer: Reducer = (state: IEditCustomAnswerState = { data: " " }, action: any) => {
  switch (action.type) {
    case AllTypeActions.EDIT_CUSTOM_ANSWER.toString():
      return {
        ...state,
        data: action.data,
      };
    case AllTypeActions.EDIT_CUSTOM_ANSWER_SUCCESS.toString():
      return {
        ...state,
        data: "",
      };
  }
  return state;
};

export const MyIdeaPathReducer: Reducer = (state: IMyIdeaPathState = { data: [] }, action: any) => {
  switch (action.type) {
    case AllTypeActions.ADD_INTO_CART.toString(): {
      return {
        ...state,
        data: [...state.data, action.data],
      };
    }
    case AllTypeActions.ADD_INTO_CART_SUCCESS.toString(): {
      return {
        ...state,
        data: [],
      };
    }
    case AllTypeActions.REMOVE_INTO_CART.toString(): {
      const newData = state.data.filter((item) => item._id !== action.data._id);
      return {
        ...state,
        data: newData,
      };
    }
  }
  return state;
};

export const SaveCustomAnswerReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.SAVE_CUSTOM_ANSWER.toString());
};

export const GenerateIdeasReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GENERATE_IDEAS.toString());
};

export const GenerateGrammarsReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GENERATE_GRAMMARS.toString());
};

export const GenerateStructuresReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GENERATE_STRUCTURES.toString());
};

export const GetListAnswerOfUserReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_ANSWER_OF_USER.toString());
};

export const RemoveAnswerItemReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.REMOVE_ANSWER_ITEM.toString());
};

export const RemoveMultipleItemsReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.REMOVE_MULTIPLE_ITEMS.toString());
};

export const CreateLevelReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_LEVEL.toString());
};

export const UpdateLevelReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_LEVEL.toString());
};

export const GetListLevelReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_LEVEL.toString());
};

export const UpdateOrderReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_ORDER.toString());
};

export const UpdateIdeaCombineCategoryAspect: Reducer = (
  state: { data: IIdeaCombineCategoryAspect[] } = { data: [] },
  action: UnknownAction
) => {
  switch (action.type) {
    case AllTypeActions.IDEA_COMBINE_CATEGORY_ASPECT.toString(): {
      return {
        ...state,
        data: action.data,
      };
    }
  }
  return state;
};


export const GetWordSearchReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GET_WORD_SEARCH.toString());
};
export const CreateQuestionCategoryReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_QUESTION_CATEGORY.toString());
};

export const CreateQuestionTypeReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_QUESTION_TYPE.toString());
};

export const UpdateQuestionCategoryReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_QUESTION_CATEGORY.toString());
};

export const UpdateQuestionTypeReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_QUESTION_TYPE.toString());
};

export const GetListQuestionCategoryReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_QUESTION_CATEGORY.toString());
};
export const GetListQuestionTypeReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_QUESTION_TYPE.toString());
};

export const DeleteQuestionCategoryReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.DELETE_QUESTION_CATEGORY.toString());
};
export const DeleteQuestionTypeReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.DELETE_QUESTION_TYPE.toString());
};

export const GetListGuidedQuestionsReducer: Reducer = (state: IAppRequestState = initialRequestState, action: UnknownAction) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_GUIDED_QUESTIONS.toString());
};
