import React, { useEffect, useState, ChangeEvent } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";

interface Helper {
  _id: string;
  prompt: string;
  output: string;
  type: string;
  ai: string;
  createdAt: string;
  input_words: number;
  output_words: number;
}

interface Filters {
  type?: string;
  ai?: string;
  startDate?: string;
  endDate?: string;
}

const AICallStatisCMSPage: React.FC = () => {
  const [helpers, setHelpers] = useState<Helper[]>([]);
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    fetchHelpers();
  }, [filters]);

  const fetchHelpers = async () => {
    try {
      const url = getApiUrl(ENDPOINTS.GET_HELPS, filters);

      const data = await Requests.get(url);
      setHelpers(data);
    } catch (error) {
      console.error("Error fetching helpers:", error);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleFilterReset = () => {
    setFilters({});
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Helper List
      </Typography>
      <Box component="form" sx={{ mb: 3 }}>
        <TextField label="Type" name="type" value={filters.type || ""} onChange={handleInputChange} sx={{ mr: 2 }} />
        <TextField label="AI" name="ai" value={filters.ai || ""} onChange={handleInputChange} sx={{ mr: 2 }} />
        <TextField
          label="Start Date"
          name="startDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.startDate || ""}
          onChange={handleInputChange}
          sx={{ mr: 2 }}
        />
        <TextField
          label="End Date"
          name="endDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.endDate || ""}
          onChange={handleInputChange}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={fetchHelpers} sx={{ mr: 2 }}>
          Filter
        </Button>
        <Button variant="outlined" onClick={handleFilterReset}>
          Reset
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* <TableCell>Prompt</TableCell>
              <TableCell>Output</TableCell> */}
              <TableCell>Type</TableCell>
              <TableCell>AI</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Input Words</TableCell>
              <TableCell>Output Words</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {helpers.map((helper) => (
              <TableRow key={helper._id}>
                {/* <TableCell>{helper.prompt}</TableCell>
                <TableCell>{helper.output}</TableCell> */}
                <TableCell>{helper.type}</TableCell>
                <TableCell>{helper.ai}</TableCell>
                <TableCell>{new Date(helper.createdAt).toLocaleString()}</TableCell>
                <TableCell>{helper.input_words}</TableCell>
                <TableCell>{helper.output_words}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AICallStatisCMSPage;
