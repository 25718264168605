import { useEffect, useState } from "react";
import { Button, Typography, Box, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  AllTypeActions,
  EAction,
  ETypeItem,
  IApplicationState,
  ICreateItem,
  IItems,
  ItemsSort,
  createQuestions,
  createTopics,
  deleteQuestion,
  deleteTopic,
  getListAspects,
  getListCategories,
  resetData,
  showMessage,
  updateQuestion,
  updateTopic,
} from "../../../store-redux";
import { IHandleAction } from "./interface";
import { styles } from "./styles";
import Swal from "sweetalert2";
import NoDataComponent from "../../../components/NoDataComponent";
import { MenuAction } from "../../../components/MenuAction";
import CreateEditItemModal, { IFormDataProps } from "../../../components/CreateEditItemModal";
import SortItem from "@/components/SortItem";
import { sortItemOrder } from "@/helpers";
import GuidedQuestionSearchAI from "./components/GuidedQuestionSearchAI";

interface ICategoryAspectPageProps {
  isPageQuestion?: boolean;
  onClickAspect?: (id: string) => void;
  onClickSearchAI?: () => void;
  showSearchAI?: boolean;
}

const CategoryAspectPage = ({
  isPageQuestion,
  onClickAspect,
  onClickSearchAI,
  showSearchAI,
}: ICategoryAspectPageProps) => {
  const dispatch = useDispatch();
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);
  const [selectedAspectId, setSelectedAspectId] = useState<string | null>(null);
  const [openModalCreate, setOpenModalCreate] = useState<{
    type: ETypeItem;
    open: boolean;
    data?: IItems;
  }>({
    type: ETypeItem.category,
    open: false,
  });

  const listCategories = useSelector((state: IApplicationState) => state.listCategories);
  const createTopicsResult = useSelector((state: IApplicationState) => state.createTopics);
  const listAspects = useSelector((state: IApplicationState) => state.listAspects);
  const createQuestionsResult = useSelector((state: IApplicationState) => state.createQuestions);
  const deleteTopicResult = useSelector((state: IApplicationState) => state.deleteTopic);
  const deleteQuestionResult = useSelector((state: IApplicationState) => state.deleteQuestion);
  const updateTopicResult = useSelector((state: IApplicationState) => state.updateTopic);
  const updateQuestionResult = useSelector((state: IApplicationState) => state.updateQuestion);

  // SORT CATEGORY
  const [listSortCategory, setListSortCategory] = useState<ItemsSort[]>([]);
  useEffect(() => {
    setListSortCategory(
      listCategories.data?.sort((a, b) => a.order - b.order)?.map((item) => ({ ...item, id: item._id })) ?? []
    );
  }, [listCategories.data]);

  // SORT ASPECT
  const [listSortAspect, setListSortAspect] = useState<ItemsSort[]>([]);
  useEffect(() => {
    setListSortAspect(sortItemOrder(listAspects.data)?.map((item) => ({ ...item, id: item._id })) ?? []);
  }, [listAspects.data]);

  const handleOpenCreate = (type: ETypeItem) => {
    setOpenModalCreate({
      type,
      open: true,
    });
  };

  const handleCloseModal = () => {
    setOpenModalCreate({
      type: ETypeItem.category,
      open: false,
    });
  };

  const handleCreateEdit = (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item) => ({
      name: item.title,
      description: item.description,
      type: openModalCreate.type,
      parentId: openModalCreate.type === ETypeItem.aspect ? selectedTopicId : "",
      public: !!item.public,
    }));
    if (openModalCreate.data) {
      if (openModalCreate.type === ETypeItem.category) {
        dispatch(updateTopic({ ...dataSend?.[0], id: openModalCreate.data._id }));
      } else {
        dispatch(updateQuestion({ ...dataSend?.[0], id: openModalCreate.data._id }));
      }
    } else {
      if (openModalCreate.type === ETypeItem.category) {
        dispatch(createTopics({ items: dataSend }));
      } else {
        dispatch(createQuestions({ items: dataSend }));
      }
    }
  };

  const handleTopicClick = (topic: IItems) => {
    setSelectedTopicId(topic._id);
    if (topic._id !== selectedTopicId) {
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          parentId: topic._id,
        })
      );
    }
  };

  const handleGetListCategories = () => {
    dispatch(
      getListCategories({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.category,
      })
    );
  };
  const handleGetListAspects = () => {
    dispatch(
      getListAspects({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        parentId: selectedTopicId,
        sortBy: "createdAt",
      })
    );
  };

  const handleAction = ({ type, typeAction, data }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === ETypeItem.category) {
            dispatch(deleteTopic(data._id));
          } else if (type === ETypeItem.aspect) {
            dispatch(deleteQuestion(data._id));
          }
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        type,
        open: true,
        data,
      });
    }
  };

  //GET LIST
  useEffect(() => {
    handleGetListCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CREATE
  useEffect(() => {
    if (createTopicsResult.data && !createTopicsResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_TOPICS));
      handleGetListCategories();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTopicsResult]);

  useEffect(() => {
    if (createQuestionsResult.data && !createQuestionsResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_QUESTIONS));
      handleGetListAspects();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuestionsResult]);

  // DELETE

  useEffect(() => {
    if (deleteTopicResult.data && !deleteTopicResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_TOPIC));
      handleGetListCategories();
      setSelectedTopicId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTopicResult]);
  useEffect(() => {
    if (deleteQuestionResult.data && !deleteQuestionResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_QUESTION));
      handleGetListAspects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuestionResult]);

  // UPDATE
  useEffect(() => {
    if (updateTopicResult.data && !updateTopicResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_TOPIC));
      handleGetListCategories();
      handleCloseModal();
      setSelectedTopicId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTopicResult]);
  useEffect(() => {
    if (updateQuestionResult.data && !updateQuestionResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_QUESTION));
      handleGetListAspects();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQuestionResult]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      })}
    >
      {listCategories.isFetching ? <LinearProgress color="secondary" style={{ marginRight: 10 }} /> : null}
      <Box sx={styles.boxContainer}>
        <Box className={showSearchAI ? "" : "flex-1"}>
          {selectedTopicId && (
            <Box
              sx={{
                // position: "absolute",
                // top: "10px",
                // right: "10px",
                backgroundColor: "#264653",
                color: "#fff",
                padding: "2px 4px",
                borderRadius: "10px",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "bold",
                width: "fit-content",
              }}
              onClick={() => {
                if (onClickSearchAI) onClickSearchAI();
              }}
            >
              Search AI
            </Box>
          )}
          <Typography variant="h3">Category</Typography>
          <Button
            variant="contained"
            onClick={() => handleOpenCreate(ETypeItem.category)}
            color="secondary"
            disabled={isPageQuestion}
          >
            Create Category
          </Button>
          <Box className="box-item">
            <SortItem
              listData={listCategories.data ?? []}
              listItem={listSortCategory}
              setListItem={setListSortCategory}
              isDisableSort={isPageQuestion}
            >
              <>
                {listSortCategory?.map((topic) => (
                  <Box
                    key={topic._id}
                    onClick={() => {
                      handleTopicClick(topic);
                      if (onClickAspect !== undefined) {
                        onClickAspect("");
                        setSelectedAspectId(null);
                      }
                    }}
                    sx={{
                      ...styles.styleItem,
                      backgroundColor: selectedTopicId === topic._id ? "lightblue" : "transparent",
                      "& .title": {
                        display: "flex",
                        "& .count": {
                          marginLeft: "5px",
                        },
                      },
                    }}
                  >
                    <Box
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html:
                          topic.name +
                            `${topic.childrenCount > 0 ? `<b class="count">(${topic.childrenCount})</b>` : ""}` || "",
                      }}
                    />
                    <Box className="description" dangerouslySetInnerHTML={{ __html: topic.description || "" }} />
                    {!isPageQuestion && (
                      <MenuAction
                        handleClick={(typeAction: EAction) => {
                          handleAction({
                            typeAction,
                            type: ETypeItem.category,
                            data: topic,
                          });
                        }}
                      />
                    )}
                  </Box>
                ))}
              </>
            </SortItem>
            {listCategories.data?.length === 0 && !listCategories.isFetching ? <NoDataComponent /> : null}
          </Box>
        </Box>
        {showSearchAI && selectedTopicId ? (
          <GuidedQuestionSearchAI selectedCategoryId={selectedTopicId} question={null} />
        ) : (
          <Box className="flex-1">
            <Typography variant="h3">Aspect</Typography>
            <Button
              variant="contained"
              onClick={() => {
                if (selectedTopicId) {
                  handleOpenCreate(ETypeItem.aspect);
                } else {
                  dispatch(showMessage("Please choose one category", "warning"));
                }
              }}
              color="secondary"
              disabled={isPageQuestion}
            >
              Create Aspect
            </Button>
            <Box className="box-item">
              <SortItem
                listData={listAspects.data ?? []}
                listItem={listSortAspect}
                setListItem={setListSortAspect}
                isDisableSort={isPageQuestion}
              >
                <>
                  {selectedTopicId &&
                    listSortAspect?.map((question) => (
                      <Box
                        key={question._id}
                        sx={{
                          ...styles.styleItem,
                          backgroundColor: selectedAspectId === question._id ? "lightblue" : "transparent",
                          "& .title": {
                            display: "flex",
                            "& .count": {
                              marginLeft: "5px",
                            },
                          },
                        }}
                        onClick={() => {
                          if (onClickAspect !== undefined) {
                            onClickAspect(question._id);
                            setSelectedAspectId(question._id);
                          }
                        }}
                      >
                        <Box
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html:
                              question.name +
                                `${
                                  question.childrenCount > 0 ? `<b class="count">(${question.childrenCount})</b>` : ""
                                }` || "",
                          }}
                        />
                        <Box
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: question.description || "",
                          }}
                        />
                        {!isPageQuestion && (
                          <MenuAction
                            handleClick={(typeAction: EAction) => {
                              handleAction({
                                typeAction,
                                type: ETypeItem.aspect,
                                data: question,
                              });
                            }}
                          />
                        )}
                      </Box>
                    ))}
                </>
              </SortItem>
              {listAspects.data?.length === 0 && !listAspects.isFetching ? <NoDataComponent /> : null}
            </Box>
          </Box>
        )}
      </Box>
      <CreateEditItemModal
        handleCreateEdit={handleCreateEdit}
        openModal={openModalCreate.open}
        handleCloseModal={handleCloseModal}
        isLoading={createTopicsResult.isFetching || createQuestionsResult.isFetching}
        titleModal={
          openModalCreate?.data
            ? openModalCreate.type === ETypeItem.category
              ? `Edit category`
              : `Edit aspect`
            : openModalCreate.type === ETypeItem.category
            ? `Create new categories`
            : `Create new aspects`
        }
        data={openModalCreate.data}
        showPublic={true}
      />
    </Box>
  );
};

export default CategoryAspectPage;
