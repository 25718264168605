import {
  ENDPOINTS,
  IChallengeEvaluateOutput,
  IChallengeTranscribeOutput,
  ISendAudioPractice,
  ISendAudioChallengeOuput,
  Requests,
  getApiUrl,
  IQuotaOutput,
  IMyPracticesOutput,
} from "@/common";

export const sendAudioPractice = async (payload: ISendAudioPractice): Promise<ISendAudioChallengeOuput> => {
  const apiUrl = getApiUrl(ENDPOINTS.POST_PRACTICES_UPLOAD);
  const data: any = await Requests.post(apiUrl, payload, true, false);
  return data;
};

export const postPracticeTranscribe = async (id: string): Promise<IChallengeTranscribeOutput> => {
  const apiUrl = getApiUrl(ENDPOINTS.POST_PRACTICES_TRANSCRIBE, { id }, true);
  const { data }: any = await Requests.post(apiUrl);
  return data;
};

export const postPracticeEvaluate = async (id: string): Promise<IChallengeEvaluateOutput> => {
  const apiUrl = getApiUrl(ENDPOINTS.POST_PRACTICES_EVALUATE, { id }, true);
  const { data }: any = await Requests.post(apiUrl);
  return data;
};

export const getQuotaPractice = async (): Promise<IQuotaOutput> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_QUOTA_PRACTICE);
  const { data }: any = await Requests.get(apiUrl);

  return data;
};

export const getMyPractices = async (): Promise<IMyPracticesOutput[]> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_MY_PRACTICE);
  const data: any = await Requests.get(apiUrl);
  const dataParse = data?.map((item: any) => {
    if (item?.result) {
      return { ...item, result: JSON.parse(item.result) };
    }
    return item;
  });
  return dataParse;
};
