import { AppDatePicker, AppSwitch, AppTextField } from "@/components/FormControls";
import { showMessage } from "@/store-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import "suneditor/dist/css/suneditor.min.css";
import { styles } from "./styles";
import { get } from "lodash";
import moment from "moment";

interface ModalAddMultipleUserProps {
  openModal: boolean;
  handleCreateMultiple: (data: IFormDataProps) => void;
  handleCloseModal: () => void;
  isLoading?: boolean;
}
export interface IFormDataProps {
  users: {
    username: string;
    email: string;
    active: boolean;
    expiredTime: string | null;
  }[];
}

function ModalAddMultipleUser(props: ModalAddMultipleUserProps) {
  const dispatch = useDispatch();
  const { openModal, handleCreateMultiple, handleCloseModal, isLoading = false } = props;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormDataProps>({
    defaultValues: {
      users: [
        {
          username: "",
          email: "",
          active: true,
          expiredTime: null,
        },
      ],
    },
  });

  const onSubmit = (data: IFormDataProps) => {
    const dataPush = data?.users?.map((item) => ({
      ...item,
      expiredTime: item?.expiredTime ? moment(item.expiredTime).format() : item.expiredTime,
    }));
    handleCreateMultiple({
      users: dataPush,
    });
  };

  const { fields, append, remove } = useFieldArray({
    name: "users",
    control,
  });

  const onCloseModal = () => {
    handleCloseModal();
    reset({
      users: [
        {
          username: "",
          email: "",
          active: true,
        },
      ],
    });
  };

  const pasteClipboard = async () => {
    try {
      const clipboardContents = await navigator.clipboard?.readText();
      const hasTabAndReturn =
        clipboardContents?.includes("\t") && clipboardContents?.includes("\r") && clipboardContents?.includes("@");
      if (hasTabAndReturn) {
        const tempVaue = clipboardContents?.split("\n")?.map((item) => {
          const tempSplit = item.replace("\r", "").split("\t");
          return {
            username: tempSplit?.[0],
            email: tempSplit?.[1],
            active: true,
          };
        });
        reset({
          users: tempVaue,
        });
      } else {
        dispatch(showMessage("Please copy true format", "warning"));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal open={openModal} onClose={onCloseModal} sx={styles.modalContainer}>
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <Box className="header">
          <Typography component={"h3"}>Create multiple users</Typography>
          <IconButton className="icon-close" onClick={onCloseModal}>
            <CloseIcon color="success" />
          </IconButton>
        </Box>
        <Box className="sub-header">
          <Typography component={"h4"} className="flex-2">
            Username
          </Typography>
          <Typography component={"h4"} className="flex-2">
            Email
          </Typography>
          <Typography component={"h4"} className="flex-2">
            Expired Date
          </Typography>
          <Typography component={"h4"} className="flex-1">
            Status
          </Typography>
          <Box className="flex-1"></Box>
        </Box>
        <Box className="body_box">
          {fields.map((field, index) => {
            return (
              <section className={"section"} key={field.id}>
                <AppTextField
                  control={control}
                  name={`users.${index}.username`}
                  rules={{ required: true }}
                  className="flex-2"
                  error={Boolean(get(errors, `users.${index}.username`))}
                />
                <AppTextField
                  control={control}
                  name={`users.${index}.email`}
                  rules={{ required: true }}
                  className="flex-2"
                  type="email"
                  error={Boolean(get(errors, `users.${index}.email`))}
                />
                <AppDatePicker control={control} name={`users.${index}.expiredTime`} rules={{}} className="flex-2" />
                <Box className="flex-1">
                  <AppSwitch
                    control={control}
                    name={`users.${index}.active`}
                    rules={{ required: true }}
                    defaultChecked
                    color="secondary"
                  />
                </Box>
                {index !== 0 ? (
                  <Box className="icon-delete flex-1">
                    <IconButton onClick={() => remove(index)}>
                      <RemoveCircleOutlineIcon color="warning" />
                    </IconButton>
                  </Box>
                ) : (
                  <Box className="icon-delete flex-1"></Box>
                )}
              </section>
            );
          })}
        </Box>
        <Box className="list-btn">
          <Button
            onClick={pasteClipboard}
            variant="text"
            sx={{
              textTransform: "none",
            }}
          >
            Pase clipboard
          </Button>

          <IconButton
            type="button"
            onClick={() =>
              append({
                username: "",
                email: "",
                active: true,
                expiredTime: null,
              })
            }
          >
            <AddCircleOutlineIcon />
          </IconButton>

          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={30} /> : "Save"}
          </Button>
        </Box>
      </form>
    </Modal>
  );
}

export default ModalAddMultipleUser;
