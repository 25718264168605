import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCurrentUser, getRemainingEnergy } from "../../store-redux";

import { Box } from "@mui/material";
import { KeyTokenName } from "../../constants";

interface IAuthorizationProps {
  children: JSX.Element;
}

function Authorization({ children }: IAuthorizationProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(KeyTokenName);
    if (token) {
      dispatch(getCurrentUser());
      dispatch(getRemainingEnergy());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box>{children}</Box>;
}

export default Authorization;
