import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AIModels, AllTypeActions, IApplicationState, generateIdeas, resetData } from "../../../../store-redux";
import { refinePrompt } from "../../../../helpers";

interface IFormGenerateIdeaProps {
  aspectContent: string;
  questionContent: string;
  questionId: string;
  aspectId: string;
  handleGetListIdeas: () => void;
}

interface IFormData {
  content: string;
  numberOfIdeas: string;
  aiModel: AIModels;
}

const FormGenerateIdea = memo(
  ({ aspectContent, questionContent, questionId, aspectId, handleGetListIdeas }: IFormGenerateIdeaProps) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, watch } = useForm<IFormData>({
      defaultValues: {
        content: "",
        aiModel: AIModels["claude-3-opus-20240229"],
      },
    });
    const generateIdeasResult = useSelector((state: IApplicationState) => state.generateIdeas);

    const onSubmit = (data: IFormData) => {
      dispatch(
        generateIdeas({
          questionId,
          aspectId,
          prompt: data.content,
          creativity: 0.9,
          aiModel: data.aiModel,
          numberOfIdeas: data.numberOfIdeas ? Number(data.numberOfIdeas) : 10,
          question: refinePrompt(questionContent),
        })
      );
    };
    useEffect(() => {
      if (aspectContent && questionContent) {
        const aspectContentRemove = refinePrompt(aspectContent);
        const questionContentRemove = refinePrompt(questionContent);
        const content = `List out some ${aspectContentRemove} of students or people that affect`;
        reset({
          content,
          numberOfIdeas: "10",
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aspectContent, questionContent]);

    useEffect(() => {
      if (!generateIdeasResult.isFetching && !generateIdeasResult.error && generateIdeasResult.data) {
        handleGetListIdeas();
        dispatch(resetData(AllTypeActions.GENERATE_IDEAS));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateIdeasResult]);

    return (
      <form onSubmit={handleSubmit(onSubmit)} className="form-generate-idea">
        <TextField type="text" multiline rows={3} {...register("content", { required: true })} />
        <Box>
          <TextField label="Number of Ideas" {...register("numberOfIdeas", { required: true })} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControl
              fullWidth
              sx={{
                width: "240px",
              }}
            >
              <InputLabel>AI Model</InputLabel>
              <Select
                label="AI Model"
                {...register("aiModel", { required: true })}
                defaultValue={AIModels["claude-3-opus-20240229"]}
              >
                <MenuItem value={AIModels["claude-3-opus-20240229"]}>{AIModels["claude-3-opus-20240229"]}</MenuItem>
                <MenuItem value={AIModels["claude-3-5-sonnet-20240620"]}>
                  {AIModels["claude-3-5-sonnet-20240620"]}
                </MenuItem>
              </Select>
            </FormControl>
            <Box className="icon-submit">
              <IconButton type="submit" disabled={generateIdeasResult.isFetching}>
                {generateIdeasResult.isFetching ? <CircularProgress size={30} /> : <SendIcon color="secondary" />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </form>
    );
  }
);

export { FormGenerateIdea };
