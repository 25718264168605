import configs from "@/configs.json";

export const changeFileName = (file: File, newName: string): File => {
  return new File([file], newName, { type: file.type });
};

export const getFileExtension = (file: File): string => {
  const fileName: string = file.name;
  const fileNameParts: string[] = fileName.split(".");
  const extension: string = fileNameParts[fileNameParts.length - 1];
  return extension;
};

export const getUrlServer = (path: string) => {
  if (path.includes("res.cloudinary.com")) return path;
  return `${import.meta.env.VITE_API_BASE || configs.apiBaseDev}/${path}`;
};

export const jsonToFile = (jsonData: any, fileName = "aspect.json", mimeType = "application/json") => {
  const jsonString = JSON.stringify(jsonData, null, 2);
  const blob = new Blob([jsonString], { type: mimeType });
  const file = new File([blob], fileName, { type: mimeType });
  return file;
};
