import { AiBoxTab, EChangeLengthAnswer, RephrasingBand } from "@/common/types";
import {
  useGeneratorConnector,
  useGetQuota,
  useGrammarAnalysis,
  useRephraseAnswer,
  useWordLimitAnswer,
} from "@/services-react-query/queries";
import { IApplicationState, editCustomAnswer, getRemainingEnergy } from "@/store-redux";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddConnector from "./AddConnector";
import { GrammarAnalysisModal } from "./GrammarAnalysisModal";
import { RepharseAnswerPopover } from "./RepharseAnswerPopover";
import { WordLimitAnswerPopover } from "./WordLimitAnswerPopover";
import { useState } from "react";

interface ITabAssistantAIProps {
  isPartTwo?: boolean;
  contentGeneratorConnector: string;
  setContentGeneratorConnector: React.Dispatch<React.SetStateAction<string>>;
  setBoxTabActive: React.Dispatch<React.SetStateAction<AiBoxTab | null>>;
  question?: string;
}

function TabAssistantAI(props: ITabAssistantAIProps) {
  const { contentGeneratorConnector, setContentGeneratorConnector, setBoxTabActive } = props;
  const dispatch = useDispatch();
  // const { data: dataQuota, refetch } = useGetQuota({});
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const isHasContentEdit = Boolean(editCustomAnswerContent?.data && editCustomAnswerContent?.data?.trim());

  const { mutate: generateConnector, isLoading: generateConnectorLoading } = useGeneratorConnector({
    onSuccess(data) {
      setContentGeneratorConnector(data);
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });
  const { mutate: repharse, isLoading: repharseLoading } = useRephraseAnswer({
    onSuccess(data) {
      setContentGeneratorConnector(data);
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });
  const { mutate: wordLimitAnswer, isLoading: wordLimitLoading } = useWordLimitAnswer({
    onSuccess(data) {
      setContentGeneratorConnector(data);
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });

  const [dataGrammarAnalysis, setDataGrammarAnalysis] = useState({
    open: false,
    data: "",
  });

  const { mutate: grammarAnalysis, isLoading: grammarAnalysisLoading } = useGrammarAnalysis({
    onSuccess(data) {
      setDataGrammarAnalysis({
        open: true,
        data,
      });
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });

  const onGenerateConnector = () => {
    if (editCustomAnswerContent.data?.trim()) {
      generateConnector({ sentence: editCustomAnswerContent.data, question: props.question ?? "" });
      setBoxTabActive(AiBoxTab.contentGeneratorConnector);
    }
  };

  const onRephraseAnswer = (level: string) => {
    repharse({ sentence: editCustomAnswerContent.data, band: level as RephrasingBand, question: props.question ?? "" });
    setBoxTabActive(AiBoxTab.contentGeneratorConnector);
  };
  const onWordLimit = (type: EChangeLengthAnswer) => {
    wordLimitAnswer({ sentence: editCustomAnswerContent.data, expectedLength: type, question: props.question ?? "" });
    setBoxTabActive(AiBoxTab.contentGeneratorConnector);
  };

  if (generateConnectorLoading || repharseLoading || wordLimitLoading || grammarAnalysisLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          "& div": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Box>
          <img src="/images/robot-writing.gif" />
        </Box>
        <Typography
          component={"span"}
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          Your request is being processed by the system. Please wait a moment...
        </Typography>
        <LinearProgress color="info" />
      </Box>
    );
  }

  // if (dataQuota?.week_count === dataQuota?.week_max) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         flexDirection: "column",
  //         textAlign: "center",
  //         "& div": {
  //           display: "flex",
  //           justifyContent: "center",
  //         },
  //       }}
  //     >
  //       <Typography
  //         component={"span"}
  //         sx={{
  //           marginTop: "16px",
  //           marginBottom: "16px",
  //           color: "red",
  //           fontSize: "20px",
  //         }}
  //       >
  //         Your quote is limited. Please comeback next week
  //       </Typography>
  //     </Box>
  //   );
  // }

  return contentGeneratorConnector ? (
    <Box sx={{ marginTop: "10px", position: "relative" }}>
      <Box
        sx={{
          textAlign: "justify",
          paddingRight: "5px",
          fontSize: "18px",
        }}
      >
        {contentGeneratorConnector?.slice(
          contentGeneratorConnector.indexOf("(") === -1 ? 0 : contentGeneratorConnector.indexOf("(")
        )}
      </Box>
      <Box sx={{ marginBlock: "15px", display: "flex", justifyContent: "center", gap: "20px" }}>
        <Button
          onClick={() => {
            dispatch(editCustomAnswer(contentGeneratorConnector));
            setTimeout(() => {
              setContentGeneratorConnector("");
            }, 100);
          }}
          variant="outlined"
          color="success"
        >
          Accept
        </Button>
        <Button
          onClick={() => {
            setContentGeneratorConnector("");
          }}
          variant="outlined"
          color="warning"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "20px",
        "& .card-ai": {
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: "16px",
          cursor: "pointer",
          ...(isHasContentEdit ? {} : { backgroundColor: "#e1e1e1", cursor: "default", pointerEvents: "none" }),
        },
      }}
    >
      {!props.isPartTwo && <AddConnector onGenerateConnector={onGenerateConnector} />}
      <RepharseAnswerPopover onRephraseAnswer={onRephraseAnswer} />
      <WordLimitAnswerPopover onWordLimit={onWordLimit} />
      <GrammarAnalysisModal
        grammarAnalysis={grammarAnalysis}
        setDataGrammarAnalysis={setDataGrammarAnalysis}
        dataGrammarAnalysis={dataGrammarAnalysis}
      />
    </Box>
  );
}

export default TabAssistantAI;
