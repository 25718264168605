import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _ from "lodash";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
const Input: React.FC<{ init: string; onChange: (value: string) => void }> = ({ init, onChange }) => {
  const [value, setValue] = useState(init);

  return (
    <TextField
      autoFocus
      multiline
      maxRows={2}
      sx={{
        "& input": {
          fontSize: "14px",
        },
        "& textarea": {
          fontSize: "14px",
        },
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="outlined"
      size="small"
      fullWidth
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      onBlur={() => onChange(value)}
    />
  );
};

export const CellName = ({ name, onSave }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState("");

  return (
    <Box
      onClick={(event) => event.stopPropagation()}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {isEdit ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Input
            init={name}
            onChange={(value) => {
              setValue(value);
            }}
          />
          <IconButton
            size="small"
            onClick={() => {
              onSave(value);
              setIsEdit(false);
              setValue("");
            }}
          >
            <SaveIcon fontSize={"small"} />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setIsEdit(false);
              setValue("");
            }}
          >
            <CancelIcon fontSize={"small"} />
          </IconButton>
        </Box>
      ) : (
        <Tooltip title="Please click to edit text">
          <Box
            sx={{
              height: "40px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setIsEdit(true);
            }}
          >
            {name}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
const AspectDetail = (props) => {
  const { item, onBack, onSave } = props;
  const [data, setData] = useState({
    ...item,
    ...(!Array.isArray(item.children) ? { children: [] } : {}),
  });
  const [childActive, setChildActive] = useState(null);
  const [indexExamplesChecked, setIndexExampleChecked] = useState([]);
  const [childSelectedMove, setChildSelectedMove] = useState(null);
  const [editS, setEditS] = useState(false);
  const [childNameVal, setChildNameVal] = useState("");

  const [childEl, setChildEl] = useState(null);
  const [childAction, setChildAction] = useState(null);

  const [itemEl, setItemEl] = useState(null);
  const [itemAction, setItemAction] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);

  const onAddChild = () => {
    setData((prev) => ({
      ...prev,
      children: [
        {
          id: Date.now(),
          name: {
            en: `Group ${prev.children.length + 1}`,
            vi: `Nhóm ${prev.children.length + 1}`,
          },
          items: [],
          // isPublic: true,
        },
        ...prev.children,
      ],
    }));
  };

  const onToggleCheck = (index) => {
    setIndexExampleChecked((prev) => (prev.includes(index) ? prev.filter((item) => item !== index) : [...prev, index]));
  };

  useEffect(() => {
    setIndexExampleChecked([]);
    setEditS(false);
  }, [childActive]);

  const listItems = useMemo(() => {
    const child = data.children.find((item) => item.id === childActive?.id);
    if (child) return child.items;
    return data.items;
  }, [childActive, data]);

  const onMoveExample = () => {
    if (!childSelectedMove) return;
    // console.log(indexExamplesChecked, childSelectedMove);
    const moveExamples = listItems.filter((item, index) => indexExamplesChecked.includes(index));
    if (childActive === null) {
      setData((prev) => ({
        ...prev,
        items: prev.items.filter((item, index) => !indexExamplesChecked.includes(index)),
        children: prev.children.map((item) =>
          item.id === childSelectedMove.id
            ? {
                ...item,
                items: [...moveExamples, ...item.items],
              }
            : item
        ),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        items: childSelectedMove.id === null ? [...moveExamples, prev.items] : prev.items,
        children: prev.children.map((item) =>
          item.id === childActive?.id
            ? {
                ...item,
                items: item.items.filter((item, index) => !indexExamplesChecked.includes(index)),
              }
            : item.id === childSelectedMove.id
            ? {
                ...item,
                items: [...moveExamples, ...item.items],
              }
            : item
        ),
      }));
    }

    setIndexExampleChecked([]);
  };

  const isChange = useMemo(() => {
    return _.isEqual(
      {
        ...item,
        ...(!Array.isArray(item.children) ? { children: [] } : {}),
      },
      data
    );
  }, [data]);

  const onCancel = () => {
    setData({
      ...item,
      ...(!Array.isArray(item.children) ? { children: [] } : {}),
    });
    setChildActive(null);
    setIndexExampleChecked([]);
    setChildSelectedMove(null);
  };

  const moveChildItem = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= data.children.length) {
      return;
    }

    const updatedRows = [...data.children];
    const [movedItem] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedItem);

    setData((prev) => ({
      ...prev,
      children: updatedRows,
    }));
  };

  const moveItem = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= listItems.length) {
      return;
    }
    const updatedRows = [...listItems];
    const [movedItem] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedItem);

    if (childActive === null) {
      setData((prev) => ({
        ...prev,
        items: updatedRows,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        children: prev.children.map((child) =>
          child.id === childActive.id
            ? {
                ...child,
                items: updatedRows,
              }
            : child
        ),
      }));
    }
  };

  const onAddItem = () => {
    const initItem = {
      code: Date.now(),
      name: {
        en: "",
        vi: "",
      },
    };
    if (childActive === null) {
      setData((prev) => ({
        ...prev,
        items: [initItem, ...prev.items],
      }));
    } else {
      setData((prev) => ({
        ...prev,
        children: prev.children.map((child) =>
          child.id === childActive.id
            ? {
                ...child,
                items: [initItem, ...child.items],
              }
            : child
        ),
      }));
    }
  };

  const onDeleteAll = () => {
    if (indexExamplesChecked.length) {
      if (childActive === null) {
        setData((prev) => ({
          ...prev,
          items: prev.items.filter((itemP, indexP) => !indexExamplesChecked.includes(indexP)),
        }));
      } else {
        setData((prev) => ({
          ...prev,
          children: prev.children.map((child) =>
            child.id === childActive.id
              ? {
                  ...child,
                  items: child.items.filter((itemP, indexP) => !indexExamplesChecked.includes(indexP)),
                }
              : child
          ),
        }));
      }
      setIndexExampleChecked([]);
    }
  };
  const menuChildAction = [
    {
      icon: ArrowUpwardIcon,
      text: "First up",
      onClick: () => {
        const index = data.children.findIndex((item) => item.id === childAction.id);
        if (index > 0) moveChildItem(index, 0);
      },
    },
    {
      icon: ArrowUpwardIcon,
      text: "Up",
      onClick: () => {
        const index = data.children.findIndex((item) => item.id === childAction.id);
        if (index > 0) moveChildItem(index, index - 1);
      },
    },
    {
      icon: ArrowDownwardIcon,
      text: "Down",
      onClick: () => {
        const index = data.children.findIndex((item) => item.id === childAction.id);
        if (index < data.children.length - 1) moveChildItem(index, index + 1);
      },
    },
    {
      icon: ArrowDownwardIcon,
      text: "Last down",
      onClick: () => {
        const index = data.children.findIndex((item) => item.id === childAction.id);
        if (index < data.children.length - 1) moveChildItem(index, data.children.length - 1);
      },
    },
    {
      icon: DeleteIcon,
      text: "Delete",
      onClick: () => {
        setData((prev) => ({
          ...prev,
          items: [...childAction?.items, ...prev.items],
          children: prev.children.filter((item) => item.id !== childAction?.id),
        }));
        setChildAction(null);
        setChildEl(null);
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "& td": {
          padding: "4px!important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <Button variant="contained" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Box>
          <Typography sx={{ fontWeight: "700", textAlign: "center" }}>
            {data.name?.en ?? ""} ({data.name?.vi ?? ""})
          </Typography>
        </Box>
        <Box>
          {!isChange && (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#f1f1f1",
                  color: "#000",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onSave(data);
                }}
              >
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "10px",
          height: "calc(100% - 60px)",
        }}
      >
        <Box
          sx={{
            width: openSidebar ? "480px" : "100px",
            paddingRight: "10px",
          }}
        >
          <TableContainer component={Paper} sx={{ height: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={openSidebar ? 4 : 1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {openSidebar && (
                        <Typography
                          sx={{
                            fontWeight: "600",
                            flex: 1,
                          }}
                        >
                          Childs
                        </Typography>
                      )}
                      <IconButton onClick={() => setOpenSidebar((prev) => !prev)}>
                        {openSidebar ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={openSidebar ? 4 : 1}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      ...(childActive === null
                        ? {
                            backgroundColor: "#264653",
                            "& span,div": {
                              color: "#fff",
                              fontWeight: "bold",
                            },
                            "& svg": {
                              fill: "#fff",
                            },
                            "&:hover": {
                              backgroundColor: "#264653",
                            },
                          }
                        : {}),
                    }}
                    onClick={() => {
                      setChildActive(null);
                    }}
                  >
                    <span>General</span>
                    <Typography
                      component={"div"}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontStyle: "italic",
                      }}
                    >
                      ({data.items.length} items)
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={openSidebar ? 4 : 1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        onAddChild();
                      }}
                      sx={{
                        ...(!openSidebar
                          ? {
                              "& span": {
                                margin: 0,
                              },
                            }
                          : {}),
                      }}
                    >
                      {openSidebar ? "child" : ""}
                    </Button>
                  </TableCell>
                </TableRow>
                {data.children.map((child) => {
                  return (
                    <TableRow
                      key={child.id}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        ...(childActive?.id === child.id
                          ? {
                              backgroundColor: "#264653",
                              "&:hover": {
                                backgroundColor: "#264653",
                              },
                              "& span,div": {
                                color: "#fff",
                                fontWeight: "bold",
                              },
                              "& svg": {
                                fill: "#fff",
                              },
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setChildActive(child);
                      }}
                    >
                      {openSidebar ? (
                        <>
                          <TableCell
                            sx={{
                              width: "30px",
                            }}
                          >
                            <IconButton>
                              {childActive?.id === child.id ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <CellName
                              name={child.name.en}
                              onSave={(value) => {
                                setData((prev) => ({
                                  ...prev,
                                  children: prev.children.map((itemC) =>
                                    itemC.id === child.id
                                      ? {
                                          ...itemC,
                                          name: {
                                            ...itemC.name,
                                            en: value,
                                          },
                                        }
                                      : itemC
                                  ),
                                }));
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <CellName
                              name={child.name.vi}
                              onSave={(value) => {
                                setData((prev) => ({
                                  ...prev,
                                  children: prev.children.map((itemC) =>
                                    itemC.id === child.id
                                      ? {
                                          ...itemC,
                                          name: {
                                            ...itemC.name,
                                            vi: value,
                                          },
                                        }
                                      : itemC
                                  ),
                                }));
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "10px",
                            }}
                          >
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                setChildEl(event.currentTarget);
                                setChildAction(child);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            <Box>{child.name.en}</Box>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: "10px",
          }}
        >
          <TableContainer component={Paper} sx={{ height: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Items
                  </TableCell>
                  <TableCell colSpan={2}>
                    {indexExamplesChecked.length ? (
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          options={[
                            {
                              id: null,
                              name: {
                                en: "General",
                                vi: "Chung",
                              },
                            },
                            ...data.children,
                          ].filter((item) => item.id !== childActive)}
                          sx={{ width: 300 }}
                          getOptionLabel={(option) => `${option.name.en} (${option.name.vi})`}
                          renderInput={(params) => <TextField {...params} label="Child" />}
                          onChange={(event, value) => {
                            setChildSelectedMove(value);
                          }}
                        />
                        {!!childSelectedMove && (
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                              marginLeft: "10px",
                              textTransform: "initial",
                            }}
                            onClick={() => onMoveExample()}
                          >
                            Move
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <Box>Please selected example to move to child</Box>
                    )}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => onAddItem()}>
                        Item
                      </Button>
                      {indexExamplesChecked.length > 0 && (
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{
                            marginLeft: "10px",
                            textTransform: "initial",
                          }}
                          onClick={() => onDeleteAll()}
                        >
                          Delete all
                        </Button>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listItems.map((item, index) => {
                  return (
                    <TableRow
                      onClick={() => {
                        onToggleCheck(index);
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <TableCell>
                        <IconButton>
                          {indexExamplesChecked.includes(index) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <CellName
                          name={item.name?.en}
                          onSave={(value) => {
                            if (childActive === null) {
                              setData((prev) => ({
                                ...prev,
                                items: prev.items.map((itemP) =>
                                  itemP.code === item.code
                                    ? {
                                        ...itemP,
                                        name: {
                                          ...itemP.name,
                                          en: value,
                                        },
                                      }
                                    : itemP
                                ),
                              }));
                            } else {
                              setData((prev) => ({
                                ...prev,
                                children: prev.children.map((child) =>
                                  child.id === childActive?.id
                                    ? {
                                        ...child,
                                        items: child.items.map((itemP) =>
                                          itemP.code === item.code
                                            ? {
                                                ...itemP,
                                                name: {
                                                  ...itemP.name,
                                                  en: value,
                                                },
                                              }
                                            : itemP
                                        ),
                                      }
                                    : child
                                ),
                              }));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CellName
                          name={item.name?.vi}
                          onSave={(value) => {
                            if (childActive === null) {
                              setData((prev) => ({
                                ...prev,
                                items: prev.items.map((itemP) =>
                                  itemP.code === item.code
                                    ? {
                                        ...itemP,
                                        name: {
                                          ...itemP.name,
                                          vi: value,
                                        },
                                      }
                                    : itemP
                                ),
                              }));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "& button": {
                              margin: "0 2px",
                            },
                          }}
                        >
                          <Button
                            disabled={index === 0}
                            startIcon={<ArrowUpwardIcon />}
                            onClick={() => {
                              const index = listItems.findIndex((itemP) => itemP.code === item.code);
                              if (index > 0) moveItem(index, 0);
                            }}
                            sx={{
                              width: "60px",
                              textTransform: "unset",
                              border: "1px solid #e1e1e1",
                            }}
                          >
                            First
                          </Button>
                          <Tooltip title="Up">
                            <IconButton
                              disabled={index === 0}
                              onClick={() => {
                                const index = listItems.findIndex((itemP) => itemP.code === item.code);
                                if (index > 0) moveItem(index, index - 1);
                              }}
                            >
                              <ArrowUpwardIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Down">
                            <IconButton
                              disabled={index === listItems.length - 1}
                              onClick={() => {
                                const index = listItems.findIndex((itemP) => itemP.code === item.code);
                                if (index < listItems.length - 1) moveItem(index, index + 1);
                              }}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                          </Tooltip>
                          <Button
                            disabled={index === listItems.length - 1}
                            onClick={() => {
                              const index = listItems.findIndex((itemP) => itemP.code === item.code);
                              if (index < listItems.length - 1) moveItem(index, listItems.length - 1);
                            }}
                            endIcon={<ArrowDownwardIcon />}
                            sx={{
                              width: "60px",
                              textTransform: "unset",
                              border: "1px solid #e1e1e1",
                            }}
                          >
                            Last
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            if (childActive === null) {
                              setData((prev) => ({
                                ...prev,
                                items: prev.items.filter((itemP) => itemP.code !== item.code),
                              }));
                            } else {
                              setData((prev) => ({
                                ...prev,
                                children: prev.children.map((child) =>
                                  child.id === childActive.id
                                    ? {
                                        ...child,
                                        items: child.items.filter((itemP) => itemP.code !== item.code),
                                      }
                                    : child
                                ),
                              }));
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Popover
        open={!!childEl}
        anchorEl={childEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setChildEl(null);
          setChildAction(null);
        }}
      >
        <Box>
          {menuChildAction.map((item, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
                onClick={() => {
                  if (typeof item.onClick === "function") {
                    setChildEl(null);
                    item.onClick();
                    setChildAction(null);
                  }
                }}
              >
                {<item.icon />}
                <Typography
                  sx={{
                    marginLeft: "5px",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </Box>
  );
};

export default AspectDetail;
