import NoDataComponent from "@/components/NoDataComponent";
import { extractContent, removeLeadingNumbersAndPeriods } from "@/helpers";
import { ETypeItem, getListQuestionCategory, getListQuestionType, IApplicationState } from "@/store-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
interface IChooseQuestionTypeTabProps {
  chooseQuestionType: (value: string | null) => void;
}
const ChooseQuestionTypeTab = ({ chooseQuestionType }: IChooseQuestionTypeTabProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const listQuestionCategory = useSelector((state: IApplicationState) => state.listQuestionCategory);
  const [questionCategorySelectedId, setQuestionCategorySelectedId] = useState<string | null>(null);
  const listQuestionType = useSelector((state: IApplicationState) => state.listQuestionType);
  const [questionTypeSelectedId, setQuestionTypeSelectedId] = useState<string | null>(null);
  const handleGetListCategory = () => {
    dispatch(
      getListQuestionCategory({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem["question.category"],
      })
    );
  };

  const handleGetListQuestionType = () => {
    if (!questionCategorySelectedId) return;
    dispatch(
      getListQuestionType({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem["question.type"],
        parentId: questionCategorySelectedId,
      })
    );
  };
  useEffect(() => {
    setQuestionTypeSelectedId(null);
    if (listQuestionType.data?.length && listQuestionType.data?.[0]?.parentId === questionCategorySelectedId) return;
    handleGetListQuestionType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionCategorySelectedId]);

  useEffect(() => {
    chooseQuestionType(questionTypeSelectedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTypeSelectedId]);

  useEffect(() => {
    if (listQuestionCategory.data?.length) return;
    handleGetListCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        "& .box-container": {
          overflowY: "auto",
          marginTop: "10px",
          paddingInline: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          height: "calc(100vh - 260px)",
          " .title": {
            fontSize: "14px",
          },
          " .image": {
            height: "100px",
            width: "auto",
            objectFit: "cover",
            borderRadius: "8px",
          },
        },
        "& .box-item": {
          padding: "5px 10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "1px solid #D9D9D9",
          borderRadius: "8px",
          margin: "5px",
          "&.active": {
            border: `1px solid ${theme.palette.primary.main}`,
            " .title": {
              color: theme.palette.primary.main,
              fontWeight: "bold",
            },
          },
          "&:hover": {
            backgroundColor: "#ECEAEA",
            cursor: "pointer",
            border: `1px solid ${theme.palette.primary.main}`,
            " .title": {
              color: theme.palette.primary.main,
              fontWeight: "bold",
            },
          },
        },
      }}
    >
      <Box className="box-container">
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          Choose Question Category
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: '-5px',
          }}
        >
          {listQuestionCategory.data?.map((questionCategory) => (
            <Box
              key={questionCategory._id}
              onClick={() => setQuestionCategorySelectedId(questionCategory._id)}
              className={`box-item ${questionCategorySelectedId === questionCategory._id ? "active" : ""}`}
            >
              <Box className="title">{removeLeadingNumbersAndPeriods(extractContent(questionCategory.name))}</Box>
            </Box>
          ))}
        </Box>
        {listQuestionCategory.data?.length === 0 && !listQuestionCategory.isFetching ? <NoDataComponent /> : null}
        {questionCategorySelectedId && (
          <>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              Choose Question Type
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: '-5px',
              }}
            >
              {listQuestionType.data?.map((questionType) => (
                <Box
                  key={questionType._id}
                  onClick={() => setQuestionTypeSelectedId(questionType._id)}
                  className={`box-item ${questionTypeSelectedId === questionType._id ? "active" : ""}`}
                >
                  <Box className="title">{removeLeadingNumbersAndPeriods(extractContent(questionType.name))}</Box>
                </Box>
              ))}
            </Box>
            {listQuestionType.data?.length === 0 && !listQuestionType.isFetching ? <NoDataComponent /> : null}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChooseQuestionTypeTab;
