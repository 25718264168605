import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress } from "@mui/material";
import { forEach, isArray } from "lodash";
import { useEffect, useState } from "react";
import {
  AllTypeActions,
  EAction,
  ERole,
  ETypeItem,
  IApplicationState,
  IItems,
  generateGrammars,
  generateStructures,
  resetData,
} from "../../../../store-redux";
import SortIdeas from "./SortIdeas";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ModalPromptStructure from "./ModalPromptStructure";

export interface IHandleAction {
  typeAction: EAction;
  data: IItems;
}

interface IListIdeasProps {
  listData: IItems[];
  handleAction: (data: IHandleAction) => void;
  setOpenModalCreate: (
    value: React.SetStateAction<{
      open: boolean;
      data?: IItems | undefined;
    }>
  ) => void;
  showSelect: boolean;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  aspectId: string;
  aspectContent: string;
  handleGetListIdeas: () => void;
}

function ListIdeas(props: IListIdeasProps) {
  const dispatch = useDispatch();
  const { id: idQuestion } = useParams();
  const generateGrammarsResult = useSelector((state: IApplicationState) => state.generateGrammars);
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const generateStructuresResult = useSelector((state: IApplicationState) => state.generateStructures);
  const { listData, aspectId, aspectContent, handleGetListIdeas, ...rest } = props;
  const [formatedData, setFormatedData] = useState<{ [key: string]: IItems[] }>({});
  const [openModalPromptStructure, setOpenModalPromptStructure] = useState(false);

  const generateGrammarStructure = (type: string) => {
    if (type === "idea.grammar") {
      dispatch(
        generateGrammars({
          questionId: `${idQuestion}`,
          aspectId,
        })
      );
    } else if (type === "idea.structure") {
      setOpenModalPromptStructure(true);
    }
  };

  const handleSendGenerateStructure = (data: string) => {
    setOpenModalPromptStructure(false);
    dispatch(
      generateStructures({
        questionId: `${idQuestion}`,
        aspectId,
        prompt: data,
      })
    );
  };

  useEffect(() => {
    const dataResult: { [key: string]: IItems[] } = {
      [ETypeItem["idea.main"]]: [],
      [ETypeItem["idea.grammar"]]: [],
      [ETypeItem["idea.structure"]]: [],
    };
    forEach(listData, (val) => {
      if (isArray(dataResult[val.type])) {
        dataResult[val.type].push(val);
      } else {
        dataResult[val.type] = [val];
      }
    });
    setFormatedData(dataResult);
  }, [listData]);

  useEffect(() => {
    if (!generateGrammarsResult.isFetching && !generateGrammarsResult.error && generateGrammarsResult.data) {
      handleGetListIdeas();
      dispatch(resetData(AllTypeActions.GENERATE_GRAMMARS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateGrammarsResult]);

  useEffect(() => {
    if (!generateStructuresResult.isFetching && !generateStructuresResult.error && generateStructuresResult.data) {
      handleGetListIdeas();
      dispatch(resetData(AllTypeActions.GENERATE_STRUCTURES));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateStructuresResult]);

  return (
    <Box>
      {Object.keys(formatedData).map((key) => {
        return (
          <Accordion
            sx={{
              "& .MuiAccordionSummary-content": {
                textTransform: "capitalize",
                fontWeight: "bold",
                justifyContent: "space-between",
                alignItems: "center",
                "& button": {
                  marginBottom: "0",
                },
              },
            }}
            key={key}
            defaultExpanded={false}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              {key.replace("idea.", "").replace("main", "idea")} ({formatedData[key]?.length ?? 0})
              {key !== ETypeItem["idea.main"] && currentUser.data?.role === ERole.SUPER_ADMIN ? (
                <Button
                  variant="text"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    generateGrammarStructure(key);
                  }}
                  disabled={formatedData[ETypeItem["idea.main"]].length === 0}
                >
                  {generateGrammarsResult.isFetching && key === ETypeItem["idea.grammar"] ? (
                    <CircularProgress size={20} />
                  ) : generateStructuresResult.isFetching && key === ETypeItem["idea.structure"] ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              <SortIdeas {...rest} listData={formatedData[key]} />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <ModalPromptStructure
        aspectContent={aspectContent}
        open={openModalPromptStructure}
        handleClose={() => {
          setOpenModalPromptStructure(false);
        }}
        handleSave={handleSendGenerateStructure}
      />
    </Box>
  );
}

export default ListIdeas;
