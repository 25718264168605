import { Box, Button, Typography } from "@mui/material";
import { IApplicationState } from "../../../../../store-redux";
import { useSelector } from "react-redux";
import { matchedLetterAnswer, removedLetterAnswer } from "../../../../../helpers";

interface ICustomAnswerWordBoldProps {
  nextStep: () => void;
  boldIndexes: number[];
  setBoldIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

function CustomAnswerWordBold({ nextStep, boldIndexes, setBoldIndexes }: ICustomAnswerWordBoldProps) {
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);

  const wordArray = removedLetterAnswer(editCustomAnswerContent.data);

  const handleWordBold = (index: number) => {
    //  tạo một state để lưu vị trí sẽ bold
    // check trạng thái khi clịck vào rồi thì cái index có thuộc array hay k nêu có thì xoá đi k có thì thêm vào
    // một phần tử thuộc một mảng (include ) nếu phần tử có trong mảng thì bold
    // next sang highlight truyền một chuỗi string thêm thẻ b

    if (boldIndexes.includes(index)) {
      setBoldIndexes(boldIndexes.filter((i) => i !== index));
    } else {
      setBoldIndexes([...boldIndexes, index]);
    }
  };

  const matches = matchedLetterAnswer(editCustomAnswerContent.data);

  return (
    <Box>
      <Box
        component={"ul"}
        sx={{
          listStyle: "none",
          display: "flex",
          flex: "row",
          justifyContent: "space-evenly",
          marginTop: "20px",
          border: "1px solid black",
          padding: "5px 0",
          borderRadius: "8px",
          "& li": {},
        }}
      >
        {matches?.map((item, idx) => (
          <Box component={"li"} key={idx}>
            {item.replace(/[()]/g, "")}
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          overflow: "auto",
          maxHeight: "72vh",
          display: "flex",
          flexDirection: "row",
          maxWidth: "calc(100vw - 32px)",
          flexFlow: "row wrap",
        }}
      >
        {wordArray.map((singleWord, index) => {
          return index === wordArray.length ? null : (
            <Typography
              key={index}
              sx={{
                fontWeight: boldIndexes.includes(index) ? "600" : "400",
                paddingInline: "3px",
                color: boldIndexes.includes(index) ? "red" : "black",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#ECEAEA",
                },
              }}
              variant="h6"
              onClick={() => {
                handleWordBold(index);
              }}
            >
              {singleWord + " "}
            </Typography>
          );
        })}

        <Button
          sx={{
            position: "fixed",
            bottom: "10px",
            width: "calc(100vw - 32px)",
            textTransform: "none",
            backgroundColor: "#264653",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "700",
          }}
          variant="contained"
          onClick={() => {
            nextStep();
          }}
        >
          Highlight
        </Button>
      </Box>
    </Box>
  );
}

export default CustomAnswerWordBold;
