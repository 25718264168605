import { Box, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAnswerItem, IApplicationState } from "../../../../store-redux";
import { useEffect, useState } from "react";
import { convertToLocalTime, matchedLetterAnswer } from "@/helpers";
import { groupBy } from "lodash";

interface IListInfoAnwerProps {
  idTopics: string;
  handleRemoveItemAnswer: (item: IAnswerItem) => void;
  handleClick: (item: IAnswerItem) => void;
}

interface IListInfoItem {
  id: string;
  content: string;
  listItems: IAnswerItem[];
}

export default function ListInfoAnwer({ idTopics, handleRemoveItemAnswer, handleClick }: IListInfoAnwerProps) {
  const listAnswer = useSelector((state: IApplicationState) => state.listAnswerOfUser);

  const [filterAnswerByTopicId, setFilterAnswerByTopicId] = useState<IListInfoItem[]>();

  useEffect(() => {
    if (idTopics) {
      // group with questionId
      const answerIdTopic = listAnswer.data?.filter((item) => item?.topicId === idTopics);
      const groupByQuestionId = groupBy(answerIdTopic, "questionId");
      const listItemWithQuestion = Object.keys(groupByQuestionId).map((key) => ({
        id: key,
        content: groupByQuestionId[key][0].question,
        listItems: groupByQuestionId[key],
      }));
      setFilterAnswerByTopicId(listItemWithQuestion);
    }
  }, [listAnswer, idTopics]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        maxWidth: "calc(100vw - 32px)",
        paddingRight: "10px",
        "& li": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "10px",
          border: "1px solid #EDEDED",
          borderRadius: "8px",
          marginBottom: "10px",
          padding: "8px",
        },
        "& .text": {
          letterSpacing: "0em",
          textAlign: "left",
        },
        "& h4": {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          fontFamily: "Poppins",
        },
        "& .content-title": {
          display: "flex",
          "& h4": {
            fontWeight: "bold",
            marginRight: "8px",
          },
        },
        "& .content-answer": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #e1e1e1",
          paddingBottom: "8px",
          "& .info-answer": {
            maxWidth: "calc(100vw - 82px)",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ECEAEA",
            },
            "& .info-time": {
              display: "flex",
              gap: "20px",
            },
            "& h4": {
              fontStyle: "italic",
              color: "#827e7e",
            },
          },
          "&:last-child": {
            borderBottom: "none",
          },
        },
      }}
    >
      {filterAnswerByTopicId?.map((answer) => (
        <Box component={"li"} key={answer.id}>
          <Box className="content-title">
            <Typography
              component={"h4"}
              className="text"
              dangerouslySetInnerHTML={{
                __html: answer.content || "",
              }}
            />
            ({answer.listItems?.length})
          </Box>
          {answer.listItems
            ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .map((item) => (
              <Box className="content-answer" key={item._id}>
                <Box onClick={() => handleClick(item)} className="info-answer">
                  <Box>
                    <Typography
                      component={"h4"}
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: item.answer || "",
                      }}
                      sx={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2 /* number of lines to show */,
                        WebkitBoxOrient: "vertical",
                      }}
                    />
                  </Box>
                  <Box className="info-time">
                    <Typography
                      component={"h4"}
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: matchedLetterAnswer(item.answer).join(" => ") || "",
                      }}
                      style={{ color: "#667bdb" }}
                    />
                    <Typography
                      component={"h4"}
                      className="text"
                      style={{
                        fontStyle: "italic",
                        color: "#70c848",
                      }}
                    >
                      {convertToLocalTime(item.updatedAt)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => handleRemoveItemAnswer(item)}
                    className="btn-delete"
                    sx={{
                      "&:active": {
                        backgroundColor: "#ECEAEA",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
        </Box>
      ))}
    </Box>
  );
}
