import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import GrammarItem from "./GrammarItem";

const GrammarTab = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GRAMMAR_SAVED_LIST));
    setData(dataRes);
  };
  return (
    <Box>
      {data.map((item) => {
        return <GrammarItem item={item} />;
      })}
    </Box>
  );
};

export default GrammarTab;
