import { Box, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { IAnswerItem, IApplicationState } from "../../../../store-redux";
import { useEffect, useState } from "react";
import { convertToLocalTime, matchedLetterAnswer } from "@/helpers";

interface IListInfoAnwerProps {
  handleRemoveItemAnswer: (item: IAnswerItem) => void;
  handleClick: (item: IAnswerItem) => void;
}

export default function ListInfoAnwerOld({ handleRemoveItemAnswer, handleClick }: IListInfoAnwerProps) {
  const listAnswer = useSelector((state: IApplicationState) => state.listAnswerOfUser);

  const [filterAnswerOld, setFilterAnswerOld] = useState<IAnswerItem[]>();

  useEffect(() => {
    const answerOld = listAnswer.data?.filter((item) => item?.topicId === undefined || item?.topicId === ":topicId");
    setFilterAnswerOld(answerOld);
  }, [listAnswer]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        maxWidth: "calc(100vw - 32px)",
        maxheight: "calc(100vh - 264px)",
        paddingRight: "10px",
        "& li": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          border: "1px solid #EDEDED",
          borderRadius: "8px",
          marginBottom: "10px",
          padding: "8px",
        },
        "& .text": {
          letterSpacing: "0em",
          textAlign: "left",
        },
        "& h4": {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          fontFamily: "Poppins",
        },
        "& .info-answer": {
          maxWidth: "calc(100vw - 82px)",
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          cursor: "pointer",
          width: "100%",
        },
      }}
    >
      {filterAnswerOld
        ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map((item) => (
          <Box component={"li"} key={item._id}>
            <Box onClick={() => handleClick(item)} className="info-answer">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    component={"h4"}
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: item.question || "",
                    }}
                    sx={{
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2 /* number of lines to show */,
                      WebkitBoxOrient: "vertical",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    component={"h4"}
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: matchedLetterAnswer(item.answer).join(" => ") || "",
                    }}
                  />
                </Box>
                <Typography
                  component={"h4"}
                  className="text"
                  sx={{
                    fontStyle: "italic",
                  }}
                >
                  {convertToLocalTime(item.updatedAt)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleRemoveItemAnswer(item)}
                className="btn-delete"
                sx={{
                  "&:active": {
                    backgroundColor: "#ECEAEA",
                    cursor: "pointer",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
