export enum AllTypeActions {
  POST_LOGIN = "POST_LOGIN",
  SYSTEM_MESSAGE_SHOW = "SYSTEM_MESSAGE_SHOW",
  SYSTEM_MESSAGE_HIDE = "SYSTEM_MESSAGE_HIDE",
  GET_CURRENT_USER = "GET_CURRENT_USER",
  GET_LIST_USER = "GET_LIST_USER",
  CREATE_USER = "CREATE_USER",
  UPDATE_USER = "UPDATE_USER",
  DELETE_USER = "DELETE_USER",
  GET_LIST_TOPICS = "GET_LIST_TOPICS",
  CREATE_TOPICS = "CREATE_TOPICS",
  CREATE_QUESTIONS = "CREATE_QUESTIONS",
  GET_LIST_QUESTIONS = "GET_LIST_QUESTIONS",
  DELETE_TOPIC = "DELETE_TOPIC",
  DELETE_QUESTION = "DELETE_QUESTION",
  UPDATE_TOPIC = "UPDATE_TOPIC",
  UPDATE_QUESTION = "UPDATE_QUESTION",
  GET_DETAIL_ITEM = "GET_DETAIL_ITEM",
  GET_LIST_IDEAS = "GET_LIST_IDEAS",
  GET_LIST_ALL_GRAMMAR_OF_IDEAS = "GET_LIST_ALL_GRAMMAR_OF_IDEAS",
  GET_LIST_ALL_STRUCTURE_OF_IDEAS = "GET_LIST_ALL_STRUCTURE_OF_IDEAS",
  CREATE_ITEMS = "CREATE_ITEMS",
  DELETE_ITEM = "DELETE_ITEM",
  UPDATE_ITEM = "UPDATE_ITEM",
  GET_LIST_CATEGORIES = "GET_LIST_CATEGORIES",
  GET_LIST_ASPECTS = "GET_LIST_ASPECTS",
  ADD_INTO_CART = "ADD_INTO_CART",
  ADD_INTO_CART_SUCCESS = "ADD_INTO_CART_SUCCESS",
  REMOVE_INTO_CART = "REMOVE_INTO_CART",
  ADD_ITEM_CUSTOM = "ADD_ITEM_CUSTOM",
  EDIT_CUSTOM_ANSWER = "EDIT_CUSTOM_ANSWER",
  EDIT_CUSTOM_ANSWER_SUCCESS = "EDIT_CUSTOM_ANSWER_SUCCESS",
  GENERATE_IDEAS = "GENERATE_IDEAS",
  GENERATE_GRAMMARS = "GENERATE_GRAMMARS",
  GENERATE_STRUCTURES = "GENERATE_STRUCTURES",
  SAVE_CUSTOM_ANSWER = "SAVE_CUSTOM_ANSWER",
  SAVE_EDIT_CUSTOM_ANSWER = "SAVE_EDIT_CUSTOM_ANSWER",
  GET_LIST_ANSWER_OF_USER = "GET_LIST_ANSWER_OF_USER",
  REMOVE_MULTIPLE_ITEMS = "REMOVE_MULTIPLE_ITEMS",
  REMOVE_ANSWER_ITEM = "REMOVE_ANSWER_ITEM",
  CREATE_LEVEL = "CREATE_LEVEL",
  UPDATE_LEVEL = "UPDATE_LEVEL",
  GET_LIST_LEVEL = "GET_LIST_LEVEL",
  UPDATE_ORDER = "UPDATE_ORDER",
  GET_LIST_ALL_ASPECTS = "GET_LIST_ALL_ASPECTS",
  IDEA_COMBINE_CATEGORY_ASPECT = "IDEA_COMBINE_CATEGORY_ASPECT",
  POST_CHALLENGES_UPLOAD = "POST_CHALLENGES_UPLOAD",
  POST_CHALLENGES_TRANSCRIBE = "POST_CHALLENGES_TRANSCRIBE",
  POST_CHALLENGES_EVALUATE = "POST_CHALLENGES_EVALUATE",
  GET_WORD_SEARCH = "GET_WORD_SEARCH",
  GET_REMAINING_ENERGY = "GET_REMAINING_ENERGY",
  CREATE_QUESTION_CATEGORY = "CREATE_QUESTION_CATEGORY",
  CREATE_QUESTION_TYPE = "CREATE_QUESTION_TYPE",
  UPDATE_QUESTION_CATEGORY = "UPDATE_QUESTION_CATEGORY",
  UPDATE_QUESTION_TYPE = "UPDATE_QUESTION_TYPE",
  GET_LIST_QUESTION_CATEGORY = "GET_LIST_QUESTION_CATEGORY",
  GET_LIST_QUESTION_TYPE = "GET_LIST_QUESTION_TYPE",
  DELETE_QUESTION_CATEGORY = "DELETE_QUESTION_CATEGORY",
  DELETE_QUESTION_TYPE = "DELETE_QUESTION_TYPE",
  GET_LIST_GUIDED_QUESTIONS = "GET_LIST_GUIDED_QUESTIONS",
}
