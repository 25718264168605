import { AllTypeActions } from "../types";
import { put, takeLatest } from "redux-saga/effects";
import { deleteQuestionCategory, deleteQuestionType, getListGuidedQuestions, showMessage } from "../actions";
import { IItems } from "../interface";
import { ENDPOINTS, getApiUrl } from "@/common/endpoints";
import { Requests } from "@/common/requests";

function* getListItems({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GET_LIST_ITEMS, input);
    const data: any = yield Requests.get(apiUrl);
    if (data?.success) {
      yield put({
        type: `${type}_SUCCESS`,
        data: data?.data?.sort((a: IItems, b: IItems) => a.order - b.order),
      });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* createItems({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.CREATE_MULTIPLE_ITEMS);

    // check upload files
    const filesOrigin = input?.items?.map((item: any) => item.file);
    const filesOriginExist = filesOrigin?.filter((item: any) => item);
    let inputWithFiles: any;
    if (filesOriginExist?.length) {
      const urlUploadFile = getApiUrl(ENDPOINTS.UPLOAD_FILES);
      const dataUpload: any = yield Requests.post(
        urlUploadFile,
        {
          files: filesOriginExist,
        },
        true,
        false
      );
      let indexDataUpload = 0;
      inputWithFiles = input?.items?.map((item: any) => {
        if (item?.file) {
          const dataItem = {
            ...item,
            imageUrl: dataUpload?.files?.[indexDataUpload]?.path,
          };
          indexDataUpload += 1;
          return dataItem;
        }
        return item;
      });
    }
    const dataRequest = filesOriginExist?.length
      ? {
          items: inputWithFiles,
        }
      : input;
    const data: any = yield Requests.post(apiUrl, dataRequest);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* deleteItem({ id, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM, { id }, true);
    const data: any = yield Requests.delete(apiUrl);
    if (data?.success) {
      yield put(showMessage("Delete answer item success", "success"));
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* updateItem({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const { id, ...rest } = input;
    const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM, { id }, true);

    // check upload file
    if (rest?.file) {
      const urlUploadFile = getApiUrl(ENDPOINTS.UPLOAD_FILES);
      const dataUpload: any = yield Requests.post(
        urlUploadFile,
        {
          files: rest.file,
        },
        true,
        false
      );
      rest.imageUrl = dataUpload?.files?.[0]?.path;
    }

    const data: any = yield Requests.patch(apiUrl, rest);
    if (data?.success) {
      yield put(showMessage("Update item success", "success"));
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* getDetailItem({ id, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM, { id }, true);
    const data: any = yield Requests.get(apiUrl);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* generateIdeas({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GENERATE_IDEAS);
    const data: any = yield Requests.post(apiUrl, input);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* generateGrammars({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GENERATE_GRAMMARS);
    const data: any = yield Requests.post(apiUrl, input);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* generateStructures({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.GENERATE_STRUCTURES);
    const data: any = yield Requests.post(apiUrl, input);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* saveCustomAnswer({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.CUSTOM_ANSWER);
    const data: any = yield Requests.post(apiUrl, input);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
      yield put(showMessage("Save your answer success", "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* saveEditCustomAnswer({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const { id, ...rest } = input;
    const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM_CUSTOM_ANSWER, { id }, true);
    const data: any = yield Requests.patch(apiUrl, rest);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
      yield put(showMessage("Save your edit answer success", "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* getListAnswerOfUser({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.CUSTOM_ANSWER, input);
    const data: any = yield Requests.get(apiUrl);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* removeMultipleItems({ input, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });
  try {
    const apiUrl = getApiUrl(ENDPOINTS.REMOVE_MULTIPLE_ITEMS);
    const data: any = yield Requests.post(apiUrl, input);
    if (data?.success) {
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
      yield put(showMessage("Delete items success", "success"));
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* removeAnswerItem({ id, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });

  try {
    const apiUrl = getApiUrl(ENDPOINTS.ONE_ITEM_CUSTOM_ANSWER, { id }, true);
    const data: any = yield Requests.delete(apiUrl);
    if (data?.success) {
      yield put(showMessage("Delete answer item success", "success"));
      yield put({ type: `${type}_SUCCESS`, data: data?.data });
    } else {
      yield put({ type: `${type}_FAIL`, error: data.message });
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}

function* getWordSearch({ word, type }: any): Generator<any> {
  yield put({ type: `${type}_ATTEMPT` });

  try {
    if (word.trim() === "") {
      yield put({
        type: `${type}_SUCCESS`,
        data: null,
      });
    } else {
      yield put({
        type: `${type}_SUCCESS`,
        data: {
          word: word,
          isLoading: true,
        },
      });
    }
    if (word.trim() !== "") {
      const apiUrl = getApiUrl(ENDPOINTS.GET_WORD_SEARCH, { keyword: word });
      const { items, isUseAI }: any = yield Requests.get(apiUrl);
      if (items?.length) {
        yield put({
          type: `${type}_SUCCESS`,
          data: {
            word: word,
            data: items[0],
            isLoading: false,
            isUseAI: !!isUseAI,
          },
        });
      } else {
        yield put({
          type: `${type}_SUCCESS`,
          data: {
            word: word,
            data: null,
            isLoading: false,
          },
        });
      }
    }
  } catch (e: any) {
    yield put({ type: `${type}_FAIL`, error: e.toString() });
  }
}
export function* itemsActions() {
  yield takeLatest(AllTypeActions.GET_LIST_TOPICS, getListItems);
  yield takeLatest(AllTypeActions.CREATE_TOPICS, createItems);
  yield takeLatest(AllTypeActions.CREATE_QUESTIONS, createItems);
  yield takeLatest(AllTypeActions.GET_LIST_QUESTIONS, getListItems);
  yield takeLatest(AllTypeActions.DELETE_TOPIC, deleteItem);
  yield takeLatest(AllTypeActions.DELETE_QUESTION, deleteItem);
  yield takeLatest(AllTypeActions.UPDATE_TOPIC, updateItem);
  yield takeLatest(AllTypeActions.UPDATE_QUESTION, updateItem);
  yield takeLatest(AllTypeActions.GET_DETAIL_ITEM, getDetailItem);
  yield takeLatest(AllTypeActions.GET_LIST_IDEAS, getListItems);
  yield takeLatest(AllTypeActions.GET_LIST_ALL_GRAMMAR_OF_IDEAS, getListItems);
  yield takeLatest(AllTypeActions.GET_LIST_ALL_STRUCTURE_OF_IDEAS, getListItems);
  yield takeLatest(AllTypeActions.CREATE_ITEMS, createItems);
  yield takeLatest(AllTypeActions.DELETE_ITEM, deleteItem);
  yield takeLatest(AllTypeActions.UPDATE_ITEM, updateItem);
  yield takeLatest(AllTypeActions.GET_LIST_CATEGORIES, getListItems);
  yield takeLatest(AllTypeActions.GET_LIST_ASPECTS, getListItems);
  yield takeLatest(AllTypeActions.GET_LIST_ALL_ASPECTS, getListItems);
  yield takeLatest(AllTypeActions.GENERATE_IDEAS, generateIdeas);
  yield takeLatest(AllTypeActions.GENERATE_GRAMMARS, generateGrammars);
  yield takeLatest(AllTypeActions.GENERATE_STRUCTURES, generateStructures);
  yield takeLatest(AllTypeActions.SAVE_CUSTOM_ANSWER, saveCustomAnswer);
  yield takeLatest(AllTypeActions.SAVE_EDIT_CUSTOM_ANSWER, saveEditCustomAnswer);
  yield takeLatest(AllTypeActions.GET_LIST_ANSWER_OF_USER, getListAnswerOfUser);
  yield takeLatest(AllTypeActions.REMOVE_MULTIPLE_ITEMS, removeMultipleItems);
  yield takeLatest(AllTypeActions.REMOVE_ANSWER_ITEM, removeAnswerItem);
  yield takeLatest(AllTypeActions.CREATE_LEVEL, createItems);
  yield takeLatest(AllTypeActions.UPDATE_LEVEL, updateItem);
  yield takeLatest(AllTypeActions.GET_LIST_LEVEL, getListItems);
  yield takeLatest(AllTypeActions.UPDATE_ORDER, updateItem);
  yield takeLatest(AllTypeActions.GET_WORD_SEARCH, getWordSearch);
  yield takeLatest(AllTypeActions.CREATE_QUESTION_CATEGORY, createItems);
  yield takeLatest(AllTypeActions.CREATE_QUESTION_TYPE, createItems);
  yield takeLatest(AllTypeActions.UPDATE_QUESTION_CATEGORY, updateItem);
  yield takeLatest(AllTypeActions.UPDATE_QUESTION_TYPE, updateItem);
  yield takeLatest(AllTypeActions.GET_LIST_QUESTION_CATEGORY, getListItems);
  yield takeLatest(AllTypeActions.GET_LIST_QUESTION_TYPE, getListItems);
  yield takeLatest(AllTypeActions.DELETE_QUESTION_CATEGORY, deleteItem);
  yield takeLatest(AllTypeActions.DELETE_QUESTION_TYPE, deleteItem);
  yield takeLatest(AllTypeActions.GET_LIST_GUIDED_QUESTIONS, getListItems);
}
