import axios from "axios";
import { KeyTokenName } from "../../constants";
import { objectToFormData } from "../../helpers";

// Add a global interceptor to handle 401 errors and redirect to /login
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
      localStorage.removeItem(KeyTokenName);
    }
    return Promise.reject(error);
  }
);

const defaultHeader = (token?: string | null) => {
  const header: any = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  };
  if (token) header.Authorization = `Bearer ${token}`;
  return header;
};

class ApiRequests {
  public get(apiUrl: string, params: any = {}, isAuth: boolean = true): Promise<any> {
    const headers = defaultHeader(isAuth ? localStorage.getItem(KeyTokenName) : null);
    return new Promise<any>((resolve, reject) => {
      axios
        .get(apiUrl, { headers, params })
        .then((response) => {
          const jsonData = response.data;
          resolve(jsonData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public post(apiUrl: string, input: any = {}, isAuth: boolean = true, jsonParser: boolean = true): Promise<any> {
    const data = jsonParser ? JSON.stringify(input) : objectToFormData(input);
    const headers = defaultHeader(isAuth ? localStorage.getItem(KeyTokenName) : null);
    return new Promise<any>((resolve, reject) => {
      axios
        .post(apiUrl, data, {
          headers: { ...headers, ...(!jsonParser ? { "Content-Type": "multipart/form-data" } : {}) },
        })
        .then((response) => {
          const jsonData = response.data;
          resolve(jsonData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public put(apiUrl: string, input: any = {}, isAuth: boolean = true, jsonParser: boolean = true): Promise<any> {
    const data = jsonParser ? JSON.stringify(input) : objectToFormData(input);
    const headers = defaultHeader(isAuth ? localStorage.getItem(KeyTokenName) : null);
    return new Promise<any>((resolve, reject) => {
      axios
        .put(apiUrl, data, { headers })
        .then((response) => {
          const jsonData = response.data;
          resolve(jsonData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public patch(apiUrl: string, input: any = {}, isAuth: boolean = true, jsonParser: boolean = true): Promise<any> {
    const data = jsonParser ? JSON.stringify(input) : objectToFormData(input);
    const headers = defaultHeader(isAuth ? localStorage.getItem(KeyTokenName) : null);
    return new Promise<any>((resolve, reject) => {
      axios
        .patch(apiUrl, data, { headers })
        .then((response) => {
          const jsonData = response.data;
          resolve(jsonData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public delete(apiUrl: string, params: any = {}, isAuth: boolean = true, jsonParser: boolean = false): Promise<any> {
    const headers = defaultHeader(isAuth ? localStorage.getItem(KeyTokenName) : null);
    const data = jsonParser ? JSON.stringify(params) : objectToFormData(params);
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(apiUrl, { headers, data })
        .then((response) => {
          const jsonData = response.data;
          resolve(jsonData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

export const Requests = new ApiRequests();
