export const styles = {
  styleItem: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    position: "relative",
    padding: "5px",
    minHeight: "50px",
    "& .menu-icon": {
      position: "absolute",
      right: "5px",
      top: "50%",
      transform: "translateY(-50%)",
      height: "40px",
      zIndex: -1,
    },
    "&:hover": {
      backgroundColor: "#ECEAEA",
    },
    "&:hover .menu-icon": {
      zIndex: 1,
    },
    "& .title": {
      paddingRight: "30px",
    },
    "& .description": {
      paddingRight: "30px",
    },
  },
  boxContainer: {
    display: "flex",
    width: "100%",
    gap: "20px",
    "& .flex-1": {
      flex: 1,
    },
    "& h3": {
      fontSize: 20,
      marginBottom: "10px",
    },
    "& button": {
      textTransform: "none",
      marginBottom: "10px",
    },
    "& .box-item": {
      height: "calc(100vh - 235px) !important",
      overflowY: "auto",
    },
    "& .form-generate-idea": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingRight: "10px",
      "&>div": {
        width: "100%",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "space-between",
      },
      "& .icon-submit": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "45px",
        marginLeft: "10px",
        marginRight: "10px",
        "& svg": {
          transform: "rotate(-45deg)",
        },
      },
    },
  },
};
