import NoDataComponent from "@/components/NoDataComponent";
import { ROUTES } from "@/constants";
import { extractContent, removeLeadingNumbersAndPeriods } from "@/helpers";
import { ETypeItem, getListQuestionCategory, IApplicationState } from "@/store-redux";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LayoutLearn from "../LayoutLearn";

const QuestionTypePage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listQuestionCategory = useSelector((state: IApplicationState) => state.listQuestionCategory);

  const handleGetListCategory = () => {
    dispatch(
      getListQuestionCategory({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem["question.category"],
      })
    );
  };

  useEffect(() => {
    if (listQuestionCategory.data?.length) return;
    handleGetListCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionCategoryClick = (id: string) => {
    navigate(ROUTES.LEARN_QUESTION_TYPE_BY_CAT.replace(":questionCategoryId", id));
  };
  return (
    <LayoutLearn>
      <Box
        sx={{
          width: "100%",
          "& h3": {
            fontSize: "22px",
            textAlign: "left",
            fontWeight: "bold",
          },
          "& .box-container": {
            overflowY: "auto",
            marginTop: "10px",
            paddingInline: "5px",
            display: "flex",
            flexDirection: "column",
            " .title": {
              fontSize: "18px",
              marginBottom: "8px",
            },
          },
          "& .box-item": {
            padding: "4px",
            borderRadius: "8px",
            textAlign: "left",
            paddingLeft: "16px",
            "&:hover": {
              backgroundColor: "#ECEAEA",
              cursor: "pointer",
              " .title": {
                color: "black",
                fontWeight: "bold",
              },
            },
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& button": {
                marginLeft: "16px",
                height: "40px",
              },
            }}
          >
            <Button onClick={() => navigate(-1)} variant="contained" color="secondary">
              Back
            </Button>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "20px",
                textAlign: "left",
                marginLeft: "16px",
              }}
            >
              Question Category
            </Typography>
            <Box />
          </Box>
        </Box>
        <Box className="box-container">
          {listQuestionCategory.data?.map((questionCategory) => (
            <Box
              key={questionCategory._id}
              onClick={() => handleQuestionCategoryClick(questionCategory._id)}
              className="box-item"
            >
              <Box className="title">{removeLeadingNumbersAndPeriods(extractContent(questionCategory.name))}</Box>
            </Box>
          ))}
          {listQuestionCategory.data?.length === 0 && !listQuestionCategory.isFetching ? <NoDataComponent /> : null}
        </Box>
      </Box>
    </LayoutLearn>
  );
};

export default QuestionTypePage;
