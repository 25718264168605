import { Box } from "@mui/material";
import { SuggestVocabularyPopover } from "./SuggestVocabularyPopover";
import StructureSuggestBtn from "./StructureSuggestBtn";
import SuggestVoca from "./SuggestVoca";
import { useState } from "react";
import { AiBoxTab, SuggestVocabularyBand } from "@/common";
import GrammarSuggestBtn from "./GrammarSuggestBtn";

interface ITabSuggestionProps {
  setBoxTabActive: React.Dispatch<React.SetStateAction<AiBoxTab | null>>;
}

function TabSuggestion({ setBoxTabActive }: ITabSuggestionProps) {
  const [suggestBand, setSuggestBand] = useState<SuggestVocabularyBand | null>(null);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <StructureSuggestBtn />
        <GrammarSuggestBtn />
        <SuggestVocabularyPopover
          repharseLoading={false}
          onSuggestVoca={(band) => {
            setSuggestBand(band);
            setBoxTabActive(AiBoxTab.suggestVoca);
          }}
        />
      </Box>
      <SuggestVoca band={suggestBand} />
    </>
  );
}

export default TabSuggestion;
