import { INIT_LLM_ENERGY } from "@/constants";
import { Box, Button, Input, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { EBackOfficeCode } from "@/common/types/backoffice";
import { jsonToFile } from "@/helpers";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
const LLMCMSPage = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [value, setValue] = useState({});
  useEffect(() => {
    setValue(data);
  }, data);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLLMEnergy();
  }, []);

  const getLLMEnergy = async () => {
    try {
      const dataRes = await Requests.get(
        getApiUrl(ENDPOINTS.BACKOFFICE_GET_BY_CODE.replace(":code", EBackOfficeCode.LLM_ENERGY_CMS))
      );
      if (dataRes?.data) {
        const data = dataRes?.data;
        data.types = {
          ...INIT_LLM_ENERGY.types,
          ...data.types,
        };
        setData(data);
      } else {
        setData(INIT_LLM_ENERGY);
      }
    } catch (e) {
      setData(INIT_LLM_ENERGY);
    }
  };

  const onSave = async () => {
    const file = jsonToFile(value);
    const urlUploadFile = getApiUrl(ENDPOINTS.BACKOFFICE_SAVE_BY_CODE.replace(":code", EBackOfficeCode.LLM_ENERGY_CMS));

    try {
      await Requests.post(
        urlUploadFile,
        {
          file: file,
        },
        true,
        false
      );
      setIsLoading(false);
      dispatch(showMessage("Update LLM energy data success", "success"));
      setIsEdit(false);
      setData(value);
    } catch (e) {
      setIsLoading(false);
      dispatch(showMessage("Update LLM energy data error", "error"));
    }
  };
  const energyByTypes = data?.types;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "5px 10px",
        }}
      >
        {isEdit ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "initial",
                marginRight: "10px",
              }}
              startIcon={<SaveIcon htmlColor="#fff" />}
              onClick={() => onSave()}
              disabled={isLoading}
            >
              Save
            </Button>
            <Button
              sx={{
                textTransform: "unset",
                backgroundColor: "#f1f1f1",
                color: "#000",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={() => setIsEdit(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "initial",
              }}
              startIcon={<EditIcon htmlColor="#fff" />}
              onClick={() => {
                setIsEdit(true);
                setValue(data);
              }}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography
          sx={{
            marginRight: "10px",
          }}
        >
          Limit quota energy by day:
        </Typography>
        {isEdit ? (
          <Input
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "2px",
              marginBottom: "4px",
              "&::after": {
                display: "none",
              },
              "&::before": {
                display: "none",
              },
            }}
            type="number"
            value={value?.limitQuotaEnergyByDay ?? ""}
            onChange={(event) => {
              setValue((prev) => ({
                ...prev,
                limitQuotaEnergyByDay: event.target.value,
              }));
            }}
          />
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {data?.limitQuotaEnergyByDay ?? ""}
          </Typography>
        )}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
              }}
            >
              LLM type
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
              }}
            >
              Energy
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {energyByTypes && (
            <>
              {Object.keys(energyByTypes).map((llm, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{llm}</TableCell>
                    <TableCell>
                      {isEdit ? (
                        <>
                          <Input
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              padding: "2px",
                              marginBottom: "4px",
                              "&::after": {
                                display: "none",
                              },
                              "&::before": {
                                display: "none",
                              },
                            }}
                            type="number"
                            value={value?.types?.[llm]}
                            onChange={(event) => {
                              setValue((prev) => ({
                                ...prev,
                                types: {
                                  ...prev.types,
                                  [llm]: event.target.value !== "" ? Number(event.target.value) : "",
                                },
                              }));
                            }}
                          />
                        </>
                      ) : (
                        <Typography>{energyByTypes[llm]}</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default LLMCMSPage;
