import { Box, Checkbox, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { MenuAction } from "../../../../components/MenuAction";
import { EAction, IItems, updateOrder } from "../../../../store-redux";
import { styles } from "../styles";
import { useDispatch } from "react-redux";
import _ from "lodash";

export interface IHandleAction {
  typeAction: EAction;
  data: IItems;
}

interface ISortIdeasProps {
  listData: IItems[];
  handleAction: (data: IHandleAction) => void;
  setOpenModalCreate: (
    value: React.SetStateAction<{
      open: boolean;
      data?: IItems | undefined;
    }>
  ) => void;
  showSelect: boolean;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

interface ItemsSort extends IItems {
  id: string;
}

function SortIdeas({
  listData,
  handleAction,
  setOpenModalCreate,
  showSelect,
  selectedItems,
  setSelectedItems,
}: ISortIdeasProps) {
  const [listIdea, setListIdea] = useState<ItemsSort[]>(listData.map((item) => ({ ...item, id: item._id })));
  const dispatch = useDispatch();
  const listIdeaRef = useRef(listData);
  const onEditIdea = (data: IItems) => {
    setOpenModalCreate({
      open: true,
      data,
    });
  };

  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    }
  };

  const handleSortItem = (newState: ItemsSort[]) => {
    setListIdea(newState);
    listIdeaRef.current = newState;
  };

  const onEndSort = () => {
    let newOrder = 0;
    const itemChange = listIdeaRef.current?.find((item, idx) => {
      const findItem = item.order > listIdeaRef.current?.[idx + 1]?.order;
      if (findItem) {
        newOrder =
          ((listIdeaRef.current?.[idx - 1]?.order ?? 0) +
            (listIdeaRef.current?.[idx + 1]?.order ?? listIdeaRef.current?.[idx - 1]?.order + 1)) /
          2;
      }
      return findItem;
    });
    const newValue = listIdeaRef.current?.map((item) => {
      if (item._id === itemChange?._id) {
        return {
          ...item,
          id: item._id,
          order: newOrder,
        };
      }
      return { ...item, id: item._id };
    });
    setListIdea(newValue);
    if (itemChange) {
      dispatch(
        updateOrder({
          ...itemChange,
          id: itemChange._id,
          name: itemChange.name,
          description: itemChange.description ?? "",
          type: itemChange.type,
          parentId: itemChange.parentId,
          aspectId: itemChange.aspectId,
          order: newOrder,
        })
      );
    }
  };

  useEffect(() => {
    setListIdea(listData.map((item) => ({ ...item, id: item._id })));
  }, [listData]);
  const isCheckAll = useMemo(() => {
    return !listIdea.find((idea) => !selectedItems.includes(idea._id));
  }, [selectedItems]);
  return (
    <>
      {showSelect && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Checkbox
              checked={isCheckAll}
              onClick={(e) => {
                if (e.target.checked) {
                  setSelectedItems((prev) => _.uniq([...prev, ...(listIdea ?? []).map((item) => item._id)]));
                } else {
                  setSelectedItems(selectedItems.filter((itemId) => !listIdea.find((idea) => idea._id === itemId)));
                }
              }}
              inputProps={{ "aria-label": "Select item" }}
              className="check-box"
            />
          </Box>
          <Typography
            sx={{
              fontWeight: "700",
            }}
          >
            Select All
          </Typography>
        </Box>
      )}
      <ReactSortable
        list={listIdea}
        setList={(newState) => handleSortItem(newState)}
        animation={200}
        delayOnTouchStart={true}
        delay={2}
        onEnd={onEndSort}
      >
        {listIdea?.map((item, idx) => (
          <Box
            key={item._id}
            sx={() => ({
              display: "flex",
              borderTop: !showSelect && idx === 0 ? "none" : "1px solid #e1e1e1",
            })}
          >
            {showSelect ? (
              <Box>
                <Checkbox
                  checked={selectedItems.includes(item._id)}
                  onChange={(e) => {
                    handleSelectItem(e, item._id);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  inputProps={{ "aria-label": "Select item" }}
                  className="check-box"
                />
              </Box>
            ) : null}
            <Box
              sx={{
                ...styles.styleItem,
              }}
              onDoubleClick={() => onEditIdea(item)}
            >
              <Box>
                <Box className="title" dangerouslySetInnerHTML={{ __html: item.name || "" }} />
                <Box
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: item.description || "",
                  }}
                />
                <MenuAction
                  handleClick={(typeAction: EAction) => {
                    handleAction({
                      typeAction,
                      data: item,
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </ReactSortable>
    </>
  );
}

export default SortIdeas;
