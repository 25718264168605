import { IAnswerItem } from "@/common";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ListInfoAnwer from "../ListInfoAnswer";
import ListInfoAnwerOld from "../ListInfoAnswerOld";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IApplicationState, editCustomAnswer, removeAnswerItem } from "@/store-redux";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ROUTES } from "@/constants";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

function AnswerTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listTopic = useSelector((state: IApplicationState) => state.listTopics);
  const listAnswerOfUser = useSelector((state: IApplicationState) => state.listAnswerOfUser);

  const listTopicExist = useMemo(() => {
    const valueTemp = listTopic?.data?.filter((item) => {
      const findItem = listAnswerOfUser?.data?.find((answer) => answer.topicId === item._id);
      return !!findItem;
    });
    return valueTemp;
  }, [listAnswerOfUser?.data, listTopic?.data]);

  const handleRemoveItemAnswer = (item: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeAnswerItem(item._id));
      }
    });
  };
  const handleClick = (value: IAnswerItem) => {
    navigate(ROUTES.ANSWER, { state: value });
    dispatch(editCustomAnswer(value.answer));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflowY: "auto",
        paddingRight: "10px",
      }}
    >
      {listTopicExist?.map((item) => (
        <Accordion
          sx={{
            borderRadius: "4px",
            height: "auto",
            margin: "0px !important",
          }}
          key={item._id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              borderRadius: "8px",
              maxHeight: "48px !important",
              minHeight: "48px",
              height: "48px",
              boxShadow: "-1px -9px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiAccordionSummary-content": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "15px",
                marginRight: "20px",
              },
              "& h6": {
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                fontSize: "18px",
                fontWeight: "600",
              },
            }}
          >
            <Box
              component={"h6"}
              key={item._id}
              dangerouslySetInnerHTML={{
                __html: `${item.name} (${
                  listAnswerOfUser.data?.filter((answer) => answer?.topicId === item._id).length
                })`,
              }}
            ></Box>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              maxHeight: "73vh",
              // [theme.breakpoints.up("sm")]: {
              //   maxHeight: "62vh",
              // },
              overflow: "auto",
            })}
          >
            <ListInfoAnwer
              idTopics={item._id}
              handleRemoveItemAnswer={handleRemoveItemAnswer}
              handleClick={handleClick}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      <Box
        sx={{
          display: listAnswerOfUser?.data?.filter((item) => !item?.topicId || item?.topicId === ":topicId")?.length
            ? "block"
            : "none",
        }}
      >
        <Typography sx={{ marginBottom: "15px", fontWeight: "bold" }}>
          Please click answer as below and save edit to group with topics
        </Typography>
        <ListInfoAnwerOld handleRemoveItemAnswer={handleRemoveItemAnswer} handleClick={handleClick} />
      </Box>
    </Box>
  );
}

export default AnswerTab;
