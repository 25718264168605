import { UnknownAction, Reducer } from "redux";
import { GlobalReducer } from "./state";
import { AllTypeActions } from "../types";
import {
  AlertSeverity,
  IAppRequestState,
  initialRequestState,
} from "../interface";

export const PostLoginReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.POST_LOGIN.toString());
};

export const GetCurrentUserReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(
    state,
    action,
    AllTypeActions.GET_CURRENT_USER.toString()
  );
};

export const GetListUserReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.GET_LIST_USER.toString());
};

export const CreateUserReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.CREATE_USER.toString());
};

export const DeleteUserReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.DELETE_USER.toString());
};

export const UpdateUserReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(state, action, AllTypeActions.UPDATE_USER.toString());
};

export const AllPermissionsReducer: Reducer = (
  state: { allPermissions: any } = { allPermissions: null },
  action: UnknownAction
) => {
  switch (action.type) {
    case "LOAD_ALL_PERMISSION": {
      return {
        ...state,
        allPermissions: action.data,
      };
    }
    default:
      return state;
  }
};

export const SystemMessageReducer: Reducer = (
  state: { message: string; isOpen: boolean; severity: AlertSeverity } = {
    message: "",
    isOpen: false,
    severity: "success",
  },
  action: UnknownAction
) => {
  if (action.type.indexOf("_FAIL") !== -1) {
    return {
      ...state,
      message: action.error,
      severity: "error",
      isOpen: true,
    };
  }
  switch (action.type) {
    case AllTypeActions.SYSTEM_MESSAGE_SHOW: {
      return {
        ...state,
        message: action.message,
        severity: action.severity ? action.severity : "success",
        isOpen: true,
      };
    }
    case AllTypeActions.SYSTEM_MESSAGE_HIDE: {
      return {
        ...state,
        message: "",
        isOpen: false,
      };
    }
    default:
      return state;
  }
};

export const GetRemainingEnergyReducer: Reducer = (
  state: IAppRequestState = initialRequestState,
  action: UnknownAction
) => {
  return GlobalReducer(
    state,
    action,
    AllTypeActions.GET_REMAINING_ENERGY.toString()
  );
};