import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails, Box, Button, IconButton } from "@mui/material";
import ChevronUpIcon from "../../../../../assets/icons/ChevronUpIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { IApplicationState, IItems, parseParamUrl, removeIntoCart } from "../../../../../store-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../constants";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { extractContent } from "@/helpers";

interface IListSuggestProps {
  handleClickIdea?: (idea: IItems) => void;
  dataOfAnswer?: IItems[];
}

export default function ListSuggest({ handleClickIdea, dataOfAnswer }: IListSuggestProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { categoryId, questionId, aspectId, topicId } = useParams();
  const isFromProfile = categoryId === ":categoryId";
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const [checkDataOfMyIdeaPath, setCheckDataOfMyIdeaPath] = useState<IItems[]>();
  const isPageAnswer = pathname.indexOf("/answer") !== -1;
  const isGenByAI = !!listMyIdeaPath.data?.find((item) => item?.genByAI);
  useEffect(() => {
    if (isFromProfile) {
      setCheckDataOfMyIdeaPath(dataOfAnswer);
    } else {
      setCheckDataOfMyIdeaPath(listMyIdeaPath.data);
    }
  }, [listMyIdeaPath, dataOfAnswer]);

  const handleClick = () => {
    console.log(ROUTES.ANSWER);
    const url = parseParamUrl(ROUTES.ANSWER, {
      questionId,
      categoryId,
      aspectId: isGenByAI ? "genByAI" : aspectId,
      topicId,
    });
    console.log("url", url);
    navigate(url + search);
  };

  const handleRemoveIntoCart = (idea: IItems) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeIntoCart(idea));
      }
    });
  };

  return (
    <Accordion
      sx={{
        borderRadius: "4px",
      }}
    >
      <AccordionSummary
        expandIcon={pathname.indexOf("/answer") !== -1 ? <ExpandMoreIcon /> : <ChevronUpIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          borderRadius: "8px",
          maxHeight: "48px !important",
          minHeight: "48px",
          height: "48px",
          boxShadow: "-1px -9px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "15px",
            marginRight: "20px",
          },
        }}
      >
        My Idea Path ({checkDataOfMyIdeaPath?.length})
        {!isPageAnswer ? (
          <Button
            onClick={() => handleClick()}
            sx={(theme) => ({
              textTransform: "none",
              backgroundColor: "#264653",
              height: "auto",
              borderRadius: "8px",
              fontFamily: "Poppins",
              fontSize: "15px",
              padding: "4px 8px",
              fontWeight: "700",
              color: "white",
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            })}
          >
            Customized
          </Button>
        ) : null}
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          maxHeight: "73vh",
          [theme.breakpoints.up("sm")]: {
            maxHeight: "62vh",
          },
          overflow: "auto",
        })}
      >
        {checkDataOfMyIdeaPath?.map((idea: IItems) => (
          <Box
            key={idea._id}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              border: "1px solid #EDEDED",
              borderRadius: "8px",
              padding: "16px 2px 16px 24px",
              "& .box-icon": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              "& .nameLocationIdea": {
                margin: " auto 6px",
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "700",
                border: "1px solid transparent",
                borderRadius: "50%",
                backgroundColor: "pink",
                padding: " 0 10px",
              },
            }}
          >
            <Box
              key={idea._id}
              onClick={() => isPageAnswer && handleClickIdea?.(idea)}
              sx={() => ({
                width: "100%",
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                position: "relative",
                padding: "5px",
                minHeight: "50px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#ECEAEA",
                  cursor: "pointer",
                },
                ...(editCustomAnswerContent?.data?.indexOf(
                  extractContent(idea.description ?? "").replace(/^.*"(.*?)"[^"]*$/, "$1") ?? ""
                ) !== -1
                  ? {
                      backgroundColor: "rgba(79, 70, 229, 0.7)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(79, 70, 229, 0.7) !important",
                      },
                    }
                  : {}),
              })}
            >
              <Box
                className="title"
                sx={{
                  fontWeight: "bold",
                }}
                dangerouslySetInnerHTML={{ __html: idea.name || "" }}
              />
              <Box
                className="description"
                dangerouslySetInnerHTML={{
                  __html: idea.description || "",
                }}
              />
            </Box>

            <Box
              className="nameLocationIdea"
              dangerouslySetInnerHTML={{ __html: idea.locationIdea == "undefined" ? " " : idea.locationIdea || "" }}
            />

            {isFromProfile ? null : (
              <Box className="box-icon">
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={() => handleRemoveIntoCart(idea)}
                  className="btn-delete"
                  sx={{
                    "&:active": {
                      backgroundColor: "#ECEAEA",
                      cursor: "pointer",
                    },
                    "&:hover": {
                      backgroundColor: "#f2a5a5de",
                      cursor: "pointer",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
