import { Navigate } from "react-router-dom";
import { KeyTokenName } from "../../constants";


interface IPrivateRouteProps {
    children: JSX.Element;
}

export function PrivateRoute({ children }: IPrivateRouteProps) {

    const auth = localStorage.getItem(KeyTokenName);
    if (auth) {
        return children
    } else {
        return <Navigate to="/login" />
    }
}


