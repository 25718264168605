export * from "@/common/types";
import { IUser } from "@/common/types";
import { IItems, IEditCustomAnswerState, IIdeaCombineCategoryAspect, IAnswerItem } from "@/common/types";

export interface IPaging {
  offset: number;
  limit: number;
}

export interface IAppRequestState<T = any> {
  isFetching: boolean;
  data: T;
  error: string;
  total?: number;
}

export const initialRequestState: IAppRequestState<null> = {
  isFetching: false,
  data: null,
  error: "",
  total: 1,
};

export type AlertSeverity = "success" | "info" | "warning" | "error" | undefined;

export interface IApplicationState {
  signin: IAppRequestState;
  currentUser: IAppRequestState<IUser>;
  permission: { allPermissions: any };
  systemMessage: { message: string; isOpen: boolean; severity: AlertSeverity };
  listUser: IAppRequestState<IUser[]>;
  createUser: IAppRequestState;
  updateUser: IAppRequestState;
  deleteUser: IAppRequestState;
  listTopics: IAppRequestState<IItems[]>;
  createTopics: IAppRequestState<IItems[]>;
  createQuestions: IAppRequestState<IItems[]>;
  listQuestions: IAppRequestState<IItems[]>;
  deleteTopic: IAppRequestState;
  deleteQuestion: IAppRequestState;
  updateTopic: IAppRequestState;
  updateQuestion: IAppRequestState;
  detailItem: IAppRequestState<IItems>;
  getListIdeas: IAppRequestState<IItems[]>;
  getListAllGrammarIdeas: IAppRequestState<IItems[]>;
  getListAllStructureIdeas: IAppRequestState<IItems[]>;
  listCategories: IAppRequestState<IItems[]>;
  listAspects: IAppRequestState<IItems[]>;
  createItems: IAppRequestState<IItems[]>;
  deleteItem: IAppRequestState;
  updateItem: IAppRequestState;
  myIdeaPath: IAppRequestState<IItems[]>;
  editCustomAnswer: IEditCustomAnswerState;
  generateIdeas: IAppRequestState;
  generateGrammars: IAppRequestState;
  generateStructures: IAppRequestState;
  saveCustomAnswer: IAppRequestState;
  removeMultipleItems: IAppRequestState;
  listAnswerOfUser: IAppRequestState<IAnswerItem[]>;
  removeAnswerItem: IAppRequestState;
  createLevel: IAppRequestState;
  updateLevel: IAppRequestState;
  listLevel: IAppRequestState<IItems[]>;
  updateOrder: IAppRequestState;
  listAllAspects: IAppRequestState<IItems[]>;
  ideaCombineCategoryAspect: { data: IIdeaCombineCategoryAspect[] };
  wordSearch: IAppRequestState;
  remainingEnergy: IAppRequestState<any>;
  createQuestionCategory: IAppRequestState<IItems[]>;
  createQuestionType: IAppRequestState<IItems[]>;
  updateQuestionCategory: IAppRequestState;
  updateQuestionType: IAppRequestState;
  listQuestionCategory: IAppRequestState<IItems[]>;
  listQuestionType: IAppRequestState<IItems[]>;
  deleteQuestionCategory: IAppRequestState;
  deleteQuestionType: IAppRequestState;
  getListGuidedQuestions: IAppRequestState<IItems[]>;
}
