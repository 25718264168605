import { Box, Grid, Skeleton, Typography, TypographyProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import NoDataComponent from "../../../../components/NoDataComponent";
import { ETypePage, SlugNavigation } from "../../../../components/SlugNavigation";
import { extractContent, genCategoryAspect } from "../../../../helpers";
import {
  ETypeItem,
  IApplicationState,
  IItems,
  addIntoCart,
  getDetailItem,
  getListAllAspects,
  getListCategories,
  getListIdeas,
  removeIntoCart,
} from "../../../../store-redux";
import LayoutLearn from "../LayoutLearn";
function IdeaPage() {
  const dispatch = useDispatch();
  const { questionId, categoryId, aspectId } = useParams();
  const isFromProfile = categoryId === ":categoryId";

  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);

  const listIdea = useSelector((state: IApplicationState) => state.getListIdeas);

  const listIdeas = listIdea.data
    ?.filter((idea) => idea.type === ETypeItem["idea.main"])
    .sort((a, b) => b.score - a.score);

  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);

  const listAllAspectsState = useSelector((state: IApplicationState) => state.listAllAspects);

  const listCategories = useSelector((state: IApplicationState) => state.listCategories);

  const [heightWithUnit, setHeightWithUnit] = useState("0px");
  const [categoryAndAspect, setCategoryAndAspect] = useState<string>();

  useEffect(() => {
    const myBox = document.querySelector(".question");

    if (myBox !== null) {
      const computedStyles = window.getComputedStyle(myBox);

      const newHeightWithUnit = computedStyles.height;
      setHeightWithUnit(newHeightWithUnit);
    }
  }, [detailQuestion]);

  const handleAddOrRemoveIntoCart = (idea: IItems) => {
    console.log(idea);
    if (listMyIdeaPath.data.find((item) => item._id === idea._id)) {
      dispatch(removeIntoCart(idea));
    } else {
      const infoIdea = { ...idea, locationIdea: categoryAndAspect };
      dispatch(addIntoCart(infoIdea));
    }
  };

  const handleGetListAllAspect = () => {
    !isFromProfile &&
      dispatch(
        getListAllAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          parentId: categoryId,
          type: ETypeItem.aspect,
        })
      );
  };

  useEffect(() => {
    let singleNameTag = "☆";
    const findAspect = listAllAspectsState?.data?.find((item) => item._id === aspectId);
    if (findAspect) {
      const findCategory = listCategories?.data?.find((item) => item._id === findAspect.parentId);
      if (findCategory) {
        singleNameTag = genCategoryAspect(findCategory, findAspect);
      }
    }
    setCategoryAndAspect(singleNameTag);
  }, [listIdeas]);

  useEffect(() => {
    if (questionId) {
      dispatch(getDetailItem(questionId));
    }
    dispatch(
      getListIdeas({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        parentId: questionId,
        aspectId: aspectId,
        checkMatchScore: true,
      })
    );

    if (!listCategories?.data) {
      dispatch(
        getListCategories({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          type: ETypeItem.category,
          isOnlyPublic: true,
        })
      );
    }
    if (!listAllAspectsState?.data?.length) {
      handleGetListAllAspect();
    } else {
      const findValueExist = listAllAspectsState?.data?.find((item) => item.parentId === categoryId);
      if (!findValueExist) {
        handleGetListAllAspect();
      }
    }
  }, []);

  const getColorByScore = (score: number): string => {
    return score >= 80 ? "#97BE5A" : score >= 60 ? "#FFAF45" : score >= 40 ? "#FB6D48" : "#FF204E";
  };
  return (
    <LayoutLearn>
      <Box
        sx={() => ({
          width: "100%",
          "& .question": {
            fontSize: "22px",
            marginBottom: "10px",
          },
          "& h3": {
            fontSize: "22px",
            textAlign: "left",
            fontWeight: "bold",
          },
          "& .box-container": {
            position: "relative",
            overflowY: "auto",
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingInline: "8px",
            paddingBottom: listMyIdeaPath?.data?.length ? "56px" : 0,
            maxHeight: `calc(100vh - ${heightWithUnit} - 145px)`,
          },
          "& .box-item": {
            padding: "16px 32px",
            border: "1px solid #EDEDED",
            borderRadius: "8px",
            cursor: "pointer",
            display: "flex",
            "&:active": { backgroundColor: "#ECEAEA" },
          },
        })}
      >
        {detailQuestion.data ? <Box className="question">{extractContent(detailQuestion.data.name)}</Box> : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            "& .locationIdea": {
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: "700",
              border: "1px solid transparent",
              borderRadius: "50%",
              backgroundColor: "pink",
              padding: " 0 10px",
              marginRight: "8px",
            },
          }}
        >
          <SlugNavigation typePage={ETypePage.Idea} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <StructureSuggestBtn /> */}
            <Typography
              sx={{
                marginLeft: "10px",
              }}
              component={"span"}
              className="locationIdea"
            >
              {categoryAndAspect}
            </Typography>
          </Box>
        </Box>
        <Box className="box-container">
          {listIdea.isFetching ? (
            <Grid item xs>
              <div>
                {(["h1", "h1", "h1", "h1", "h1"] as readonly TypographyProps["variant"][]).map((variant, idx) => (
                  <Typography component="div" key={idx} variant={variant}>
                    <Skeleton />
                  </Typography>
                ))}
              </div>
            </Grid>
          ) : (
            listIdeas?.map((idea) => (
              <Box
                onClick={() => handleAddOrRemoveIntoCart(idea)}
                key={idea._id}
                className="box-item"
                sx={{
                  ...(listMyIdeaPath.data.some((item) => idea._id === item._id)
                    ? {
                        backgroundColor: "rgba(79, 70, 229, 0.7)",
                        color: "white",
                      }
                    : {}),
                  position: "relative",
                  ...(idea.score !== undefined ? { borderColor: `${getColorByScore(idea.score)}!important` } : {}),
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                    className="title"
                    dangerouslySetInnerHTML={{ __html: idea.name?.replace(/\d+\.\s*&nbsp;/, "") || "" }}
                  />
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                    className="description"
                    dangerouslySetInnerHTML={{ __html: idea.description || "" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: 60,
                  }}
                >
                  {idea.score !== undefined && (
                    <Box
                      sx={{
                        fontWeight: "700",
                        color: getColorByScore(idea.score),
                      }}
                    >
                      {idea.score}%
                    </Box>
                  )}
                </Box>
              </Box>
            ))
          )}
          {listIdea.data?.length === 0 && !listIdea.isFetching ? <NoDataComponent /> : null}
        </Box>
      </Box>
    </LayoutLearn>
  );
}

export default IdeaPage;
