import { Box, Button, Modal, Typography } from "@mui/material"
import ExtensionIcon from '@mui/icons-material/Extension';
import { NavLink } from "react-router-dom";
const PlayVocaContainer = (props) => {
    const {
        onClose,
        children,
        dataWordbook
    } = props;
    return (
        <Modal
            open={true}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    // borderRadius: "8px",
                    minWidth: {
                      xs: "100vw",
                    //   sm: "500px",
                    },
                    maxWidth: {
                      xs: "100vw",
                    //   sm: "600px",
                    },
                    minHeight: {
                        xs: '100vh'
                    },
                    width: "100%",
                    "& form": {
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    "& h2": {
                        height: "54px",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "54px",
                        marginRight: '10px'
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '& svg': {
                            
                            '& path': {
                                fill: '#264653'
                            }
                        }
                    }}>
                        <ExtensionIcon fontSize="large" />
                        <Typography component={"h2"}>
                            {dataWordbook?.name ?? ""}
                        </Typography>
                    </Box>
                    <Button sx={{
                        minWidth: "68px",
                        height: "30px",
                        padding: "0px 8px",
                        borderRadius: "4px",
                        color: "white",
                        backgroundColor: "#264653",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "30px",
                        textTransform: "none",
                    }} variant="contained" onClick={() => onClose()}>
                        Back
                    </Button>
                </Box>
                {children}
            </Box>
        </Modal>
    )
}

export default PlayVocaContainer;