import { extractContent, getUrlServer, removeLeadingNumbersAndPeriods } from "@/helpers";
import { useGetGroupQuestionPart2All } from "@/services-react-query/queries";
import { Box, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NoDataComponent from "../../../../components/NoDataComponent";
import { ROUTES } from "../../../../constants";
import { ETypeItem, parseParamUrl } from "../../../../store-redux";
import LayoutLearn from "../LayoutLearn";

function GroupPart2Page() {
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: dataListGroup, isSuccess } = useGetGroupQuestionPart2All({
    query: {
      skip: 0,
      limit: Number.MAX_SAFE_INTEGER,
      type: ETypeItem["group.question.part2"],
    },
  });

  const handleGroupClick = (id: string) => {
    const urlQuestion = parseParamUrl(ROUTES.LEARN_QUESTION_PART2, { groupId: id });
    navigate(urlQuestion);
  };

  return (
    <LayoutLearn>
      <Box
        sx={{
          width: "100%",
          "& h3": {
            fontSize: "22px",
            textAlign: "left",
            fontWeight: "bold",
          },
          "& .box-container": {
            overflowY: "auto",
            marginTop: "10px",
            paddingInline: "5px",
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "20px",
            [theme.breakpoints.up("md")]: {
              gridTemplateColumns: "1fr 1fr",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: "1fr 1fr",
            },
            [theme.breakpoints.up("xl")]: {
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            },
            " .title": {
              fontSize: "20px",
              marginBottom: "8px",
            },
            " .image": {
              height: "calc(250px/1.5)",
              width: "250px",
              objectFit: "cover",
              borderRadius: "8px",
            },
          },
          "& .box-item": {
            padding: "16px 32px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#ECEAEA",
              cursor: "pointer",
              " .title": {
                color: "black",
                fontWeight: "600",
              },
            },
          },
        }}
      >
        <Box
          sx={{
            textAlign: "left",
            marginLeft: "16px",
            "& button": {
              height: "40px",
            },
          }}
        >
          <Button onClick={() => navigate(-1)} variant="contained" color="secondary">
            Back
          </Button>
        </Box>
        <Box className="box-container">
          {dataListGroup?.map((topic) => (
            <Box key={topic._id} onClick={() => handleGroupClick(topic._id)} className="box-item">
              <Box className="title">{removeLeadingNumbersAndPeriods(extractContent(topic.name))}</Box>
              {topic.imageUrl ? (
                <img className="image" src={getUrlServer(topic.imageUrl ?? "")} loading="lazy" />
              ) : (
                <Box className="image" />
              )}
            </Box>
          ))}
          {dataListGroup?.length === 0 && isSuccess ? <NoDataComponent /> : null}
        </Box>
      </Box>
    </LayoutLearn>
  );
}

export default GroupPart2Page;
