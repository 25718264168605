import { IMyPracticesOutput } from "@/common";
import { useGetMyPractices } from "@/services-react-query/queries";
import { IApplicationState } from "@/store-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, IconButton, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ListInfoPractice from "../ListInfoPractice";
import DetailPractice from "@/components/DetailPractice";
import CloseIcon from "@mui/icons-material/Close";
import { extractContent, getUrlServer } from "@/helpers";

function PracticeTab() {
  const listTopic = useSelector((state: IApplicationState) => state.listTopics);
  const [showDetail, setShowDetail] = useState<any>(null);

  const { data: dataMyPractices } = useGetMyPractices({
    onSuccess: () => {},
  });

  const listTopicExist = useMemo(() => {
    const valueTemp = listTopic?.data?.filter((item) => {
      const findItem = dataMyPractices?.find(
        (practice: IMyPracticesOutput) => practice.questionId.parentId === item._id
      );
      return !!findItem;
    });
    return valueTemp;
  }, [dataMyPractices, listTopic?.data]);

  const handleClick = (value: IMyPracticesOutput) => {
    setShowDetail(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflowY: "auto",
        paddingRight: "10px",
      }}
    >
      {listTopicExist?.map((item) => (
        <Accordion
          sx={{
            borderRadius: "4px",
            // maxHeight: "calc(100vh - 266px)",
            height: "auto",
            margin: "0px !important",
          }}
          key={item._id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              borderRadius: "8px",
              maxHeight: "48px !important",
              minHeight: "48px",
              height: "48px",
              boxShadow: "-1px -9px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiAccordionSummary-content": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "15px",
                marginRight: "20px",
              },
              "& h6": {
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                fontSize: "16px",
                fontWeight: "600",
              },
            }}
          >
            <Box
              component={"h6"}
              key={item._id}
              dangerouslySetInnerHTML={{
                __html: `${item.name} (${
                  dataMyPractices?.filter((practice: IMyPracticesOutput) => practice?.questionId?.parentId === item._id)
                    .length
                })`,
              }}
            ></Box>
          </AccordionSummary>
          <AccordionDetails
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              maxHeight: "73vh",
              [theme.breakpoints.up("sm")]: {
                maxHeight: "62vh",
              },
              overflow: "auto",
            })}
          >
            <ListInfoPractice idTopics={item._id} handleClick={handleClick} dataMyPractices={dataMyPractices} />
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        open={Boolean(showDetail)}
        onClose={() => {
          setShowDetail(null);
        }}
        PaperProps={{
          sx: {
            minWidth: "80vw",
            minHeight: "70vh",
            position: "relative",
          },
        }}
      >
        <Box
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflow: "auto",
            flex: "1",
            padding: "32px",
            "& h2": {
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "500",
              marginTop: "16px",
              marginBottom: "32px",
            },
            "& .btn-close": {
              position: "absolute",
              top: "20px",
              right: "20px",
              backgroundColor: "white",
            },
            "& .MuiTableContainer-root": {
              marginBottom: "32px",
            },
          })}
        >
          <IconButton
            className="btn-close"
            onClick={() => {
              setShowDetail(null);
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography component={"h2"}>{extractContent(showDetail?.questionId?.name ?? "")}</Typography>
          {showDetail?.voicePath ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <audio src={`${getUrlServer(showDetail?.voicePath)}`} controls></audio>
            </Box>
          ) : null}
          {showDetail?.voiceTranscript && (
            <DetailPractice voiceTranscript={showDetail.voiceTranscript} gptEvaluates={showDetail?.result ?? []} />
          )}
        </Box>
      </Dialog>
    </Box>
  );
}

export default PracticeTab;
