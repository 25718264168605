import WordDetail from "@/pages/LearnPage/components/WordSearch/WordDetail";
import { Box, Card } from "@mui/material";

const FlashCardItemBack = (props) => {
  const { item } = props;
  const word = item?.word;
  return (
    <Card
      sx={{
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          height: "400px",
          width: "320px",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          padding: "10px",
        }}
      >
        {word ? <WordDetail word={word} isHidePhonetic={true} /> : <></>}
      </Box>
    </Card>
  );
};

export default FlashCardItemBack;
