import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { IDatePickerProps } from "./types";

export default function Datepicker(props: IDatePickerProps): ReactElement {
  const { control, rules, handleChange, controllerExtras = {}, ...other } = props;
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // disableToolbar
            slotProps={{
              textField: (params: any) => ({
                ...other,
                ...params,
                error: error,
              }),
            }}
            onChange={(date: any) => {
              handleChange && handleChange(date);
              onChange(date);
            }}
            format="dd-MM-yyyy"
            {...other}
            value={value || null}
          />
        </LocalizationProvider>
      )}
      name={other.name}
      rules={rules}
      control={control}
      {...controllerExtras}
    />
  );
}
