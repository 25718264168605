import { Box, Button, CircularProgress, IconButton, Input, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import AddIcon from "@mui/icons-material/Add";

const AddInvoiceEnergyBtn = ({ userId, onRefresh }) => {
  const [addInvoiceEl, setAddInvoiceEl] = useState(null);
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onSave = async () => {
    if (amount.trim() === "") return;
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.ADD_INVOICE_ENERGY);
    try {
      await Requests.post(apiUrl, {
        user: userId,
        amount,
      });
      setIsLoading(false);
      onRefresh();
    } catch (e) {}
    setIsLoading(false);
  };
  return (
    <>
      <IconButton
        sx={{
          textTransform: "unset",
          marginLeft: "5px",
        }}
        onClick={(event) => setAddInvoiceEl(event.currentTarget)}
      >
        <AddIcon fontSize="small" />
      </IconButton>
      <Popover
        open={!!addInvoiceEl}
        anchorEl={addInvoiceEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            Add invoice energy
          </Typography>
          <Input
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "2px",
              margin: "10px 0",
              "&::after": {
                display: "none",
              },
              "&::before": {
                display: "none",
              },
            }}
            type="number"
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{
              textTransform: "initial",
              marginRight: "10px",
            }}
            onClick={() => onSave()}
            disabled={isLoading || amount.trim() === ""}
          >
            {isLoading && <CircularProgress />}
            Submit
          </Button>
        </Box>
      </Popover>
    </>
  );
};
export default AddInvoiceEnergyBtn;
