import { Grid } from "@mui/material";
import VocabsCard from "../VocabCard";

const ListVocabsGridView = (props) => {
    const {items, handleClickAction} = props;
    return (
        <Grid container sx={{
            maxHeight: 'calc(100vh - 180px)',
            overflowY: 'auto'
        }}>
            {items.map(item => {
                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{
                        padding: '10px',
                    }}>
                        <VocabsCard item={item} handleClickAction={(typeAction) => handleClickAction(typeAction, item)}/>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ListVocabsGridView;