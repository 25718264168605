import { Box, CircularProgress, IconButton, Popover } from "@mui/material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { extractContent, refinePrompt } from "@/helpers";
import { useEffect, useState, MouseEvent } from "react";

interface SuggestAnswerBtnProps {
  inputDisabled: boolean;
  question: { name: string };
  guidedQuestion: { name: string };
  onChoiceSuggest: (item: string) => void;
  name?: string;
}

const SuggestAnswerBtn: React.FC<SuggestAnswerBtnProps> = ({
  inputDisabled,
  question,
  guidedQuestion,
  onChoiceSuggest,
  name,
}) => {
  const [suggestEl, setSuggestEl] = useState<HTMLButtonElement | null>(null);
  const [items, setItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSuggest = async (e: MouseEvent<HTMLButtonElement>) => {
    setSuggestEl(e.currentTarget);
    if (items.length > 0) return;
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.SUGGEST_ANSWER_GUIDED_QUESTION);
    try {
      const responseItems = await Requests.post<string[]>(apiUrl, {
        question: refinePrompt(extractContent(question.name)),
        guidedQuestion: refinePrompt(extractContent(guidedQuestion.name)),
        name: name ?? "",
      });
      setItems(responseItems);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setItems([]);
  }, [guidedQuestion]);

  return (
    <>
      <IconButton
        sx={{
          margin: "0 5px",
        }}
        onClick={(event) => onSuggest(event)}
        disabled={inputDisabled || isLoading}
      >
        <TipsAndUpdatesIcon
          sx={
            inputDisabled
              ? {}
              : {
                  color: "#264653",
                }
          }
        />
      </IconButton>
      <Popover
        open={!!suggestEl && items.length > 0}
        anchorEl={suggestEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={() => setSuggestEl(null)}
      >
        <Box
          sx={{
            padding: "10px",
          }}
        >
          {items.map((item) => (
            <Box
              key={item}
              sx={{
                padding: "5px 10px",
                cursor: "pointer",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: "#ECEAEA",
                },
              }}
              onClick={() => {
                setSuggestEl(null);
                setTimeout(() => {
                  onChoiceSuggest(item);
                }, 500);
              }}
            >
              {item}
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SuggestAnswerBtn;
