import { EAction, ETypeItem, ICreateItem, IItems } from "@/common";
import CreateEditItemModal, { IFormDataProps } from "@/components/CreateEditItemModal";
import { MenuAction } from "@/components/MenuAction";
import {
  AllTypeActions,
  createQuestionCategory,
  createQuestions,
  createQuestionType,
  deleteQuestion,
  deleteQuestionCategory,
  deleteQuestionType,
  getListQuestionCategory,
  getListQuestions,
  getListQuestionType,
  IApplicationState,
  resetData,
  updateQuestion,
  updateQuestionCategory,
  updateQuestionType,
} from "@/store-redux";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IHandleAction } from "../TopicsQuestionsPage/interface";
import Swal from "sweetalert2";
import NoDataComponent from "@/components/NoDataComponent";
import { styles } from "../TopicsQuestionsPage/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LinkingQuestionModal from "./LinkingQuestionModal";
import { useUpdateItem } from "@/services-react-query/queries";
import { ROUTES } from "@/constants";
import { useNavigate } from "react-router-dom";
import { extractContent } from "@/helpers";
const QuestionTypeCMSPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedQuestionCategoryId, setSelectedQuestionCategoryId] = useState<string | null>(null);
  const [selectedQuestionTypeId, setSelectedQuestionTypeId] = useState<string | null>(null);
  const [openModalCreate, setOpenModalCreate] = useState<{
    type: ETypeItem;
    open: boolean;
    data?: IItems;
  }>({
    type: ETypeItem.topic,
    open: false,
  });
  const [openLinkingQuestion, setOpenLinkingQuestion] = useState(false);
  const { mutate: updateItem } = useUpdateItem({
    onSuccess: () => {
      handleGetListQuestions();
    },
  });

  const listQuestionCategory = useSelector((state: IApplicationState) => state.listQuestionCategory);
  const listQuestionType = useSelector((state: IApplicationState) => state.listQuestionType);
  const listQuestions = useSelector((state: IApplicationState) => state.listQuestions);
  const createQuestionCategoryResult = useSelector((state: IApplicationState) => state.createQuestionCategory);
  const createQuestionTypeResult = useSelector((state: IApplicationState) => state.createQuestionType);
  const createQuestionsResult = useSelector((state: IApplicationState) => state.createQuestions);
  const deleteQuestionCategoryResult = useSelector((state: IApplicationState) => state.deleteQuestionCategory);
  const deleteQuestionTypeResult = useSelector((state: IApplicationState) => state.deleteQuestionType);
  const deleteQuestionResult = useSelector((state: IApplicationState) => state.deleteQuestion);
  const updateQuestionCategoryResult = useSelector((state: IApplicationState) => state.updateQuestionCategory);
  const updateQuestionTypeResult = useSelector((state: IApplicationState) => state.updateQuestionType);
  const updateQuestionResult = useSelector((state: IApplicationState) => state.updateQuestion);

  const handleOpenCreate = (type: ETypeItem) => {
    setOpenModalCreate({
      type,
      open: true,
    });
  };

  const handleCloseModal = () => {
    setOpenModalCreate({
      type: ETypeItem.topic,
      open: false,
    });
  };

  const handleGetListQuestionCategory = () => {
    dispatch(
      getListQuestionCategory({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem["question.category"],
      })
    );
  };
  const handleGetListQuestionType = () => {
    dispatch(
      getListQuestionType({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        parentId: selectedQuestionCategoryId,
        sortBy: "createdAt",
      })
    );
  };

  useEffect(() => {
    handleGetListQuestionCategory();
  }, []);

  const handleCreateEdit = async (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item) => ({
      name: item.title,
      description: item.description,
      type: openModalCreate.type,
      parentId: openModalCreate.type === ETypeItem["question.type"] ? selectedQuestionCategoryId : "",
      questionTypeId: openModalCreate.type === ETypeItem.question ? selectedQuestionTypeId : "",
      imageUrl: item.imageUrl,
      ...(item.file ? { file: item.file } : {}),
      ...(openModalCreate.type === ETypeItem.question
        ? {
            public: item.public ?? false,
          }
        : {}),
    }));
    if (openModalCreate.data) {
      if (openModalCreate.type === ETypeItem["question.category"]) {
        dispatch(updateQuestionCategory({ ...dataSend?.[0], id: openModalCreate.data._id }));
      } else if (openModalCreate.type === ETypeItem["question.type"]) {
        dispatch(updateQuestionType({ ...dataSend?.[0], id: openModalCreate.data._id }));
      } else if (openModalCreate.type === ETypeItem.question) {
        dispatch(updateQuestion({ ...dataSend?.[0], id: openModalCreate.data._id }));
      }
    } else {
      if (openModalCreate.type === ETypeItem["question.category"]) {
        dispatch(createQuestionCategory({ items: dataSend }));
      } else if (openModalCreate.type === ETypeItem["question.type"]) {
        dispatch(createQuestionType({ items: dataSend }));
      } else if (openModalCreate.type === ETypeItem.question) {
        dispatch(createQuestions({ items: dataSend }));
      }
    }
  };

  const handleAction = ({ type, typeAction, data }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === ETypeItem["question.category"]) {
            dispatch(deleteQuestionCategory(data._id));
          } else if (type === ETypeItem["question.type"]) {
            dispatch(deleteQuestionType(data._id));
          } else if (type === ETypeItem.question) {
            dispatch(deleteQuestion(data._id));
          }
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        type,
        open: true,
        data,
      });
    }
  };

  const handleUnlink = (question: IItems) => {
    updateItem({
      ...question,
      description: question.description || "",
      id: question._id,
      questionTypeId: "",
    });
  };

  // CREATE
  useEffect(() => {
    if (createQuestionCategoryResult.data && !createQuestionCategoryResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_QUESTION_CATEGORY));
      handleGetListQuestionCategory();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuestionCategoryResult]);

  useEffect(() => {
    if (createQuestionTypeResult.data && !createQuestionTypeResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_QUESTION_TYPE));
      handleGetListQuestionType();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuestionTypeResult]);

  useEffect(() => {
    if (createQuestionsResult.data && !createQuestionsResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_QUESTIONS));
      handleGetListQuestions();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuestionsResult]);

  // DELETE

  useEffect(() => {
    if (deleteQuestionCategoryResult.data && !deleteQuestionCategoryResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_QUESTION_CATEGORY));
      handleGetListQuestionCategory();
      setSelectedQuestionCategoryId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuestionCategoryResult]);
  useEffect(() => {
    if (deleteQuestionTypeResult.data && !deleteQuestionTypeResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_QUESTION_TYPE));
      handleGetListQuestionType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuestionTypeResult]);
  useEffect(() => {
    if (deleteQuestionResult.data && !deleteQuestionResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_QUESTION));
      handleGetListQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuestionResult]);

  // UPDATE
  useEffect(() => {
    if (updateQuestionCategoryResult.data && !updateQuestionCategoryResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_QUESTION_CATEGORY));
      handleGetListQuestionCategory();
      handleCloseModal();
      setSelectedQuestionCategoryId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQuestionCategoryResult]);
  useEffect(() => {
    if (updateQuestionTypeResult.data && !updateQuestionTypeResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_QUESTION_TYPE));
      handleGetListQuestionType();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQuestionTypeResult]);

  useEffect(() => {
    if (updateQuestionResult.data && !updateQuestionResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_QUESTION));
      handleGetListQuestions();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQuestionResult]);

  const handleQuestionCategoryClick = (questionCategory: IItems) => {
    setSelectedQuestionCategoryId(questionCategory._id);
    if (questionCategory._id !== selectedQuestionCategoryId) {
      dispatch(
        getListQuestionType({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          parentId: questionCategory._id,
        })
      );
    }
  };

  const handleQuestionTypeClick = (questionType: IItems) => {
    setSelectedQuestionTypeId(questionType._id);
    if (questionType._id !== selectedQuestionTypeId) {
      dispatch(
        getListQuestions({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          questionTypeId: questionType._id,
          parentId: "*",
        })
      );
    }
  };

  const handleGetListQuestions = () => {
    dispatch(
      getListQuestions({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        parentId: "*",
        questionTypeId: selectedQuestionTypeId,
        sortBy: "createdAt",
      })
    );
  };

  const selectedQuestionCategory = useMemo(() => {
    return listQuestionCategory.data?.find((questionCategory) => questionCategory._id === selectedQuestionCategoryId);
  }, [selectedQuestionCategoryId]);
  const selectedQuestionType = useMemo(() => {
    return listQuestionType.data?.find((questionType) => questionType._id === selectedQuestionTypeId);
  }, [selectedQuestionTypeId]);
  return (
    <>
      <Box
        sx={{
          ...styles.boxContainer,
          ...(selectedQuestionCategoryId && selectedQuestionTypeId ? { position: "relative" } : {}),
        }}
      >
        {selectedQuestionCategoryId && selectedQuestionTypeId && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "#fff",
              zIndex: 999,
            }}
          >
            <Box
              sx={{
                display: "flex",
                paddingRight: "10px",
                alignItems: "center",
                marginBottom: "16px",
                "& button": {
                  marginBottom: 0,
                },
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: "unset",
                  marginRight: "20px",
                }}
                onClick={() => setSelectedQuestionTypeId(null)}
              >
                Back
              </Button>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  // alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    fontWeight: "bold",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: selectedQuestionCategory?.name ?? "",
                  }}
                ></Box>
                <Typography
                  sx={{
                    margin: "0 10px",
                    fontWeight: "bold",
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Typography>
                <Box
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {extractContent(selectedQuestionType?.name ?? "")}
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    textTransform: "unset",
                    marginRight: "8px",
                  }}
                  onClick={() => setOpenLinkingQuestion(true)}
                >
                  Linking question
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    textTransform: "unset",
                  }}
                  onClick={() => handleOpenCreate(ETypeItem.question)}
                >
                  Create question
                </Button>
              </Box>
            </Box>
            <Box>
              <Box className="box-item">
                {listQuestions.data?.map((question) => {
                  return (
                    <Box
                      key={question._id}
                      sx={{
                        ...styles.styleItem,
                        "& .title": {
                          display: "flex",
                          "& .count": {
                            marginLeft: "5px",
                          },
                        },
                      }}
                      onClick={() => {
                        navigate(`${ROUTES.QUESTION_IDEA.replace(":id", question._id)}`);
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginRight: "50px",
                        }}
                      >
                        <Box
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: question.name,
                          }}
                        />
                        {question.public && <Chip label={"Public"} size="small" color="success" />}
                      </Box>

                      {question.parentId ? (
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "unset", marginLeft: "-20px" }}
                          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            event?.stopPropagation();
                            handleUnlink(question);
                          }}
                          color="warning"
                        >
                          Unlink
                        </Button>
                      ) : (
                        <MenuAction
                          handleClick={(typeAction: EAction) => {
                            // e.stopPropagation();
                            handleAction({
                              typeAction,
                              type: ETypeItem.question,
                              data: question,
                            });
                          }}
                        />
                      )}
                    </Box>
                  );
                })}
                {listQuestions.data?.length === 0 && !listQuestions.isFetching ? <NoDataComponent /> : null}
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: "300px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: 20,
                marginBottom: "10px",
              }}
            >
              Categories
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "unset",
              }}
              onClick={() => handleOpenCreate(ETypeItem["question.category"])}
            >
              Create
            </Button>
          </Box>
          <Box className="box-item">
            {listQuestionCategory.data?.map((questionCategory) => {
              return (
                <Box
                  key={questionCategory._id}
                  onClick={() => {
                    handleQuestionCategoryClick(questionCategory);
                  }}
                  sx={{
                    ...styles.styleItem,
                    backgroundColor: selectedQuestionCategoryId === questionCategory._id ? "lightblue" : "transparent",
                    "& .title": {
                      display: "flex",
                      "& .count": {
                        marginLeft: "5px",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Box
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html:
                          questionCategory.name +
                            `${
                              questionCategory.childrenCount > 0
                                ? `<b class="count">(${questionCategory.childrenCount})</b>`
                                : ""
                            }` || "",
                      }}
                    />
                  </Box>
                  {/* <Box
                    className="description"
                    sx={{
                      flex: 1,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: questionCategory.description || "",
                    }}
                  /> */}
                  <MenuAction
                    handleClick={(typeAction: EAction) => {
                      // e.stopPropagation();
                      handleAction({
                        typeAction,
                        type: ETypeItem["question.category"],
                        data: questionCategory,
                      });
                    }}
                  />
                </Box>
              );
            })}
            {listQuestionCategory.data?.length === 0 && !listQuestionCategory.isFetching ? <NoDataComponent /> : null}
          </Box>
        </Box>
        {selectedQuestionCategoryId && (
          <Box
            sx={{
              flex: 1,
              paddingRight: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: 20,
                  marginBottom: "10px",
                }}
              >
                Question Types
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: "unset",
                }}
                onClick={() => handleOpenCreate(ETypeItem["question.type"])}
              >
                Create
              </Button>
            </Box>
            <Box className="box-item">
              {listQuestionType.data?.map((questionType) => {
                return (
                  <Box
                    key={questionType._id}
                    onClick={() => {
                      handleQuestionTypeClick(questionType);
                    }}
                    sx={{
                      ...styles.styleItem,
                      "& .title": {
                        display: "flex",
                        "& .count": {
                          marginLeft: "5px",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Box
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html:
                            questionType.name +
                              `${
                                questionType.childrenCount > 0
                                  ? `<b class="count">(${questionType.childrenCount})</b>`
                                  : ""
                              }` || "",
                        }}
                      />
                    </Box>
                    <MenuAction
                      handleClick={(typeAction: EAction) => {
                        // e.stopPropagation();
                        handleAction({
                          typeAction,
                          type: ETypeItem["question.type"],
                          data: questionType,
                        });
                      }}
                    />
                  </Box>
                );
              })}
              {listQuestionType.data?.length === 0 && !listQuestionType.isFetching ? <NoDataComponent /> : null}
            </Box>
          </Box>
        )}
      </Box>
      <CreateEditItemModal
        handleCreateEdit={handleCreateEdit}
        openModal={openModalCreate.open}
        handleCloseModal={handleCloseModal}
        isLoading={createQuestionCategoryResult.isFetching || createQuestionTypeResult.isFetching}
        titleModal={
          openModalCreate?.data
            ? openModalCreate.type === ETypeItem["question.category"]
              ? `Edit question category`
              : openModalCreate.type === ETypeItem["question.type"]
              ? `Edit question type`
              : `Edit question`
            : openModalCreate.type === ETypeItem["question.category"]
            ? `Create question categories`
            : openModalCreate.type === ETypeItem["question.type"]
            ? `Create question types`
            : `Create questions`
        }
        data={openModalCreate.data}
        isQuestion={openModalCreate.type === ETypeItem.question}
      />
      <LinkingQuestionModal
        open={openLinkingQuestion}
        handleClose={() => setOpenLinkingQuestion(false)}
        questionTypeId={selectedQuestionTypeId ?? ""}
        refetchQuestion={() => handleGetListQuestions()}
      />
    </>
  );
};

export default QuestionTypeCMSPage;
