import { Box, Button, CircularProgress, IconButton, Input } from "@mui/material";
import SunEditor from "suneditor-react";
import DeleteIcon from "@mui/icons-material/Delete";
import { extractContent, refinePrompt } from "@/helpers";
import { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
interface ListIdeaAnswerBoxProps {
  question?: IItems;
  guidedQuestions?: any[];
  answersByAspect?: any;
  name?: string;
  guidedQuestionsSelected: any[];
  removeGuidedQuestion?: (id: string) => void;
  onAddConnectorDone: (content: string) => void;
}

const ListIdeaAnswerBoxProps = (props: ListIdeaAnswerBoxProps) => {
  const [contentAnswer, setContentAnswer] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onAddConnector = async () => {
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.ADD_CONNECTOR_ANSWER_PART_TWO);
    try {
      const dataRes = await Requests.post<string>(apiUrl, {
        question: refinePrompt(extractContent(props.question?.name ?? "")),
        answers: Object.keys(contentAnswer).map((key) => contentAnswer[key]),
        name: props.name,
      });
      props.onAddConnectorDone(dataRes);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  if (props.guidedQuestionsSelected.length === 0) return <></>;
  return (
    <Box>
      {props.guidedQuestionsSelected.map((item) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
            key={item._id}
          >
            <Box
              sx={{
                flex: 1,
                maxWidth: "calc(100% - 40px)",
                "& .se-wrapper-inner": {
                  padding: "5px",
                  minHeight: "unset!important",
                  "& p": {
                    fontSize: "16px",
                  },
                },
                "& .se-resizing-bar": {
                  display: "none",
                },
              }}
            >
              <SunEditor
                hideToolbar
                setContents={props.answersByAspect[item.aspect?._id]}
                onChange={(content) => {
                  const contentFormat = extractContent(content);
                  setContentAnswer((prev) => ({
                    ...prev,
                    [item.aspect?._id]: contentFormat,
                  }));
                }}
              />
            </Box>
            <Box
              sx={{
                width: "40px",
              }}
            >
              <IconButton
                onClick={() => {
                  props.removeGuidedQuestion(item._id);
                  setContentAnswer((prev) => {
                    const tempPrev = { ...prev };
                    delete tempPrev[item._id];
                    return tempPrev;
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#79c745",
          }}
          variant="contained"
          onClick={() => onAddConnector()}
          color="secondary"
          onClickCapture={() => {}}
        >
          {isLoading && (
            <CircularProgress
              size={20}
              sx={{
                marginRight: "10px",
              }}
            />
          )}
          <LinkIcon />
          Add connector
        </Button>
      </Box>
    </Box>
  );
};
export default ListIdeaAnswerBoxProps;
