import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Modal, TextField, Typography } from "@mui/material";
import { IEditInfoUser, IUser } from "../../../../store-redux";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

interface IModalAddEditUserProps {
  open: boolean;
  onClose: () => void;
  onSave: (user: IEditInfoUser) => void;
  loading: boolean;
  titleModal: string;
  data?: IUser;
}

export interface IFormData extends Omit<IUser, "_id"> {
  password: string;
}

export default function ModalAddEditUserProfile({
  open,
  onClose,
  onSave,
  loading,
  titleModal,
  data,
}: IModalAddEditUserProps) {
  const { control, handleSubmit, reset, watch } = useForm<IEditInfoUser>({
    defaultValues: {
      username: "",
      password: "",
      newPassword: "",
      isChangePassword: false,
    },
  });
  const isChangePassword = watch("isChangePassword");
  useEffect(() => {
    if (open) {
      reset({
        username: data?.username || "",
        email: data?.email || "",
        password: "",
        newPassword: "",
        isChangePassword: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reset, data]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          minWidth: {
            xs: "100vw",
            sm: "500px",
          },
          maxWidth: {
            xs: "100vw",
            sm: "600px",
          },
          width: "100%",
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSave)}>
          <Typography variant="h5">{titleModal}</Typography>
          <Controller
            name="username"
            control={control as any}
            render={({ field }: { field: any }) => <TextField {...field} label="Username" />}
          />
          <Controller
            name="email"
            control={control as any}
            render={({ field }: { field: any }) => <TextField {...field} label="Email" type="email" required />}
            disabled
          />
          <Controller
            name="isChangePassword"
            control={control as any}
            render={({ field }: { field: any }) => (
              <FormControlLabel label="Change password" control={<Checkbox {...field} />} />
            )}
          />
          {isChangePassword && (
            <>
              <Controller
                name="password"
                control={control as any}
                render={({ field }: { field: any }) => <TextField {...field} label="Password" required />}
              />

              <Controller
                name="newPassword"
                control={control as any}
                render={({ field }: { field: any }) => <TextField {...field} label="New Password" required />}
              />
            </>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" disabled={loading}>
              {loading ? <CircularProgress size={30} /> : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
