import { IAppRequestState } from "../interface";

export const GlobalReducer = (
  state: IAppRequestState,
  action: any,
  actionType: string
): IAppRequestState => {
  switch (action.type) {
    case `${actionType}_ATTEMPT`: {
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    }
    case `${actionType}_SUCCESS`: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: "",
        total: action.total ? action.total : state.total,
      };
    }
    case `${actionType}_FAIL`: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        data: null,
      };
    }
    default:
      return state;
  }
};
