import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { isArray } from "lodash";

const GrammarItem = (props) => {
  const theme = useTheme();
  const { item } = props;
  const grammarJson = JSON.parse(item.grammar);
  return (
    <Box
      sx={{
        borderBottom: "1px solid #0000001f",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          marginBottom: "5px",
        }}
      >
        <Typography>{item.content}</Typography>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              "& thead": {
                backgroundColor: theme.palette.secondary.light,
                position: "sticky",
                top: "0",
                "& th": {
                  fontSize: "16px",
                  color: "white",
                },
              },
              "& td": {
                fontSize: "16px",
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Example</TableCell>
                <TableCell>Grammar</TableCell>
                <TableCell>Explain</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArray(grammarJson) &&
                grammarJson?.map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Box dangerouslySetInnerHTML={{ __html: row.example }} />
                    </TableCell>
                    <TableCell>{row.grammar}</TableCell>
                    <TableCell>
                      <Box dangerouslySetInnerHTML={{ __html: row.explain }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default GrammarItem;
