import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CreateEditItemModal, { IFormDataProps } from "../../../components/CreateEditItemModal";
import { MenuAction } from "../../../components/MenuAction";
import NoDataComponent from "../../../components/NoDataComponent";
import {
  AllTypeActions,
  EAction,
  ETypeItem,
  IApplicationState,
  ICreateItem,
  IItems,
  createQuestions,
  createTopics,
  deleteQuestion,
  deleteTopic,
  getListQuestions,
  getListTopics,
  resetData,
  showMessage,
  updateQuestion,
  updateTopic,
} from "../../../store-redux";
import SortQuestions from "./components/SortQuestions";
import { IHandleAction } from "./interface";
import { styles } from "./styles";
import { getUrlServer } from "@/helpers";

interface ITopicQuestionsPageProps {
  isPageChallenge?: boolean;
  onHandleClickQuestion?: (id: string) => void;
}

const TopicQuestionsPage = ({ isPageChallenge, onHandleClickQuestion }: ITopicQuestionsPageProps) => {
  const dispatch = useDispatch();
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null);
  const [openModalCreate, setOpenModalCreate] = useState<{
    type: ETypeItem;
    open: boolean;
    data?: IItems;
  }>({
    type: ETypeItem.topic,
    open: false,
  });

  const listTopics = useSelector((state: IApplicationState) => state.listTopics);
  const createTopicsResult = useSelector((state: IApplicationState) => state.createTopics);
  const listQuestions = useSelector((state: IApplicationState) => state.listQuestions);
  const createQuestionsResult = useSelector((state: IApplicationState) => state.createQuestions);
  const deleteTopicResult = useSelector((state: IApplicationState) => state.deleteTopic);
  const deleteQuestionResult = useSelector((state: IApplicationState) => state.deleteQuestion);
  const updateTopicResult = useSelector((state: IApplicationState) => state.updateTopic);
  const updateQuestionResult = useSelector((state: IApplicationState) => state.updateQuestion);

  const handleOpenCreate = (type: ETypeItem) => {
    setOpenModalCreate({
      type,
      open: true,
    });
  };

  const handleCloseModal = () => {
    setOpenModalCreate({
      type: ETypeItem.topic,
      open: false,
    });
  };

  const handleCreateEdit = async (data: IFormDataProps) => {
    const dataSend: ICreateItem["items"] = data?.values?.map((item) => ({
      name: item.title,
      description: item.description,
      type: openModalCreate.type,
      parentId: openModalCreate.type === ETypeItem.question ? selectedTopicId : "",
      imageUrl: item.imageUrl,
      ...(item.file ? { file: item.file } : {}),
      ...(openModalCreate.type === ETypeItem.question
        ? {
            public: item.public ?? false,
          }
        : {}),
    }));
    if (openModalCreate.data) {
      if (openModalCreate.type === ETypeItem.topic) {
        dispatch(updateTopic({ ...dataSend?.[0], id: openModalCreate.data._id }));
      } else {
        dispatch(updateQuestion({ ...dataSend?.[0], id: openModalCreate.data._id }));
      }
    } else {
      if (openModalCreate.type === ETypeItem.topic) {
        dispatch(createTopics({ items: dataSend }));
      } else {
        dispatch(createQuestions({ items: dataSend }));
      }
    }
  };

  const handleTopicClick = (topic: IItems) => {
    setSelectedTopicId(topic._id);
    if (topic._id !== selectedTopicId) {
      dispatch(
        getListQuestions({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          parentId: topic._id,
        })
      );
    }
  };

  const handleGetListTopics = () => {
    dispatch(
      getListTopics({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.topic,
      })
    );
  };
  const handleGetListQuestions = () => {
    dispatch(
      getListQuestions({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        parentId: selectedTopicId,
        sortBy: "createdAt",
      })
    );
  };

  const handleAction = ({ type, typeAction, data }: IHandleAction) => {
    if (typeAction === EAction.delete) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === ETypeItem.topic) {
            dispatch(deleteTopic(data._id));
          } else if (type === ETypeItem.question) {
            dispatch(deleteQuestion(data._id));
          }
        }
      });
    } else if (typeAction === EAction.edit) {
      setOpenModalCreate({
        type,
        open: true,
        data,
      });
    }
  };

  useEffect(() => {
    handleGetListTopics();
  }, []);

  // CREATE
  useEffect(() => {
    if (createTopicsResult.data && !createTopicsResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_TOPICS));
      handleGetListTopics();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTopicsResult]);

  useEffect(() => {
    if (createQuestionsResult.data && !createQuestionsResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_QUESTIONS));
      handleGetListQuestions();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuestionsResult]);

  // DELETE

  useEffect(() => {
    if (deleteTopicResult.data && !deleteTopicResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_TOPIC));
      handleGetListTopics();
      setSelectedTopicId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTopicResult]);
  useEffect(() => {
    if (deleteQuestionResult.data && !deleteQuestionResult.error) {
      dispatch(resetData(AllTypeActions.DELETE_QUESTION));
      handleGetListQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuestionResult]);

  // UPDATE
  useEffect(() => {
    if (updateTopicResult.data && !updateTopicResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_TOPIC));
      handleGetListTopics();
      handleCloseModal();
      setSelectedTopicId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTopicResult]);
  useEffect(() => {
    if (updateQuestionResult.data && !updateQuestionResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_QUESTION));
      handleGetListQuestions();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQuestionResult]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      })}
    >
      {listTopics.isFetching ? <LinearProgress color="secondary" style={{ marginRight: 10 }} /> : null}
      <Box sx={styles.boxContainer}>
        <Box width={"320px"}>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h3">Topics</Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenCreate(ETypeItem.topic)}
              color="secondary"
              disabled={isPageChallenge}
            >
              Create Topic
            </Button>
          </Box>
          <Box className="box-item">
            {listTopics.data?.map((topic) => (
              <Box
                key={topic._id}
                onClick={() => {
                  handleTopicClick(topic);
                  if (onHandleClickQuestion !== undefined) {
                    onHandleClickQuestion("");
                    setSelectedQuestionId(null);
                  }
                }}
                sx={{
                  ...styles.styleItem,
                  backgroundColor: selectedTopicId === topic._id ? "lightblue" : "transparent",
                  "& .title": {
                    display: "flex",
                    "& .count": {
                      marginLeft: "5px",
                    },
                  },
                }}
              >
                <Box
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      topic.name +
                        `${topic.childrenCount > 0 ? `<b class="count">(${topic.childrenCount})</b>` : ""}` || "",
                  }}
                />
                <Box
                  className="description"
                  sx={{
                    flex: 1,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: topic.description || "",
                  }}
                />
                <Box
                  sx={{
                    maxWidth: "40px",
                    marginRight: "40px",
                    "& img": {
                      maxWidth: "100%",
                    },
                  }}
                >
                  {topic.imageUrl && <img src={getUrlServer(topic.imageUrl)} />}
                </Box>
                {!isPageChallenge && (
                  <MenuAction
                    handleClick={(typeAction: EAction) => {
                      // e.stopPropagation();
                      handleAction({
                        typeAction,
                        type: ETypeItem.topic,
                        data: topic,
                      });
                    }}
                  />
                )}
              </Box>
            ))}
            {listTopics.data?.length === 0 && !listTopics.isFetching ? <NoDataComponent /> : null}
          </Box>
        </Box>
        <Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h3">Questions</Typography>
            <Box flex={1} />
            <Button
              variant="contained"
              onClick={() => {
                if (selectedTopicId) {
                  handleOpenCreate(ETypeItem.question);
                } else {
                  dispatch(showMessage("Please choose one topic", "warning"));
                }
              }}
              color="secondary"
              disabled={isPageChallenge}
            >
              Create Question
            </Button>
            <Box width={16} />
          </Box>
          <Box className="box-item">
            {selectedTopicId && (
              <SortQuestions
                listData={listQuestions.data}
                handleAction={handleAction}
                isPageChallenge={isPageChallenge}
                onHandleClickQuestion={onHandleClickQuestion}
                selectedQuestionId={selectedQuestionId}
                setSelectedQuestionId={setSelectedQuestionId}
              />
            )}
            {listQuestions.data?.length === 0 && !listQuestions.isFetching ? <NoDataComponent /> : null}
          </Box>
        </Box>
      </Box>
      <CreateEditItemModal
        handleCreateEdit={handleCreateEdit}
        openModal={openModalCreate.open}
        handleCloseModal={handleCloseModal}
        isLoading={createTopicsResult.isFetching || createQuestionsResult.isFetching}
        titleModal={
          openModalCreate?.data
            ? openModalCreate.type === ETypeItem.topic
              ? `Edit topic`
              : `Edit question`
            : openModalCreate.type === ETypeItem.topic
            ? `Create new topics`
            : `Create new questions`
        }
        data={openModalCreate.data}
        isQuestion={openModalCreate.type === ETypeItem.question}
        isUploadFile
      />
    </Box>
  );
};

export default TopicQuestionsPage;
