export const resetData = (type: string): any => {
	return {
		type: `${type}_SUCCESS`,
		data: null,
	};
};

export * from './admin';
export * from './items';
export * from './challenge';
