import {
  ENDPOINTS,
  IGenerateConnectorInput,
  IGrammarAnalysis,
  IQuotaOutput,
  IRepharseAnswerInput,
  ISuggestVocaByBand,
  IWordLimitAnswer,
  Requests,
  getApiUrl,
} from "@/common";
import { EBackOfficeCode } from "@/common/types/backoffice";
import { INIT_LLM_ENERGY } from "@/constants";

export const generateConnector = async (payload: IGenerateConnectorInput): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GENERATE_CONNECTOR);
  const { data }: any = await Requests.post(apiUrl, payload);

  return data;
};

export const getConnectorQuota = async (): Promise<IQuotaOutput> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_QUOTA);
  const { data }: any = await Requests.get(apiUrl);

  return data;
};

export const repharseAnswer = async (payload: IRepharseAnswerInput): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.REPHARSE_ANSWER);
  const { data }: any = await Requests.post(apiUrl, payload);

  return data;
};

export const wordLimitAnswer = async (payload: IWordLimitAnswer): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.WORD_LIMIT_ANSWER);
  const { data }: any = await Requests.post(apiUrl, payload);

  return data;
};

export const suggestVocaByBand = async (payload: ISuggestVocaByBand): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_SUGGEST_VOCA_BY_BAND);
  const { data }: any = await Requests.post(apiUrl, payload);

  return data;
};

export const grammarAnalysis = async (payload: IGrammarAnalysis): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GRAMMAR_ANALYSIS);
  const { data }: any = await Requests.post(apiUrl, payload);

  return JSON.parse(data);
};

export const getEnergyPrice = async (): Promise<any> => {
  const apiUrl = getApiUrl(
    ENDPOINTS.BACKOFFICE_SAVE_BY_CODE,
    {
      code: EBackOfficeCode.LLM_ENERGY_CMS,
    },
    true
  );
  // console.log("apiUrl", apiUrl)
  const { data }: any = await Requests.get(apiUrl);
  data.types = {
    ...INIT_LLM_ENERGY.types,
    ...data.types,
  };

  return data;
};
