import { ENDPOINTS, getApiUrl, Requests } from "@/common";

export const sendDictReport = async (id: string): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.DICT_REPORT, { id }, true);
  const data: any = await Requests.patch(apiUrl);
  return data;
};

export const listDictReport = async (): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.LIST_DICT_REPORT, { isAll: 1 });
  const data: any = await Requests.get(apiUrl);
  return data;
};

export const genDict = async (word: string): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.GEN_DICT, { word });
  const data: any = await Requests.post(apiUrl);
  return data;
};

export const updateDict = async (payload: any): Promise<any> => {
  const apiUrl = getApiUrl(ENDPOINTS.UPDATE_DICT.replace(":id", payload?.id));
  delete payload.id;
  const data: any = await Requests.patch(apiUrl, payload);
  return data;
};
