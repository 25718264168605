import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SunEditor from "suneditor-react";
import {
  AllTypeActions,
  ELevel,
  ETypeItem,
  IApplicationState,
  ICreateItem,
  createLevel,
  getListLevel,
  resetData,
  showMessage,
  updateLevel,
} from "../../../store-redux";
import TopicQuestionsPage from "../TopicsQuestionsPage";
import { styles } from "./styles";

const ChallengeCMSPage: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedLevelId, setSelectedLevelId] = useState<ELevel>(ELevel.level1);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(null);
  const [contentLevelSelected, setContentLevelSelected] = useState<string>("");

  const createLevelResult = useSelector((state: IApplicationState) => state.createLevel);
  const listLevel = useSelector((state: IApplicationState) => state.listLevel);
  const listQuestions = useSelector((state: IApplicationState) => state.listQuestions);
  const updateLevelResult = useSelector((state: IApplicationState) => state.updateLevel);

  const handleCreateEdit = () => {
    if (contentLevelSelected) {
      const selectContentLevel = listLevel.data.find((item) => item.level === selectedLevelId);
      const dataSend: ICreateItem["items"] = [
        {
          name: contentLevelSelected,
          description: "",
          type: ETypeItem.level,
          parentId: selectedQuestionId ?? "",
          level: selectedLevelId,
          topicId: listQuestions?.data?.[0]?.parentId ?? "",
        },
      ];
      if (selectContentLevel) {
        dispatch(updateLevel({ ...dataSend?.[0], id: selectContentLevel._id }));
      } else {
        dispatch(createLevel({ items: dataSend }));
      }
    }
  };

  const handleLevelClick = (level: ELevel) => {
    setSelectedLevelId(level);
    const contentFind = listLevel?.data?.find((item) => item.level === level);
    setContentLevelSelected(contentFind?.name ?? "");
  };

  const handleGetListLevel = (idQuestion?: string) => {
    dispatch(
      getListLevel({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.level,
        parentId: idQuestion ?? selectedQuestionId,
      })
    );
  };

  const onHandleClickQuestion = (idQuestion: string) => {
    setSelectedQuestionId(idQuestion);
    idQuestion && handleGetListLevel(idQuestion);
  };

  // CREATE
  useEffect(() => {
    if (createLevelResult.data && !createLevelResult.error) {
      dispatch(resetData(AllTypeActions.CREATE_LEVEL));
      dispatch(showMessage("Save content success", "success"));
      handleGetListLevel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLevelResult]);

  // UPDATE
  useEffect(() => {
    if (updateLevelResult.data && !updateLevelResult.error) {
      dispatch(resetData(AllTypeActions.UPDATE_LEVEL));
      handleGetListLevel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLevelResult]);

  useEffect(() => {
    if (selectedQuestionId) {
      setSelectedLevelId(ELevel.level1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionId]);

  useEffect(() => {
    if (listLevel.data && !listLevel.isFetching && selectedQuestionId) {
      const contentFind = listLevel?.data?.find((item) => item.level === selectedLevelId);
      setContentLevelSelected(contentFind?.name ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLevel.data]);

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      })}
    >
      <Box
        sx={{
          ...styles.boxContainer,
          "& >div:first-of-type": {
            flex: 2,
          },
          "& .col-idea": {
            flex: 1,
          },
        }}
      >
        <TopicQuestionsPage isPageChallenge onHandleClickQuestion={onHandleClickQuestion} />
        <Box className="">
          <Typography variant="h3">Challenge Level</Typography>
          <Box
            sx={{
              height: "24.5px",
              marginBottom: "10px",
            }}
          />
          <Box className="box-item">
            {selectedQuestionId &&
              Object.entries(ELevel).map(([, value], key) => (
                <Box
                  key={key}
                  onClick={() => handleLevelClick(value)}
                  sx={{
                    ...styles.styleItem,
                    backgroundColor: selectedLevelId === value ? "lightblue" : "transparent",
                  }}
                >
                  <Box
                    className="title"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {value.slice(0, value.length - 1) + " " + value[value.length - 1]}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
        <Box
          className="flex-1"
          sx={{
            "& .box-item": {
              marginRight: "15px",
            },
          }}
        >
          <Typography variant="h3">Challenge Content</Typography>
          <Button
            variant="contained"
            onClick={() => handleCreateEdit()}
            color="secondary"
            disabled={!selectedQuestionId}
          >
            {createLevelResult.isFetching && updateLevelResult.isFetching ? <CircularProgress size={30} /> : "Save"}
          </Button>
          <Box className="box-item">
            {selectedQuestionId ? (
              <SunEditor
                onChange={(content) => {
                  setContentLevelSelected(content);
                }}
                hideToolbar
                setContents={contentLevelSelected.toString()}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengeCMSPage;
