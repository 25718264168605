import { IQueryOptions } from ".";
import { useMutation } from "react-query";
import { changeStatusUser, createMultipleUsers, updateInfoUser } from "../api";

export const useCreateMultipleUser = (options: IQueryOptions) => {
  return useMutation(createMultipleUsers, options);
};

export const useUpdateInfoUser = (options: IQueryOptions) => {
  return useMutation(updateInfoUser, options);
};

export const useChangeStatusUser = (options: IQueryOptions) => {
  return useMutation(changeStatusUser, options);
};

