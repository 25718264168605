import { Box, Button, IconButton, LinearProgress, Popover, Typography } from "@mui/material"
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { useEffect, useState } from "react";
import { useGetListWordbooks } from "@/services-react-query/queries/wordbank.query";
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from "react-router-dom";
import { ROUTES } from "@/constants";
import WordbankModal from "@/pages/WordbankPage/components/WordbankModal";
const SelectWordbook  = (props) => {
    const {
        onSelectWordbook
    } = props;
    const [wordBooks, setWordBooks] = useState([]);
    const [openFormModal, setOpenFormModal] = useState(false)
    const [openWordbankModal, setOpenWordbankModal] = useState(false)
    const { mutate: getListWordbooks, isLoading: loading } = useGetListWordbooks({
        onSuccess: (data: any) => {
            setWordBooks(data)
        },
    });
    useEffect(() => {
        getListWordbooksFnc();
    }, [])
    const getListWordbooksFnc = () => {
        getListWordbooks();
    }
    return (
        <>
            <Box sx={{
                maxWidth: '150px'
            }}>
                {loading && <LinearProgress />}
                <Box sx={{
                    minHeight: '180px',
                    maxHeight: '180px',
                    overflowY: 'auto'
                }}>
                    {
                        (!loading && wordBooks.length === 0) && <>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: '14px'
                            }}>
                                You don't have a wordbook yet
                            </Typography>
                            {/* <NavLink to={ROUTES.WORDBANK}> */}
                                <Button sx={{
                                    minWidth: "68px",
                                    height: "30px",
                                    padding: "0px 8px",
                                    borderRadius: "4px",
                                    color: "white",
                                    backgroundColor: "#264653",
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    lineHeight: "30px",
                                    textTransform: "none",
                                }} variant="contained" startIcon={<AddIcon />} onClick={() => {
                                    setOpenWordbankModal(true)
                                }}>
                                    Create Wordbook
                                </Button>
                            {/* </NavLink> */}
                        </>
                    }
                    {
                        wordBooks.map((item) => {
                            return (
                                <Box sx={{
                                    cursor: 'pointer',
                                    padding: '4px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0,0,0, 0.1)'
                                    }
                                }} key={item._id} onClick={() => onSelectWordbook(item)}>
                                    {item.name}
                                </Box>
                            )
                        })
                    }
                </Box>
                {wordBooks.length > 0 && <Typography onClick={() => setOpenWordbankModal(true)} sx={{
                        fontSize: '12px',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: '#aaa',
                        textAlign: 'center',
                        '&:hover': {
                            color: '#264653',
                        }
                    }}>
                        Create other wordbook
                </Typography>}
            </Box>
            {openWordbankModal && <WordbankModal 
                onClose={() => {
                    setOpenWordbankModal(false);
                    getListWordbooksFnc();
                }}
            />}
        </>
    )
}

export default SelectWordbook;