import { useSelector, useDispatch } from "react-redux";
import { IApplicationState, hideMessage } from "../../store-redux";
import { Alert, Snackbar } from "@mui/material";

export const SnackbarMessage = () => {
  const { isOpen, message, severity } = useSelector((state: IApplicationState) => state.systemMessage);
  const dispatch = useDispatch();
  return (
    <Snackbar
      sx={{
        zIndex: 99999999,
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={!!(isOpen && message)}
      autoHideDuration={5000}
      onClose={() => dispatch(hideMessage())}
    >
      <Alert elevation={6} variant="filled" onClose={() => dispatch(hideMessage())} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
