import { FC, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Swal from "sweetalert2";

import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import GrammarExample from "./GrammarExample";

interface GrammarExampleData {
  grammarTopic: string;
  examples: any[];
}

interface ListGrammarByQuestionProps {
  questionId: string;
  grammarTopics: string[];
}

const ListGrammarByQuestion: FC<ListGrammarByQuestionProps> = ({ questionId, grammarTopics }) => {
  const [examplesByTopic, setExamplesByTopic] = useState<Record<string, any[]>>({});
  const [topicGenerating, setTopicGenerating] = useState<string[]>([]);

  useEffect(() => {
    setExamplesByTopic({});
    getGrammarByQuestionId();
  }, [questionId]);

  const getGrammarByQuestionId = async () => {
    const url = getApiUrl(ENDPOINTS.GET_GRAMMAR_BY_QUESTION.replace(":questionId", questionId));
    const data: GrammarExampleData[] = await Requests.get(url);
    const dataJson: Record<string, string[]> = {};

    data.forEach((item) => {
      dataJson[item.grammarTopic] = item.examples;
    });

    setExamplesByTopic(dataJson);
  };

  const onGenerate = async (grammarTopic: string) => {
    setTopicGenerating((prev) => [...prev, grammarTopic]);
    const apiUrl = getApiUrl(ENDPOINTS.GRAMMAR_GENERATE);

    try {
      const data = await Requests.post(apiUrl, {
        grammarTopic: grammarTopic,
        questionId,
      });

      if (Array.isArray(data.examples)) {
        setExamplesByTopic((prev) => ({
          ...prev,
          [grammarTopic]: data.examples,
        }));
      }
    } catch (e) {
      console.log("e", e);
    }

    setTopicGenerating((prev) => prev.filter((item) => item !== grammarTopic));
  };

  const onSave = async (grammarTopic: string, examples: string[]) => {
    const apiUrl = getApiUrl(ENDPOINTS.GRAMMAR_SAVE);

    try {
      const data = await Requests.post(apiUrl, {
        grammarTopic: grammarTopic,
        questionId,
        examples,
      });

      if (Array.isArray(data.examples)) {
        setExamplesByTopic((prev) => ({
          ...prev,
          [grammarTopic]: data.examples,
        }));
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const onConfirmReGenerate = (grammarTopic: string) => {
    Swal.fire({
      title: "Are you sure to regenerate the examples for this grammar topic?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        onGenerate(grammarTopic);
      }
    });
  };

  return (
    <Box
      sx={{
        flex: 1,
        minWidth: "400px",
        padding: "10px",
        maxHeight: "calc(100vh - 150px)",
        overflowY: "auto",
      }}
    >
      {grammarTopics.map((grammarTopic) => (
        <Box
          key={grammarTopic}
          sx={{
            borderBottom: "2px solid #eceaea",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "4px",
            }}
          >
            <Typography
              sx={{
                padding: "10px",
                cursor: "pointer",
                flex: 1,
              }}
            >
              {grammarTopic}
            </Typography>
            <Button
              disabled={topicGenerating.includes(grammarTopic)}
              onClick={() => {
                if (examplesByTopic[grammarTopic]) {
                  onConfirmReGenerate(grammarTopic);
                } else {
                  onGenerate(grammarTopic);
                }
              }}
              sx={{
                textTransform: "unset",
                height: "36px",
              }}
              endIcon={<AutoAwesomeIcon fontSize="small" />}
            >
              {topicGenerating.includes(grammarTopic) && (
                <CircularProgress
                  size={20}
                  sx={{
                    marginRight: "2px",
                  }}
                />
              )}
              Generate
            </Button>
          </Box>
          {Array.isArray(examplesByTopic[grammarTopic]) && (
            <Box
              sx={{
                padding: "10px",
              }}
            >
              {examplesByTopic[grammarTopic].map((example, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    borderBottom: "0.5px solid #ccc",
                    padding: "10px",
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "5px",
                      backgroundColor: "#264653",
                      color: "#fff",
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {index + 1}
                  </Box>
                  <GrammarExample
                    example={example}
                    onSave={(data) => {
                      onSave(
                        grammarTopic,
                        examplesByTopic[grammarTopic].map((itemEx, indexEx) => (index === indexEx ? data : itemEx))
                      );
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ListGrammarByQuestion;
