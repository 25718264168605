import { parseParamUrl } from "@/common";
import { EQuestionNumber, ROUTES } from "@/constants";
import { Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LearnPage() {
  const navigate = useNavigate();
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const renderBackground = () => {
    const color1 = getRandomColor();
    const color2 = getRandomColor();
    const angle = Math.floor(Math.random() * 360);
    return `linear-gradient(${angle}deg, ${color1}, ${color2})`;
  };
  const handleNavigate = (type: EQuestionNumber) => {
    // const url = parseParamUrl(ROUTES.LEARN_TOPIC, undefined, {
    //   type,
    // });
    // navigate(url);
    const url = parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
      "type-question": type,
    });
    navigate(url);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100vh - 64px)",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "50px",
        "& .MuiCard-root": {
          height: "250px",
          width: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "80px",
        }}
      >
        <Card
          sx={{
            background: renderBackground(),
          }}
          onClick={() => {
            handleNavigate(EQuestionNumber.QUESTION_PART_1);
          }}
        >
          IELTS Part 1
        </Card>
        <Card
          sx={{
            background: renderBackground(),
          }}
          onClick={() => {
            navigate(ROUTES.LEARN_GROUP_PART2);
          }}
        >
          IELTS Part 2
        </Card>
        <Card
          sx={{
            background: renderBackground(),
          }}
          onClick={() => {
            handleNavigate(EQuestionNumber.QUESTION_PART_3);
          }}
        >
          IELTS Part 3
        </Card>
      </Box>
    </Box>
  );
}

export default LearnPage;
