export interface IGenerateConnectorInput {
  sentence: string;
  question: string;
}

export interface IRepharseAnswerInput {
  sentence: string;
  band: RephrasingBand;
  question: string;
}

export enum RephrasingBand {
  "5_0" = "5.0",
  "5_5" = "5.5",
  "6_0" = "6.0",
  "6_5" = "6.5",
  "7_0" = "7.0",
  "7_5" = "7.5",
  "8_0" = "8.0",
  "8_5" = "8.5",
  "9_0" = "9.0",
}

export enum SuggestVocabularyBand {
  "6_0" = "6.0",
  "6_5" = "6.5",
  "7_0" = "7.0",
  "7_5" = "7.5",
  "8_0" = "8.0",
  "8_5" = "8.5",
  "9_0" = "9.0",
}

export interface IQuotaOutput {
  week_count: number;
  week_max: number;
}

export interface IWordLimitAnswer {
  sentence: string;
  expectedLength: EChangeLengthAnswer;
  question: string;
}

export enum EChangeLengthAnswer {
  shorten = "shorten",
  lengthen = "lengthen",
}

export interface ISuggestVocaByBand {
  band: SuggestVocabularyBand;
  question: string;
}

export interface IGrammarAnalysis {
  sentences: string;
}

export enum AiBoxTab {
  "contentGeneratorConnector" = "contentGeneratorConnector",
  "wordSearch" = "wordSearch",
  "suggestVoca" = "suggestVoca",
}
