import React from 'react';

interface IdeaIconProps {
    // Define your prop types here
}

const IdeaIcon: React.FC<IdeaIconProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.7723 3.20618L17.1246 3.49592C18.4448 4.7319 19.278 6.40804 19.4606 8.21782C19.6588 10.1822 19.0744 12.1454 17.8344 13.6816C17.0073 14.6618 16.5369 15.8935 16.5001 17.1757L16.4999 17.1828V17.19V20C16.4999 20.663 16.2365 21.2989 15.7677 21.7678C15.2989 22.2366 14.663 22.5 13.9999 22.5H9.99994C9.3369 22.5 8.70102 22.2366 8.23217 21.7678C7.76333 21.2989 7.49994 20.663 7.49994 20V17V16.9922L7.4997 16.9845C7.46319 15.81 7.01748 14.6853 6.23976 13.8047C5.53106 12.949 5.01942 11.9477 4.74117 10.8719C4.46239 9.79406 4.42479 8.66797 4.63105 7.57391C4.91383 6.11556 5.62333 4.77403 6.66958 3.71943C7.71612 2.66455 9.05242 1.94432 10.5089 1.65011L10.5098 1.64993C11.6037 1.42689 12.7336 1.45086 13.817 1.72009C14.9004 1.98933 15.9101 2.49704 16.7723 3.20618ZM8.99994 18.5H8.49994V19V20C8.49994 20.3978 8.65798 20.7794 8.93928 21.0607C9.22059 21.342 9.60212 21.5 9.99994 21.5H13.9999C14.3978 21.5 14.7793 21.342 15.0606 21.0607C15.3419 20.7794 15.4999 20.3978 15.4999 20V19V18.5H14.9999H8.99994ZM17.0522 13.0823L17.0523 13.0823L17.057 13.0765C17.9974 11.9263 18.5076 10.4845 18.4999 8.99887C18.5003 8.03426 18.2859 7.08166 17.8723 6.21018C17.4586 5.33826 16.8559 4.56943 16.108 3.95948C15.3601 3.34953 14.4857 2.91378 13.5484 2.68387C12.6111 2.45396 11.6344 2.43565 10.6891 2.63028L10.6886 2.63039C9.58868 2.85811 8.56657 3.36696 7.72189 4.10733C6.87721 4.8477 6.23881 5.79431 5.86892 6.85488C5.49903 7.91545 5.41028 9.05377 5.61133 10.1589C5.81237 11.2639 6.29635 12.2981 7.01607 13.1604L7.01598 13.1605L7.02309 13.1686C7.95258 14.2346 8.47566 15.5946 8.50002 17.0086L8.50848 17.5H8.99994H10.9999H11.4999V17V14C11.4999 13.8674 11.5526 13.7402 11.6464 13.6465C11.7402 13.5527 11.8673 13.5 11.9999 13.5C12.1326 13.5 12.2597 13.5527 12.3535 13.6465C12.4473 13.7402 12.4999 13.8674 12.4999 14V17V17.5H12.9999H14.9999H15.4756L15.4993 17.025C15.5717 15.5763 16.1172 14.1913 17.0522 13.0823Z" fill="black" stroke="#264653" />
        </svg>
    );
};

export default IdeaIcon;
