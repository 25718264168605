import { useMutation, useQuery } from "react-query";
import { IQueryOptions } from ".";
import { getChallenge, postChallengeEvaluate, postChallengeTranscribe, sendAudioAnswer } from "../api";
import { IGetList } from "@/store-redux";

export const useGetChallenge = (payload: IQueryOptions & IGetList) => {
  const { onSuccess, ...rest } = payload;
  return useQuery(["fetchChallenge"], () => getChallenge(rest), {
    enabled: true,
    keepPreviousData: true,
    onSuccess,
    staleTime: Infinity,
  });
};

export const useUploadFile = (options: IQueryOptions) => {
  return useMutation(sendAudioAnswer, options);
};

export const useChallengeTranscribe = (options: IQueryOptions) => {
  return useMutation(postChallengeTranscribe, options);
};

export const useChallengeEvaluate = (options: IQueryOptions) => {
  return useMutation(postChallengeEvaluate, options);
};
