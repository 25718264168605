import { Box, Button, Card, IconButton, LinearProgress, Typography } from "@mui/material";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useEffect, useMemo, useState } from "react";
import { ELevel, ETypeItem, IItems, IChallengeEvaluateOutput, ECurrentStep } from "@/store-redux";
import { convertSecondToTime, extractContent } from "@/helpers";
import {
  useGetDetailQuestion,
  useGetChallenge,
  useChallengeEvaluate,
  useChallengeTranscribe,
  useUploadFile,
} from "@/services-react-query/queries";

interface IStartRecordingProps {
  nextStep: (values?: IChallengeEvaluateOutput) => void;
  currentStep: ECurrentStep;
  onBack?: () => void;
  levelSelected: ELevel;
  questionSelected: IItems | null;
}

const CONTENT_STEP: {
  [key: string]: {
    description: string;
    iconButton: string;
  };
} = {
  START_RECORDING_2: {
    description: "Press the button to record your answer using requirement above",
    iconButton: "/svgs/microphone.svg",
  },
  RECORDING_3: {
    description: "Recording... press again to stop recording",
    iconButton: "/svgs/square.svg",
  },
  SUBMIT_4: {
    description: "Press the button to submit your answer",
    iconButton: "/svgs/upload.svg",
  },
};

function StartRecording({ nextStep, currentStep, onBack, levelSelected, questionSelected }: IStartRecordingProps) {
  const [audioRecord, setAudioRecord] = useState<any>();

  const { data: dataChallenge } = useGetChallenge({
    onSuccess: () => {},
    parentId: "*",
    type: ETypeItem.level,
    skip: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const randomQuestion = useMemo(() => {
    if (questionSelected) return questionSelected;
    const dataChallengeLevel = dataChallenge?.filter((item: IItems) => item.level === levelSelected && item?.name);
    if (dataChallengeLevel?.length) {
      const random = Math.floor(Math.random() * dataChallengeLevel?.length);
      return dataChallengeLevel?.[random];
    }
    return null;
  }, [dataChallenge, levelSelected, questionSelected]);

  const { data: dataQuestion } = useGetDetailQuestion({
    onSuccess: () => {},
    id: randomQuestion?.parentId,
    enabled: Boolean(randomQuestion?.parentId),
  });

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const { mutate: uploadFileAudio, isLoading: loadingUploadFile } = useUploadFile({
    onSuccess(data) {
      if (data?._id) {
        postTranscribe(data?._id);
      }
    },
  });

  const { mutate: postTranscribe, isLoading: loadingTranscribe } = useChallengeTranscribe({
    onSuccess(data) {
      if (data?._id) {
        postEvaluate(data?._id);
      }
    },
  });

  const { mutate: postEvaluate, isLoading: loadingEvaluate } = useChallengeEvaluate({
    onSuccess(data) {
      nextStep(data);
    },
  });

  useEffect(() => {
    if (recorderControls.recordingTime > 120) {
      recorderControls.stopRecording();
      nextStep();
    }
  }, [nextStep, recorderControls, recorderControls.recordingTime]);

  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setAudioRecord(url);
  };

  const handleSendAudioAnswer = async () => {
    const blob = await (await fetch(audioRecord)).blob();
    const timeStamp = new Date(Date.now()).toISOString().replace(/:/g, "-").replace(/\./g, "-");
    const file = new File([blob], `${timeStamp}.webm`, { type: "audio/webm" });

    uploadFileAudio({
      questionId: dataQuestion?._id ?? "",
      baremId: randomQuestion?._id ?? "",
      voiceFile: file,
    });
  };


  if (!randomQuestion) {
    return (
      <WrapContent onBack={onBack}>
        <Box className="box-content">
          <Card className="card warning">No data, please choose other level!</Card>
        </Box>
      </WrapContent>
    );
  }

  return (
    <WrapContent onBack={onBack}>
      <>
        <Typography component={"h2"}>{extractContent(dataQuestion?.name ?? "")}</Typography>
        <Box sx={{ display: "none" }}>
          <AudioRecorder onRecordingComplete={(blob) => addAudioElement(blob)} recorderControls={recorderControls} />
        </Box>
        <Box className="box-content">
          {/* <img src="/images/banner-practice.png" width={"100%"} /> */}
          <Card className="card">{extractContent(randomQuestion?.name ?? "")}</Card>
          <Typography component={"h3"}>{CONTENT_STEP?.[`${currentStep}`]?.description}</Typography>
          {currentStep === ECurrentStep.START_RECORDING_2 ? (
            <IconButton
              onClick={() => {
                recorderControls.startRecording();
                nextStep();
              }}
              className="icon-submit"
            >
              <img src={CONTENT_STEP?.[`${currentStep}`]?.iconButton} />
            </IconButton>
          ) : null}
          {currentStep === ECurrentStep.RECORDING_3 ? (
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography component={"span"} sx={{ fontSize: "30px" }}>
                {convertSecondToTime(recorderControls.recordingTime)}{" "}
              </Typography>
              {recorderControls.recordingTime > 100 ? (
                <Typography component={"span"} sx={{ color: "red", textAlign: "center", paddingInline: "16px" }}>
                  You have {120 - recorderControls.recordingTime} seconds left to answer the question
                </Typography>
              ) : null}
              <IconButton
                onClick={() => {
                  recorderControls.stopRecording();
                  nextStep();
                }}
                className="icon-submit"
              >
                <img src={CONTENT_STEP?.[`${currentStep}`]?.iconButton} />
              </IconButton>
            </Box>
          ) : null}
          {currentStep === ECurrentStep.SUBMIT_4 ? (
            loadingUploadFile || loadingTranscribe || loadingEvaluate ? (
              <Box
                sx={{
                  marginTop: "40px",
                  width: "80%",
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  {loadingUploadFile
                    ? "The audio is being processing..."
                    : loadingTranscribe
                    ? "Transcribing..."
                    : "Evaluating..."}
                </Typography>
                <LinearProgress
                  color="success"
                  sx={{}}
                  valueBuffer={loadingUploadFile ? 30 : loadingTranscribe ? 70 : loadingEvaluate ? 100 : 0}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  "& video": {
                    marginTop: "-60px",
                  },
                }}
              >
                <video src={audioRecord} controls />

                <IconButton
                  onClick={() => {
                    handleSendAudioAnswer();
                  }}
                  className="icon-submit"
                >
                  <img src={CONTENT_STEP?.[`${currentStep}`]?.iconButton} />
                </IconButton>
              </Box>
            )
          ) : null}
        </Box>
      </>
    </WrapContent>
  );
}

interface IWrapContentProps {
  children: JSX.Element;
  onBack?: () => void;
}

const WrapContent = ({ children, onBack }: IWrapContentProps) => {
  return (
    <Box
      sx={() => ({
        maxHeight: "calc(100vh - 56px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "auto",
        "& .box-content": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& img": {
            maxWidth: "30vw",
          },
          "& .card": {
            minHeight: "100px",
            maxWidth: "60vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            backgroundColor: "#b7d1e1",
            fontSize: "20px",
            "&.warning": {
              backgroundColor: "#f8d7da",
            },
          },
        },
        "& h2": {
          textAlign: "center",
          fontSize: "28px",
          marginBottom: "10vh",
          fontWeight: "500",
        },
        "& h3": {
          textAlign: "center",
          marginTop: "20px",
        },
        "& button.icon-submit": {
          marginBlock: "20px",
          height: "144px",
          width: "144px",
          backgroundColor: "rgba(247, 249, 250, 1) !important",
          boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
        },
      })}
    >
      {onBack && (
        <Box
          sx={{
            "& button": {
              height: "40px",
            },
          }}
        >
          <Button onClick={onBack} variant="contained" color="secondary">
            Back
          </Button>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default StartRecording;
