export const styles = {
  styleItem: {
    display: "flex",
    cursor: "pointer",
    position: "relative",
    padding: "5px",
    minHeight: "50px",
    "& .menu-icon": {
      position: "absolute",
      right: "5px",
      top: "50%",
      transform: "translateY(-50%)",
      height: "40px",
      zIndex: -1,
    },
    "&:hover": {
      backgroundColor: "#ECEAEA",
    },
    "&:hover .menu-icon": {
      zIndex: 1,
    },
    "& .title": {
      paddingRight: "30px",
    },
    "& .description": {
      paddingRight: "30px",
    },
  },
  boxContainer: {
    display: "flex",
    width: "100%",
    gap: "20px",
    "& .flex-1": {
      flex: 1,
    },
    "& h3": {
      fontSize: 20,
      marginBottom: "10px",
    },
    "& button": {
      textTransform: "none",
      marginBottom: "10px",
    },
    "& .box-item": {
      height: "calc(100vh - 180px)",
      //   maxHeight: "calc(100vh - 180px)",
      overflowY: "auto",
    },
  },
};
