import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _ from "lodash";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
const Input: React.FC<{ init: string; onChange: (value: string) => void }> = ({ init, onChange }) => {
  const [value, setValue] = useState(init);

  return (
    <TextField
      autoFocus
      multiline
      maxRows={2}
      sx={{
        "& textarea": {
          fontSize: "0.875rem",
        },
      }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="outlined"
      size="small"
      fullWidth
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      onBlur={() => onChange(value)}
    />
  );
};

const StructureAspect = (props) => {
  const { item, onBack, onSave } = props;
  const [data, setData] = useState(item);
  const [structureActive, setStructureActive] = useState(null);
  const [indexExamplesChecked, setIndexExampleChecked] = useState([]);
  const [structureSelectedMove, setStructureSelectedMove] = useState(null);
  const [editS, setEditS] = useState(false);
  const [structureNameVal, setStructureNameVal] = useState("");
  const [structureEl, setStructureEl] = useState(null);
  const [structureAction, setStructureAction] = useState(null);
  const onAddStructure = () => {
    setData((prev) => ({
      ...prev,
      structures: [
        {
          id: Date.now(),
          name: `Structure ${prev.structures.length + 1}`,
          examples: [],
          // isPublic: true,
        },
        ...prev.structures,
      ],
    }));
  };

  const onToggleCheck = (index) => {
    setIndexExampleChecked((prev) => (prev.includes(index) ? prev.filter((item) => item !== index) : [...prev, index]));
  };

  useEffect(() => {
    setIndexExampleChecked([]);
    setEditS(false);
  }, [structureActive]);

  const listExamples = useMemo(() => {
    const structure = data.structures.find((item) => item.id === structureActive?.id);
    if (structure) return structure.examples;
    return data.examples;
  }, [structureActive, data]);

  const onMoveExample = () => {
    if (!structureSelectedMove) return;
    // console.log(indexExamplesChecked, structureSelectedMove);
    const moveExamples = listExamples.filter((item, index) => indexExamplesChecked.includes(index));
    if (structureActive === null) {
      setData((prev) => ({
        ...prev,
        examples: prev.examples.filter((item, index) => !indexExamplesChecked.includes(index)),
        structures: prev.structures.map((item) =>
          item.id === structureSelectedMove.id
            ? {
                ...item,
                examples: [...moveExamples, ...item.examples],
              }
            : item
        ),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        examples: structureSelectedMove.id === null ? [...moveExamples, prev.examples] : prev.examples,
        structures: prev.structures.map((item) =>
          item.id === structureActive?.id
            ? {
                ...item,
                examples: item.examples.filter((item, index) => !indexExamplesChecked.includes(index)),
              }
            : item.id === structureSelectedMove.id
            ? {
                ...item,
                examples: [...moveExamples, ...item.examples],
              }
            : item
        ),
      }));
    }

    setIndexExampleChecked([]);
  };

  const isChange = useMemo(() => {
    return _.isEqual(item, data);
  }, [data]);

  const onCancel = () => {
    setData(item);
    setStructureActive(null);
    setIndexExampleChecked([]);
    setStructureSelectedMove(null);
  };

  const moveStructureItem = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= data.structures.length) {
      return;
    }

    const updatedRows = [...data.structures];
    const [movedItem] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedItem);

    setData((prev) => ({
      ...prev,
      structures: updatedRows,
    }));
  };

  const menuStructureAction = [
    {
      icon: ArrowUpwardIcon,
      text: "First up",
      onClick: () => {
        // console.log("structureAction", structureAction);
        const index = data.structures.findIndex((item) => item.id === structureAction.id);
        // console.log("index", index);
        if (index > 0) moveStructureItem(index, 0);
      },
    },
    {
      icon: ArrowUpwardIcon,
      text: "Up",
      onClick: () => {
        const index = data.structures.findIndex((item) => item.id === structureAction.id);
        if (index > 0) moveStructureItem(index, index - 1);
      },
    },
    {
      icon: ArrowDownwardIcon,
      text: "Down",
      onClick: () => {
        const index = data.structures.findIndex((item) => item.id === structureAction.id);
        if (index < data.structures.length - 1) moveStructureItem(index, index + 1);
      },
    },
    {
      icon: ArrowDownwardIcon,
      text: "Last down",
      onClick: () => {
        const index = data.structures.findIndex((item) => item.id === structureAction.id);
        if (index < data.structures.length - 1) moveStructureItem(index, data.structures.length - 1);
      },
    },
    {
      icon: DeleteIcon,
      text: "Delete",
      onClick: () => {
        setData((prev) => ({
          ...prev,
          examples: [...structureAction?.examples, ...prev.examples],
          structures: prev.structures.filter((item) => item.id !== structureAction?.id),
        }));
        setStructureAction(null);
        setStructureEl(null);
      },
    },
  ];
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <Button variant="contained" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
        <Box>
          <Typography sx={{ fontWeight: "700", textAlign: "center" }}>{item.aspect}</Typography>
        </Box>
        <Box>
          {!isChange && (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#f1f1f1",
                  color: "#000",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onSave(data);
                }}
              >
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "10px",
          height: "calc(100% - 60px)",
        }}
      >
        <Box
          sx={{
            width: "400px",
            paddingRight: "10px",
          }}
        >
          <TableContainer component={Paper} sx={{ height: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Structures
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      ...(structureActive === null
                        ? {
                            backgroundColor: "#264653",
                            "& span,div": {
                              color: "#fff",
                              fontWeight: "bold",
                            },
                            "& svg": {
                              fill: "#fff",
                            },
                            "&:hover": {
                              backgroundColor: "#264653",
                            },
                          }
                        : {}),
                    }}
                    onClick={() => {
                      setStructureActive(null);
                    }}
                  >
                    <span>General</span>
                    <Typography
                      component={"div"}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontStyle: "italic",
                      }}
                    >
                      ({data.examples.length} examples)
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        onAddStructure();
                      }}
                    >
                      structure
                    </Button>
                  </TableCell>
                </TableRow>
                {data.structures.map((item) => {
                  return (
                    <TableRow
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        ...(structureActive?.id === item.id
                          ? {
                              backgroundColor: "#264653",
                              "&:hover": {
                                backgroundColor: "#264653",
                              },
                              "& span,div": {
                                color: "#fff",
                                fontWeight: "bold",
                              },
                              "& svg": {
                                fill: "#fff",
                              },
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setStructureActive(item);
                      }}
                    >
                      <TableCell
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box>
                          <span>{item.name}</span>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              fontStyle: "italic",
                            }}
                          >
                            ({item.examples.length} examples)
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setStructureEl(event.currentTarget);
                            setStructureAction(item);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: "10px",
          }}
        >
          {structureActive && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
              }}
            >
              {editS ? (
                <>
                  <Input
                    init={structureActive.name}
                    onChange={(value) => {
                      setStructureNameVal(value);
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setData((prev) => ({
                        ...prev,
                        structures: prev.structures.map((item) =>
                          item.id === structureActive?.id ? { ...item, name: structureNameVal } : item
                        ),
                      }));
                      setStructureActive((prev) => ({
                        ...prev,
                        name: structureNameVal,
                      }));
                      setEditS(false);
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setEditS(false);
                      setStructureNameVal("");
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    {structureActive.name}
                  </Typography>

                  <IconButton onClick={() => setEditS(true)}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Box>
          )}
          <TableContainer component={Paper} sx={{ height: "100%" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Examples
                  </TableCell>
                  <TableCell>
                    {indexExamplesChecked.length ? (
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          options={[
                            {
                              id: null,
                              name: "General",
                            },
                            ...data.structures,
                          ].filter((item) => item.id !== structureActive)}
                          sx={{ width: 300 }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label="Structure" />}
                          onChange={(event, value) => {
                            setStructureSelectedMove(value);
                          }}
                        />
                        {!!structureSelectedMove && (
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                              marginLeft: "10px",
                              textTransform: "initial",
                            }}
                            onClick={() => onMoveExample()}
                          >
                            Move
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <Box>Please selected example to move to structure</Box>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listExamples.map((item, index) => {
                  return (
                    <TableRow
                      onClick={() => {
                        onToggleCheck(index);
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <TableCell>
                        <IconButton>
                          {indexExamplesChecked.includes(index) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{item}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Popover
        open={!!structureEl}
        anchorEl={structureEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setStructureEl(null);
          setStructureAction(null);
        }}
      >
        <Box>
          {menuStructureAction.map((item, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
                onClick={() => {
                  if (typeof item.onClick === "function") {
                    setStructureEl(null);
                    item.onClick();
                    setStructureAction(null);
                  }
                }}
              >
                {<item.icon />}
                <Typography
                  sx={{
                    marginLeft: "5px",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </Box>
  );
};

export default StructureAspect;
