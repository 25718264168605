import { ELevel, ETypeItem, IItems } from "@/common";
import { extractContent } from "@/helpers";
import { useGetQuestionsOfTopicAll } from "@/services-react-query/queries";
import { Box, Button, Card, LinearProgress } from "@mui/material";
import { useMemo, useState } from "react";

interface IChooseQuestionProps {
  topicExist: IItems[];
  nextStep: (value: IItems) => void;
  onBack: () => void;
  levelSelected: ELevel;
  dataAllChallenge: IItems[];
}

function ChooseQuestion({ topicExist, nextStep, onBack, levelSelected, dataAllChallenge }: IChooseQuestionProps) {
  const [topicSelectedId, setTopicSelectedId] = useState("");
  const {
    data: dataQuestions,
    refetch,
    isLoading: isLoadingQuestions,
    isFetching: isFetchingQuestions,
  } = useGetQuestionsOfTopicAll({
    options: {
      enabled: Boolean(topicSelectedId),
    },
    query: {
      skip: 0,
      limit: Number.MAX_SAFE_INTEGER,
      parentId: topicSelectedId,
      type: ETypeItem.question,
    },
  });

  const topicHasLevel = useMemo(() => {
    if (!topicExist?.length) return [];
    const parentIdTopic = dataAllChallenge
      ?.filter((item) => item?.level === levelSelected && extractContent(item?.name))
      .map((item) => item.topicId);
    return topicExist?.filter((item) => parentIdTopic?.includes(item?._id));
  }, [dataAllChallenge, levelSelected, topicExist]);

  const dataQuestionsFiltered = useMemo(() => {
    if (!dataAllChallenge) return [];
    const parentIdChallenge = dataAllChallenge
      ?.filter((item) => item?.level === levelSelected)
      ?.map((item) => item.parentId);
    return dataQuestions?.filter((item: IItems) => parentIdChallenge?.includes(item?._id));
  }, [dataAllChallenge, dataQuestions, levelSelected]);

  const handleClicktopic = (topicId: string) => {
    if (dataQuestions?.length) {
      setTopicSelectedId(topicId);
      setTimeout(() => {
        refetch();
      }, 10);
    } else {
      setTopicSelectedId(topicId);
    }
  };
  const handleClickQuestion = (id: string) => {
    const itemFind = dataAllChallenge?.find((item) => item.parentId === id && levelSelected === item.level);
    itemFind && nextStep(itemFind);
  };
  if (!topicSelectedId) {
    return (
      <WrapContent title="Choose topic" onBack={onBack}>
        <Box className="box-container">
          {topicHasLevel?.length ? (
            topicHasLevel?.map((topic) => (
              <Box key={topic._id} onClick={() => handleClicktopic(topic._id)} className="box-item">
                <Box className="title" dangerouslySetInnerHTML={{ __html: topic.name || "" }} />
                <Box className="description" dangerouslySetInnerHTML={{ __html: topic.description || "" }} />
              </Box>
            ))
          ) : (
            <NoDataComponent title="No data, please choose other level!" />
          )}
        </Box>
      </WrapContent>
    );
  }
  return (
    <WrapContent
      title="Choose question"
      onBack={() => {
        setTopicSelectedId("");
      }}
    >
      {isLoadingQuestions || isFetchingQuestions ? (
        <LinearProgress color="secondary" />
      ) : (
        <Box className="box-container">
          {dataQuestionsFiltered?.length ? (
            dataQuestionsFiltered?.map((question: IItems) => (
              <Box key={question._id} onClick={() => handleClickQuestion(question._id)} className="box-item">
                <Box className="title" dangerouslySetInnerHTML={{ __html: question.name || "" }} />
                <Box className="description" dangerouslySetInnerHTML={{ __html: question.description || "" }} />
              </Box>
            ))
          ) : (
            <NoDataComponent title="No data, please choose other topic!" />
          )}
        </Box>
      )}
    </WrapContent>
  );
}

const NoDataComponent = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Card
        sx={{
          minHeight: "100px",
          maxWidth: "60vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          fontSize: "20px",
          backgroundColor: "#f8d7da",
          marginTop: "15px",
        }}
      >
        {title}
      </Card>
    </Box>
  );
};

interface IWrapContentProps {
  children: JSX.Element;
  onBack: () => void;
  title: string;
}

const WrapContent = ({ children, onBack, title }: IWrapContentProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        "& h3": {
          fontSize: "22px",
          textAlign: "center",
        },
        "& .box-container": {
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          marginTop: "10px",
          paddingInline: "5px",
          width: "100%",
          gap: "10px",
        },
        "& .box-item": {
          padding: "16px 32px",
          border: "1px solid #EDEDED",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#ECEAEA",
            cursor: "pointer",
          },
        },
      }}
    >
      <Box
        sx={{
          "& button": {
            height: "40px",
          },
        }}
      >
        <Button onClick={onBack} variant="contained" color="secondary">
          Back
        </Button>
      </Box>
      <h3>{title}</h3>
      {children}
    </Box>
  );
};

export default ChooseQuestion;
