import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3, // retry failed requests up to 3 times
      // staleTime: 60000, // 1 minute
    },
  },
});

interface IQueryProviderProps {
  children: React.ReactNode;
}

const QueryProvider = React.memo(({ children }: IQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
});

export default QueryProvider;
