import NoDataComponent from "@/components/NoDataComponent";
import { extractContent } from "@/helpers";
import { useGetDetailItem, useGetQuestionPart2All } from "@/services-react-query/queries";
import { Box, Button, Dialog, IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ETypeItem, IItems, parseParamUrl } from "../../../../store-redux";
import LayoutLearn from "../LayoutLearn";
import { useState } from "react";
import SunEditor from "suneditor-react";
import CloseIcon from "@mui/icons-material/Close";
import { ROUTES } from "@/constants";

function QuestionPart2Page() {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isShowStructure, setIsShowStructure] = useState(false);
  const { data: listQuestionPart2, isSuccess } = useGetQuestionPart2All({
    query: {
      type: ETypeItem["question.part2"],
      limit: Number.MAX_SAFE_INTEGER,
      skip: 0,
      parentId: groupId ?? "",
      isOnlyPublic: true,
    },
    options: {
      enabled: !!groupId,
    },
  });
  const { data: dataGroup } = useGetDetailItem({
    id: groupId ?? "",
    enabled: !!groupId,
  });

  const handleClickQuestion = (id: string) => {
    const url = parseParamUrl(ROUTES.LEARN_GUIDED_QUESTION_PART2, {
      questionId: id,
    });
    navigate(url + search);
  };

  return (
    <LayoutLearn>
      <Box
        sx={{
          width: "100%",
          "& h3": {
            fontSize: "22px",
            textAlign: "left",
            fontWeight: "bold",
          },
          "& .box-container": {
            display: "grid",
            overflowY: "auto",
            marginTop: "10px",
            paddingInline: "5px",
            width: "100%",
            gap: "10px",
          },
          " .box-child": {
            " .head": {
              padding: "8px",
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "1px solid #000",
            },
          },
          "& .box-item": {
            padding: "16px 32px",

            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#ECEAEA",
              cursor: "pointer",
            },
          },
        }}
      >
        <Box className="box-container">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "32px",
              paddingLeft: "16px",
              "& button": {
                textTransform: "none",
                height: "40px",
              },
            }}
          >
            <Button onClick={() => navigate(-1)} variant="contained" color="secondary">
              Back
            </Button>
            <Button variant="outlined" onClick={() => setIsShowStructure(!isShowStructure)}>
              Structure
            </Button>
          </Box>
          <Box className="box-child">
            <Box className="content">
              {listQuestionPart2?.map((question: IItems) => (
                <Box key={question._id} onClick={() => handleClickQuestion(question._id)} className="box-item">
                  <Box className="title">{extractContent(question.name).replace(/^[A-Z]\d\s*-\s*\d+\.\s*/, "")}</Box>
                  <Box className="description" dangerouslySetInnerHTML={{ __html: question.description || "" }} />
                </Box>
              ))}
              {listQuestionPart2?.length === 0 && isSuccess && (
                <NoDataComponent
                  style={{
                    height: "200px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Dialog
          open={isShowStructure}
          onClose={() => setIsShowStructure(false)}
          PaperProps={{
            sx: {
              minWidth: "70vw",
              position: "relative",
              "& .se-wrapper-wysiwyg": {
                minHeight: "80vh !important",
              },
              "& .btn-close": {
                position: "absolute",
                top: "20px",
                right: "20px",
                zIndex: 9,
              },
            },
          }}
        >
          <IconButton className="btn-close" onClick={() => setIsShowStructure(false)}>
            <CloseIcon />
          </IconButton>
          <SunEditor hideToolbar disable disableToolbar defaultValue={dataGroup?.description ?? ""} />
        </Dialog>
      </Box>
    </LayoutLearn>
  );
}

export default QuestionPart2Page;
