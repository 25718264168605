import { useGetChallenge, useGetTopicsAll } from "@/services-react-query/queries";
import { ELevel, ETypeItem, IItems, IChallengeEvaluateOutput, ECurrentStep } from "@/store-redux";
import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import ChooseLevel from "./components/ChooseLevel";
import Result from "./components/Result";
import StartRecording from "./components/StartRecording";
import ChooseQuestion from "./components/ChooseQuestion";
import { ETypeQuestion } from "./components/ChooseLevel/interface";

function ChallengePage() {
  const [currentStep, setCurrentStep] = useState<ECurrentStep>(ECurrentStep.CHOOSE_LEVEL_1);
  const [levelSelected, setLevelSelected] = useState(ELevel.level1);
  const [dataEvaluate, setDataEvaluate] = useState<IChallengeEvaluateOutput | null>(null);
  const [typeQuestion, setTypeQuestion] = useState(ETypeQuestion.choose);
  const [questionSelected, setQuestionSelected] = useState<IItems | null>(null);
  const { data: dataTopicsAll } = useGetTopicsAll({
    query: {
      parentId: "*",
      type: ETypeItem.topic,
      skip: 0,
      limit: Number.MAX_SAFE_INTEGER,
    },
  });

  const { data: dataAllChallenge } = useGetChallenge({
    onSuccess: () => {},
    parentId: "*",
    type: ETypeItem.level,
    skip: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const topicExist: IItems[] = useMemo(() => {
    const listTopicId = dataAllChallenge?.filter((item: IItems) => item?.topicId)?.map((item: IItems) => item.topicId);
    if (!listTopicId) return [];
    const listTopicIdUnique = [...new Set(listTopicId)];
    const dataTemp = dataTopicsAll?.filter((item: IItems) => listTopicIdUnique?.includes(item._id));
    return dataTemp;
  }, [dataAllChallenge, dataTopicsAll]);

  const nextStepSubmit = (values?: IChallengeEvaluateOutput) => {
    values && setDataEvaluate(values);
    setCurrentStep(ECurrentStep.RESULT_5);
  };

  const handleChooseLevel = (level: ELevel) => {
    setLevelSelected(level);
    if (typeQuestion === ETypeQuestion.random) {
      setTimeout(() => {
        setCurrentStep(ECurrentStep.START_RECORDING_2);
      }, 10);
    } else {
      setCurrentStep(ECurrentStep.CHOOSE_QUESTION);
    }
  };

  const onChangeType = (type: ETypeQuestion) => {
    setTypeQuestion(type);
    if (type === ETypeQuestion.random) {
      setQuestionSelected(null);
    }
  };

  const renderContent = (): JSX.Element => {
    switch (currentStep) {
      case ECurrentStep.CHOOSE_LEVEL_1:
        return <ChooseLevel nextStep={handleChooseLevel} setTypeQuestion={onChangeType} typeQuestion={typeQuestion} />;
      case ECurrentStep.CHOOSE_QUESTION:
        return (
          <ChooseQuestion
            onBack={() => setCurrentStep(ECurrentStep.CHOOSE_LEVEL_1)}
            nextStep={(value: IItems) => {
              setQuestionSelected(value);
              setCurrentStep(ECurrentStep.START_RECORDING_2);
            }}
            topicExist={topicExist}
            levelSelected={levelSelected}
            dataAllChallenge={dataAllChallenge}
          />
        );
      case ECurrentStep.START_RECORDING_2:
        return (
          <StartRecording
            nextStep={() => setCurrentStep(ECurrentStep.RECORDING_3)}
            currentStep={currentStep}
            onBack={() => {
              if (questionSelected) {
                setCurrentStep(ECurrentStep.CHOOSE_QUESTION);
              } else {
                setCurrentStep(ECurrentStep.CHOOSE_LEVEL_1);
              }
            }}
            levelSelected={levelSelected}
            questionSelected={questionSelected}
          />
        );
      case ECurrentStep.RECORDING_3:
        return (
          <StartRecording
            nextStep={() => setCurrentStep(ECurrentStep.SUBMIT_4)}
            currentStep={currentStep}
            onBack={() => setCurrentStep(ECurrentStep.START_RECORDING_2)}
            levelSelected={levelSelected}
            questionSelected={questionSelected}
          />
        );
      case ECurrentStep.SUBMIT_4:
        return (
          <StartRecording
            nextStep={(values) => nextStepSubmit(values)}
            currentStep={currentStep}
            onBack={() => setCurrentStep(ECurrentStep.START_RECORDING_2)}
            levelSelected={levelSelected}
            questionSelected={questionSelected}
          />
        );
      case ECurrentStep.RESULT_5:
        return <Result nextStep={() => setCurrentStep(ECurrentStep.CHOOSE_LEVEL_1)} dataEvaluate={dataEvaluate} />;
      default:
        break;
    }
    return (
      <ChooseLevel
        setTypeQuestion={setTypeQuestion}
        typeQuestion={typeQuestion}
        nextStep={() => setCurrentStep(ECurrentStep.START_RECORDING_2)}
      />
    );
  };

  return (
    <Box
      sx={() => ({
        height: "100%",
        padding: "20px 10px",
      })}
    >
      {renderContent()}
    </Box>
  );
}

export default ChallengePage;
