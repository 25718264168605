import { applyMiddleware } from 'redux';
import { legacy_createStore as createStore} from 'redux'
import createSagaMiddleware from 'redux-saga';
import { AllSagaActions } from './sagas';
import { ApplicationState } from './reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

const initialAppState: any = {};
export const getStore = (initialState = initialAppState) => {
	const composeEnhancers = composeWithDevTools({});
	const sagaMiddleware = createSagaMiddleware();
	const appStore = createStore(ApplicationState, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));
	sagaMiddleware.run(AllSagaActions);
	return appStore;
};

export * from '../common/endpoints';
export * from './actions';
export * from './reducers';
export * from './sagas';
export * from './types';
export * from '../common/requests';
export * from './interface';
