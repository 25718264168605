import { Box, IconButton } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { BASE_DICT_SOUND_UK, BASE_DICT_SOUND_US } from "@/constants";

const WordPhonetic = (props) => {
  const { word } = props;

  const onPlaySound = (type: string) => {
    if (!word.uid) {
      if ("speechSynthesis" in window) {
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(word.word);
        utterance.lang = type === "us" ? "en-US" : "en-UK";
        synth.speak(utterance);
      }
      return;
    }
    const baseSoundByType = {
      us: BASE_DICT_SOUND_US,
      uk: BASE_DICT_SOUND_UK,
    };
    const soundUrl = `${baseSoundByType[type]}${word.uid}.mp3`;
    const audio = new Audio(soundUrl);
    audio.play();
  };

  if (!word) {
    return <></>;
  }

  return (
    <Box
      sx={() => ({
        "& .phonetic_type": {
          fontWeight: "bold",
        },
        "& .phonetic_text": {},
      })}
    >
      <Box
        sx={() => ({
          display: "flex",
          alignItems: "center",
          "& span:first-of-type": {
            fontWeight: "bold",
          },
        })}
      >
        <span>UK</span>
        <IconButton
          size="small"
          onClick={(e) => {
            onPlaySound("uk");
            e.stopPropagation();
          }}
        >
          <VolumeUpIcon />
        </IconButton>
        <span>
          {word.phonetic?.uk
            ? word.phonetic?.uk.indexOf("/") === 0
              ? word.phonetic?.uk
              : `/${word.phonetic?.uk}/`
            : ""}
        </span>
      </Box>
      <Box
        sx={() => ({
          display: "flex",
          alignItems: "center",
          "& span:first-of-type": {
            fontWeight: "bold",
          },
        })}
      >
        <span>US</span>
        <IconButton
          size="small"
          onClick={(e) => {
            onPlaySound("us");
            e.stopPropagation();
          }}
        >
          <VolumeUpIcon />
        </IconButton>
        <span>
          {word.phonetic?.us
            ? word.phonetic?.us.indexOf("/") === 0
              ? word.phonetic?.us
              : `/${word.phonetic?.us}/`
            : ""}
        </span>
      </Box>
    </Box>
  );
};

export default WordPhonetic;
