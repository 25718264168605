import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { useState } from "react";
import SelectWordbook from "./SelectWordbook";
import { useSaveWord } from "@/services-react-query/queries/wordbank.query";
import { showMessage } from "@/store-redux";
import { useDispatch } from "react-redux";
const SaveWordBtn = (props) => {
  const { word } = props;
  const [selectWbEl, setSelectWbEl] = useState(null);
  const dispatch = useDispatch();
  const { mutate: saveWord, isLoading: loadingCreate } = useSaveWord({
    onSuccess: (data: any) => {
      dispatch(showMessage("Save word success", "success"));
    },
  });

  const onSaveWord = (word, wordbook) => {
    saveWord({
      word,
      wordbook,
    });
  };
  return (
    <>
      <Button
        sx={{
          minWidth: "68px",
          height: "30px",
          padding: "0px 8px",
          borderRadius: "4px",
          color: "white",
          backgroundColor: "#264653",
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "30px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
        onClick={(e) => setSelectWbEl(e.currentTarget)}
        startIcon={<LibraryAddIcon fontSize="small" />}
      >
        Save
      </Button>
      <Popover
        open={!!selectWbEl}
        anchorEl={selectWbEl}
        onClose={() => setSelectWbEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={
          {
            // borderRadius: '10px'
          }
        }
      >
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "15px",
              textAlign: "center",
            }}
          >
            Select wordbook
          </Typography>
          <SelectWordbook
            onSelectWordbook={(wordbook) => {
              onSaveWord(word, wordbook);
              setSelectWbEl(null);
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default SaveWordBtn;
