import { useChangeStatusUser, useCreateMultipleUser } from "@/services-react-query/queries";
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  AllTypeActions,
  createUser,
  getListUser,
  IApplicationState,
  IUser,
  resetData,
  showMessage,
  updateUser,
} from "../../../store-redux";
import ModalAddEditUser, { IFormData } from "./components/ModalAddEditUser";
import ModalAddMultipleUser, { IFormDataProps } from "./components/ModalAddMultipleUser";
import SearchInfoUser from "./components/SearchInfoUser";
import AddInvoiceEnergyBtn from "./components/AddInvoiceEnergyBtn";
import StarIcon from "@mui/icons-material/Star";

const UsersPage: React.FC = () => {
  const dispatch = useDispatch();
  const listUser = useSelector((state: IApplicationState) => state.listUser);
  const createUserState = useSelector((state: IApplicationState) => state.createUser);
  const updateUserState = useSelector((state: IApplicationState) => state.updateUser);
  const [openModalOneUser, setOpenModalOneUser] = useState<{
    isOpen: boolean;
    userData?: IUser;
  }>({
    isOpen: false,
    userData: undefined,
  });
  const [openModalCreateMultiple, setOpenModalCreateMultiple] = useState(false);

  const [listData, setListData] = useState<any>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { mutate: createMultipleUser, isLoading: createMultipleLoading } = useCreateMultipleUser({});

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSave = (data: IFormData) => {
    if (openModalOneUser.userData) {
      const dataSend = {
        ...data,
        username: data.username ? data.username : data.email?.split("@")?.[0],
        _id: openModalOneUser.userData._id,
        active: data?.active ?? true,
        expiredTime: data?.expiredTime ? moment(data.expiredTime).format() : "",
      };
      dispatch(updateUser(dataSend));
    } else {
      const dataSend = {
        ...data,
        username: data.username ? data.username : data.email?.split("@")?.[0],
        active: data?.active ?? true,
        expiredTime: data?.expiredTime ? moment(data.expiredTime).format() : "",
      };
      dispatch(createUser(dataSend));
    }
  };
  const handleSearch = (searcInfoUser: string, searchRole: string) => {
    const filtered = listUser?.data?.filter((item: any) => {
      const searchDataInfoUser =
        (item.username.includes(searcInfoUser) || item.email.includes(searcInfoUser)) &&
        (searchRole ? item.role === searchRole : true);

      return searchDataInfoUser;
    });
    setListData(filtered);
  };

  // Change Status
  const { mutate: changeStatus } = useChangeStatusUser({
    onSuccess: () => {
      dispatch(getListUser());
    },
  });

  const handleAction = (id: string, status: boolean) => {
    Swal.fire({
      title: `Are you sure want to ${status ? "active" : "inactive"}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus({
          id,
          status,
        });
      }
    });
  };

  const onSaveMultiple = (data: IFormDataProps) => {
    createMultipleUser(
      {
        users: data.users,
      },
      {
        onSuccess: () => {
          dispatch(getListUser());
          setOpenModalCreateMultiple(false);
          dispatch(showMessage("Create all users success", "success"));
        },
      }
    );
  };

  useEffect(() => {
    dispatch(getListUser());
  }, []);

  useEffect(() => {
    if (createUserState && !createUserState.isFetching && createUserState.data) {
      dispatch(resetData(AllTypeActions.CREATE_USER));
      setOpenModalOneUser({ isOpen: false });
      dispatch(getListUser());
    }
  }, [createUserState]);

  useEffect(() => {
    if (updateUserState.data && !updateUserState.error && !updateUserState.isFetching) {
      setOpenModalOneUser({ isOpen: false });
      dispatch(getListUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserState]);

  if (listUser.isFetching) {
    return <LinearProgress color="secondary" style={{ marginRight: 10 }} />;
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "calc(100vw - 32px)",
          gap: "20px",
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: "3",
          "& button": {
            height: "43px",
          },
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            setOpenModalOneUser({ isOpen: true });
          }}
        >
          Create new user
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            setOpenModalCreateMultiple(true);
          }}
        >
          Create multiple users
        </Button>
        <SearchInfoUser onSearch={handleSearch} />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          "& thead": {
            "& th": {
              fontWeight: "bold",
            },
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Expired Time</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Energy</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((user: any) => (
              <TableRow key={user._id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell
                  sx={{
                    "& input": {
                      pointerEvents: "none",
                      cursor: "default",
                    },
                  }}
                >
                  <Box
                    onClick={() => {
                      handleAction(user._id, user.active === false ? true : false);
                    }}
                  >
                    {user.active === false ? <Switch checked={false} /> : <Switch checked={true} color="success" />}
                  </Box>
                </TableCell>
                <TableCell>{user.expiredTime ? moment(user.expiredTime).format("DD-MM-YYYY") : ""}</TableCell>
                <TableCell>{user.createdAt ? moment(user.createdAt).format("DD-MM-YYYY") : ""}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ff7269",
                        color: "#fff",
                        padding: "2px 4px",
                        borderRadius: "8px",
                      }}
                    >
                      {user.currentEnergy}
                      <StarIcon fontSize="small" />
                    </Box>
                    <AddInvoiceEnergyBtn
                      userId={user._id}
                      onRefresh={() => {
                        dispatch(getListUser());
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={() => ({
                    display: "flex",
                    gap: "10px",
                  })}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenModalOneUser({ isOpen: true, userData: user });
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={listData?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ModalAddEditUser
        open={openModalOneUser.isOpen}
        onClose={() => setOpenModalOneUser({ isOpen: false })}
        onSave={onSave}
        loading={createUserState.isFetching || updateUserState.isFetching}
        titleModal={openModalOneUser?.userData ? `Update info user` : `Create new user`}
        data={openModalOneUser.userData}
      />
      <ModalAddMultipleUser
        openModal={openModalCreateMultiple}
        handleCloseModal={() => setOpenModalCreateMultiple(false)}
        handleCreateMultiple={onSaveMultiple}
        isLoading={createMultipleLoading}
      />
    </Box>
  );
};

export default UsersPage;
