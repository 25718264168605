import { IQueryOptions } from ".";
import { useMutation, useQuery } from "react-query";
import {
  generateConnector,
  getConnectorQuota,
  getEnergyPrice,
  grammarAnalysis,
  repharseAnswer,
  suggestVocaByBand,
  wordLimitAnswer,
} from "../api";

export const useGeneratorConnector = (options: IQueryOptions) => {
  return useMutation(generateConnector, options);
};

export const useGetQuota = (options: IQueryOptions) => {
  return useQuery(["fetchQuota"], () => getConnectorQuota(), {
    enabled: true,
    keepPreviousData: true,
    ...options,
  });
};

export const useRephraseAnswer = (options: IQueryOptions) => {
  return useMutation(repharseAnswer, options);
};

export const useWordLimitAnswer = (options: IQueryOptions) => {
  return useMutation(wordLimitAnswer, options);
};

export const useSuggestVocaByBand = (options: IQueryOptions) => {
  return useMutation(suggestVocaByBand, options);
};

export const useGrammarAnalysis = (options: IQueryOptions) => {
  return useMutation(grammarAnalysis, options);
};

export const useGetListEnergyPrice = (options: IQueryOptions) => {
  return useQuery(["fetchEnergyPrice"], () => getEnergyPrice(), {
    enabled: true,
    keepPreviousData: true,
    ...options,
  });
};
