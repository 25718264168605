import AssistantIcon from "@mui/icons-material/Assistant";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TranslateIcon from "@mui/icons-material/Translate";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import { extractContent, genCategoryAspect, refinePrompt } from "../../../../../helpers";
import { AiBoxTab, IApplicationState, IItems, editCustomAnswer, getWordSearch } from "../../../../../store-redux";
import ListSuggest from "../../IdeaPage/components/ListSuggest";
import WordSearch from "../../WordSearch";
import TabAssistantAI from "./TabAssistantAI";
import TabSuggestion from "./TabSuggestion";
import ListAnswer from "../../GuidedQuestionPage/components/ListAnswer";
import ListIdeaAnswerBox from "../../GuidedQuestionPage/components/ListIdeaAnswerBox";
interface ICustomAnswerProps {
  isPartTwo?: boolean;
  question?: IItems;
  guidedQuestions?: any[];
  answersByAspect?: any;
  name?: string;
}

const boxTabs = [
  {
    code: AiBoxTab.contentGeneratorConnector,
    name: "Assistant AI",
    icon: AssistantIcon,
  },
  {
    code: AiBoxTab.wordSearch,
    name: "Dictionary",
    icon: TranslateIcon,
  },
  {
    code: AiBoxTab.suggestVoca,
    name: "Suggestion",
    icon: TipsAndUpdatesIcon,
  },
];

function CustomAnswer(props: ICustomAnswerProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { categoryId } = useParams();
  const isFromProfile = categoryId === ":categoryId";
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const listAllAspectsState = useSelector((state: IApplicationState) => state.listAllAspects);
  const listCategories = useSelector((state: IApplicationState) => state.listCategories);
  const [contentGeneratorConnector, setContentGeneratorConnector] = useState("");
  const [guidedQuestionsSelected, setGuidedQuestionsSelected] = useState([]);
  const handleAddItemCustom = (idea: IItems) => {
    const contentFormat = extractContent(idea.description ?? "").replace(/^.*"(.*?)"[^"]*$/, "$1");
    if (idea && editCustomAnswerContent.data?.indexOf(contentFormat) === -1) {
      let singleNameTag = "☆";
      const findAspect = listAllAspectsState.data.find((item) => item._id === idea.aspectId);
      if (findAspect) {
        const findCategory = listCategories.data?.find((item) => item._id === findAspect.parentId);
        if (findCategory) {
          singleNameTag = genCategoryAspect(findCategory, findAspect);
        }
      }

      dispatch(editCustomAnswer(editCustomAnswerContent.data + " " + `(${singleNameTag}) ` + contentFormat));
    }
  };

  const handleEditorChange = (content: string) => {
    const contentFormat = extractContent(content);
    dispatch(editCustomAnswer(contentFormat));
  };

  useEffect(() => {
    const editorWrapperEl = document.getElementById("editorWrapper");
    if (editorWrapperEl) {
      const editorEl = editorWrapperEl.querySelector(".sun-editor-editable");
      editorEl?.addEventListener("mouseup", function () {
        const selectedText = (window?.getSelection()?.toString() ?? "").trim();
        if (selectedText !== "") {
          getWordSearchFn("");
          debounceGetWordSearchFn(selectedText);
        }
      });
    }
  }, []);

  const getWordSearchFn = (word: string) => {
    dispatch(getWordSearch(word));
  };
  const debounceGetWordSearchFn = useCallback(_.debounce(getWordSearchFn, 1000), []);

  // SUPPORT AI
  const [boxTabActive, setBoxTabActive] = useState<null | AiBoxTab>(AiBoxTab.contentGeneratorConnector);
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={(theme) => ({
          height: "calc(100vh - 130px)",
          overflow: "hidden",
          display: "flex",
          width: "100%",
          gap: "15px",
          marginTop: "16px",
          border: "1px solid #e1e1e1",
          borderRadius: "4px",
          "& .content_box": {
            margin: "0px",
            height: "100%",
          },
          "& .assistant_box": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minHeight: "calc(50vh + 28px)",
            padding: "16px",
            height: "100%",
            position: "relative",
            "& button": {
              textTransform: "none",
            },
          },
        })}
      >
        <PanelGroup direction="horizontal">
          <Panel defaultSize={65}>
            <Box
              className="content_box"
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "block",
                  border: "1px solid #e1e1e1",
                  borderRadius: "0px",
                  borderBottom: "none",
                  // maxHeight: "240px",
                  "& .MuiAccordionSummary-root": {
                    "&.Mui-expanded": {
                      borderBottom: "1px solid #e1e1e1",
                    },
                  },
                  "& .MuiCollapse-root": {
                    maxHeight: "75vh",
                    [theme.breakpoints.up("sm")]: {
                      maxHeight: "71vh",
                    },
                  },
                  "& .MuiAccordionSummary-content": {
                    fontWeight: "bold",
                    marginBottom: "20px",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    "& svg": {
                      fill: "black",
                    },
                  },
                  "& .MuiAccordionDetails-root": {
                    "& div": {},
                  },
                })}
              >
                {props?.isPartTwo ? (
                  <ListAnswer
                    guidedQuestions={props.guidedQuestions}
                    answersByAspect={props.answersByAspect}
                    question={props.question}
                    name={props.name}
                    onGenerateAnswer={(content) => {
                      dispatch(editCustomAnswer(content));
                    }}
                    handleClickGuidedQuestion={(item) => {
                      setGuidedQuestionsSelected((prev) =>
                        prev.find((itemG) => itemG._id === item._id) ? prev : [...prev, item]
                      );
                    }}
                  />
                ) : (
                  <ListSuggest
                    dataOfAnswer={isFromProfile ? JSON.parse(location.state?.idea) : null}
                    handleClickIdea={handleAddItemCustom}
                  />
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  // maxHeight: "calc(100% - 240px)",
                  "& .sun-editor": {
                    height: "100%",
                  },
                  "& .se-wrapper": {
                    height: "calc(100% - 12px)!important",
                    overflow: "auto",
                    "& .se-wrapper-inner": {
                      height: "auto !important",
                      textAlign: "justify",
                      fontSize: "24px !important",
                    },
                  },
                  "& .se-resizing-bar": {
                    display: "none",
                  },
                }}
                id="editorWrapper"
              >
                {guidedQuestionsSelected.length > 0 && props.isPartTwo ? (
                  <ListIdeaAnswerBox
                    question={props.question}
                    name={props.name}
                    guidedQuestionsSelected={guidedQuestionsSelected}
                    answersByAspect={props.answersByAspect}
                    removeGuidedQuestion={(id) => {
                      setGuidedQuestionsSelected((prev) => prev.filter((itemG) => itemG._id !== id));
                    }}
                    onAddConnectorDone={(content) => {
                      setGuidedQuestionsSelected([]);
                      dispatch(editCustomAnswer(content));
                    }}
                  />
                ) : (
                  <SunEditor
                    onChange={handleEditorChange}
                    hideToolbar
                    setContents={editCustomAnswerContent.data.toString()}
                  />
                )}
              </Box>
            </Box>
          </Panel>
          <PanelResizeHandle
            style={{
              width: "5px",
              backgroundColor: "#e1e1e1",
            }}
          />
          <Panel defaultSize={35}>
            <Box className="assistant_box" sx={{}}>
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e1e1e1",
                  marginBottom: "16px",
                }}
              >
                {boxTabs.map((item, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        flex: 1,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        margin: "0 2px",
                        borderRadius: "4px 4px 0 0",
                        ...(boxTabActive === item.code
                          ? {
                              backgroundColor: "#eceaea",
                            }
                          : {}),
                        "&:hover": {
                          backgroundColor: "#eceaea",
                        },
                      }}
                      onClick={() => {
                        setBoxTabActive(item.code);
                      }}
                    >
                      <Typography component={"span"} sx={{ marginRight: "4px" }}>
                        {item.name}
                      </Typography>
                      <item.icon
                        sx={{
                          color: "#264653",
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
              <Box
                sx={{
                  height: "calc(100% - 80px)",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <Box sx={boxTabActive !== AiBoxTab.wordSearch ? { display: "none" } : {}}>
                  <WordSearch
                    showWordSearch={() => {
                      setBoxTabActive(AiBoxTab.wordSearch);
                    }}
                    hideWordSearch={() => {
                      setBoxTabActive(AiBoxTab.contentGeneratorConnector);
                    }}
                  />
                </Box>
                <Box sx={boxTabActive !== AiBoxTab.contentGeneratorConnector ? { display: "none" } : {}}>
                  <TabAssistantAI
                    isPartTwo={props.isPartTwo}
                    contentGeneratorConnector={contentGeneratorConnector}
                    setBoxTabActive={setBoxTabActive}
                    setContentGeneratorConnector={setContentGeneratorConnector}
                    question={refinePrompt(extractContent(props.question?.name ?? ""))}
                  />
                </Box>
                <Box sx={boxTabActive !== AiBoxTab.suggestVoca ? { display: "none" } : {}}>
                  <TabSuggestion setBoxTabActive={setBoxTabActive} />
                </Box>
              </Box>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
      {/* 
      {editCustomAnswerContent.data !== "" ? (
        <Button
          sx={{
            position: "fixed",
            bottom: "10px",
            width: "calc(100vw - 32px)",
            textTransform: "none",
            backgroundColor: "#264653",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "700",
          }}
          variant="contained"
          className="btn-practice"
          onClick={nextStep}
          disabled={!editCustomAnswerContent.data}
        >
          Practice
        </Button>
      ) : null} */}
    </Box>
  );
}

export default CustomAnswer;
