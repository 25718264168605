import { getRemainingEnergy, IApplicationState } from "@/store-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Skeleton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWordSearch } from "../../../../store-redux/actions/items";
import ReportDictBtn from "./ReportDictBtn";
import SaveWordBtn from "./SaveWordBtn";
import WordDetail from "./WordDetail";
interface WordSearchProps {
  showWordSearch: () => void;
  hideWordSearch: () => void;
}
const WordSearch: React.FC<WordSearchProps> = ({ showWordSearch, hideWordSearch }) => {
  const dataWordSearch = useSelector((state: IApplicationState) => state.wordSearch);
  const dispatch = useDispatch();
  const onResetWordSearch = () => {
    dispatch(getWordSearch(""));
    hideWordSearch();
  };

  const dataDict = dataWordSearch?.data?.data;
  const isLoading = dataWordSearch?.data?.isLoading;
  const isUseAI = dataWordSearch?.data?.isLoading;
  useEffect(() => {
    if (isUseAI) {
      setTimeout(() => {
        dispatch(getRemainingEnergy());
      }, 1000);
    }
  }, [isUseAI]);

  useEffect(() => {
    if (isLoading) showWordSearch();
  }, [isLoading]);
  if (!dataWordSearch?.data) return <></>;
  return (
    <Box
      sx={() => ({
        width: "100%",
        backgroundColor: "#fff",
        overflow: "hidden",
        flex: 1,
        padding: "5px 0",
        margin: "5px 0",
        borderTop: "1px solid #e1e1e1",
        borderBottom: "1px solid #e1e1e1",
      })}
    >
      <Box
        sx={() => ({
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& p": {
            color: "#264653",
            fontWeight: "bold",
          },
        })}
      >
        <p>{dataWordSearch.data.word}</p>
        <IconButton className="icon-close" onClick={onResetWordSearch}>
          <CloseIcon color="success" />
        </IconButton>
      </Box>
      {isLoading ? (
        <Box
          sx={() => ({
            padding: "10px",
            maxHeight: "calc(100vh - 370px)",
            overflowY: "auto",
          })}
        >
          <Skeleton variant="text" width={120} height={30} />
          <Skeleton variant="text" width={120} height={30} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
        </Box>
      ) : (
        <></>
      )}
      {dataDict ? (
        <Box
          sx={() => ({
            padding: "10px",
            maxHeight: "calc(100vh - 370px)",
            overflowY: "auto",
          })}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SaveWordBtn word={dataDict} />
            <ReportDictBtn id={dataDict._id} />
          </Box>
          <WordDetail word={dataDict} />
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};

export default WordSearch;
