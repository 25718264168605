import React, { useState, ChangeEvent, useEffect } from "react";
import { Box, Avatar, IconButton } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
interface AvatarUploadProps {
  disabled?: boolean;
  url?: string;
  onChange?: (avatar: File | null) => void;
  isAvatar?: boolean;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ disabled = false, url = null, onChange, isAvatar }) => {
  const [avatar, setAvatar] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      setAvatar(url);
    }
  }, [url]);

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (onChange && file) {
      setAvatar(URL.createObjectURL(file));
      onChange(file);
    }
  };

  return (
    <Box
      sx={{
        height: "230px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar
        alt="Avatar Preview"
        src={avatar ?? (isAvatar ? "/images/people.png" : "/images/background-profile.jpg")}
        sx={{
          mb: 2,
          width: "100%",
          height: "100%",
          overflow: "unset",
          "& img": {
            borderRadius: "32px",
          },
        }}
      />
      {!disabled && (
        <IconButton
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: 999,
            "&:hover": {
              backgroundColor: "transparent",
              "& svg": {
                color: "#fff",
              },
            },
          }}
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input type="file" hidden accept="image/*" onChange={handleAvatarChange} />
          <PhotoCamera
            sx={{
              position: "absolute",
              bottom: 0,
              color: "#ccc",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default AvatarUpload;
