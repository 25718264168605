import { ETypeItem, IItems } from "@/common";
import { fetchListItems } from "@/services-react-query/api";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import IdeaByAspectModal from "./IdeaByAspectModal";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

const AllAspectPage: React.FC = () => {
  const [allAspect, setAllAspect] = useState<IItems[]>([]);
  const [openAspect, setOpenAspect] = useState<IItems | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllAspect();
  }, []);

  const getAllAspect = async () => {
    setIsLoading(true);
    const data = await fetchListItems({
      type: ETypeItem.aspect,
      parentId: "*",
    });
    if (Array.isArray(data)) setAllAspect(data);
    setIsLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TableContainer
          sx={{
            flex: 1,
          }}
          component={Paper}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  createdAt
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  Number of ideas
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAspect
                .sort((a, b) => b.countIdeasByAspect - a.countIdeasByAspect)
                .map((idea) => (
                  <TableRow key={idea._id}>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: idea.name }}></div>
                    </TableCell>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: idea.description }}></div>
                    </TableCell>
                    <TableCell>{moment(idea.createdAt).format("MMM Do YY")}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "700",
                            width: "60px",
                          }}
                        >
                          {idea.countIdeasByAspect}
                        </Box>
                        <IconButton color="secondary" onClick={() => setOpenAspect(idea)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && <LinearProgress color="secondary" />}
      </Box>
      {!!openAspect && <IdeaByAspectModal onClose={() => setOpenAspect(null)} aspect={openAspect} />}
    </>
  );
};

export default AllAspectPage;
